import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from './Login';
import Dashboard from './Dashboard';
import axios from 'axios';
import { getToken, removeUserSession } from "./Utils/commonData";
import Licensing from './scenes/licensing/Licensing';
import LicenseGenerator from './scenes/licensing/LicenseGenerator';
import SetupScreen from './scenes/licensing/SetupScreen';
import SystemManagement from './scenes/administrator/SystemManagement';
import FileManager from './scenes/dashboard/FileManager';


import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_USER } from './redux/actions/types';
import './App.css';
import LetterEditor from 'scenes/dashboard/letters/LetterEditor';


function App() {
  const dispatch = useDispatch();



  const logOut = useCallback(() => {
    removeUserSession();
    dispatch({ type: LOGOUT_USER })
  }, [dispatch]);



  useEffect(() => {
    const token = getToken();

    if (!token) {
      return
    }



    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  }, [])



  const SuperPrivateRoute = ({ children }) => {
    const isLicensed = localStorage.getItem('isLicensed');
    return !isLicensed ? <Navigate to="/licensing" /> : children;
  }

  const SuperPublicRoute = ({ children }) => {
    const isSetup = localStorage.getItem('isSetup');

    const authed = getToken(); // isauth() returns true or false based on localStorage
    return !isSetup ? children : authed ? <Navigate to="/dashboard" /> : <Navigate to="/login" />;
  }

  const PrivateRoute = ({ children }) => {
    // const isLicensed = localStorage.getItem('isLicensed');
    const isLicensed = true;
    const authed = getToken(); // isauth() returns true or false based on localStorage
    return !isLicensed ? <Navigate to="/licensing" /> : authed ? children : <Navigate to="/setup" />;
  }

  const PublicRoute = ({ children }) => {
    // const isLicensed = localStorage.getItem('isLicensed');
    const isLicensed = true;
    const isSetup = localStorage.getItem('isSetup');


    const authed = !getToken(); // isauth() returns true or false based on localStorage
    return !isLicensed ? <Navigate to="/licensing" /> : !isSetup ? <Navigate to="/setup" /> : authed ? children : <Navigate to="/dashboard" />;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className=''>
          <Routes>
            <Route path="/" element={
              <Navigate to="/dashboard" />
            } />


            <Route path="/login" element={<PublicRoute>
              <Login />
            </PublicRoute>
            } />

            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="/setup" element={
              <SuperPublicRoute>
                <SetupScreen />
              </SuperPublicRoute>
            } />
            <Route path="/licensegenerator" element={<LicenseGenerator />} />
            <Route path="/licensing" element={<Licensing />} />
            <Route path="/systemmanagement" element={<SystemManagement />} />
            <Route path="/file-manager" element={<FileManager />} />
            <Route path="/template-editor" element={<LetterEditor />} />

          </Routes>
          {/* <AuthVerify logOut={logOut} /> */}

        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
