import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getPersonalReferralDetailsById } from "../../../redux/actions/data.action";
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PEROSNAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { Button, Input, Label } from "reactstrap";
import { examinationAppearanceLetterData } from "../../../Utils/commonData";
import { manipulateSentence } from "../../../Utils/helpers";






const ColposcopicFindings = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { patientDetails, examination, personalReferralDetails } = useSelector(({ data }) => data)
    const { examinationDetails } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)




    let handleReferralDetails = (id) => {
        dispatch(getPersonalReferralDetailsById(id))
            .then((res) => {
                let { tbl_personalreferralreason, tbl_personalassociatedsymptom, tbl_personalrelevantdetail } = res.data;
                dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_personalreferralreason })
                dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_personalassociatedsymptom })
                dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })

            })
            .catch(err => {
                console.log(err)
            })
    }



    const handleGetExamination = (id) => {
        dispatch(getCervixExaminationDetailsById(id))
            .then(res => {
                console.log(res, 'examination details')
                dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
            })
    }




    const handleSubmit = (e) => {
        e.preventDefault()

        if (examinationDetails && !examinationDetails.tztype) {
            return;

        }

        /*   props.lastStep();
          props.completeCallback();
          Swal.fire(
            'Good job!',
            'You clicked the button!',
            'success'
          ) */
        if (examinationDetails.pregnant) {
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/pregnant' : '/dashboard/viewPatient/examination/pregnant'}`)
        } else {
            navigate(`${viewOption === 'enter' ? '/dashboard/patient/examination/details' : '/dashboard/viewPatient/examination/details'}`)
        }
    };

    const setExamDetails = (prop) => {
        if (viewOption === 'review') {
            return console.log('REVIEW MODE')
        }



        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};

        dispatch({ type: SET_EXAMINATION_DETAILS, payload: prop })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: prop }))
        dispatch({ type: SET_EDITED, payload: 'examination' })
    }


    const handleChanges = prop => event => {
        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};

        if (viewOption === 'review') {
            return console.log('REVIEW MODE')
        }







        let targetValue = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value

        let newVal = { ...examinationDetails, [prop]: targetValue }

        let withSummary = examinationAppearanceLetterData.find(a => a.key === prop);
        if (withSummary) {
            let oldDetailsletterSummary = examinationDetails.detailslettersummary;


            let detailslettersummary = manipulateSentence(oldDetailsletterSummary, withSummary.value, targetValue ? false : true)





            newVal = { ...newVal, detailslettersummary }


        }






        dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
        dispatch({ type: SET_EDITED, payload: 'examination' })

    }


    const handleBack = () => {
        navigate(-1)
    };



    const handleExit = (id) => {
        if (edited.find(a => a === 'examination')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
            // return

        } else {
            dispatch({ type: CLEAR_EXAMINATION })
            dispatch({ type: CLEAR_EXAMINATION_DETAILS })
        }


        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }







    useEffect(() => {
        if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
            //   setPatient(patientDetails)
            if (personalReferralDetails && personalReferralDetails.referralid) {
                handleReferralDetails(personalReferralDetails.referralid)
            }


            if (examination && examination.examinationid) {
                handleGetExamination(examination.examinationid)
            }
        } else {
            navigate('/dashboard/home')
        }
    }, [patientDetails, examination])




    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Examination Details</h4>
            </div>
            <div className="card-body">
                <div>
                    <Form noValidate
                        onSubmit={handleSubmit} onReset={handleExit}>
                        <div className="bordered pl-2 pr-2">
                            <h3 className="card-title text-center bordered-title">Colposcopic Findings</h3>
                            <div className="row pl-2 pr-2">
                                <div className='col-md-6'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title txtLeft bordered-title">Squamo-Columnar Junction</h3>
                                        <div className="bordered pr-2 pl-2">
                                            <h3 className="card-title txtLeft bordered-title text-15 ml-3">Visible</h3>
                                            <div className="d-flex pb-3">
                                                <div className="col-md-4 text-left">
                                                    <div className='pl-3'>
                                                        <input
                                                            type="radio" id="Ectocervix"
                                                            value="Ectocervix" checked={examinationDetails.squamocolumnarjunctionvisible === 'Ectocervix'} name="squamocolumnarjunction_radio" className="" onChange={(e) => {
                                                                setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Ectocervix', tztype: "1" })
                                                            }}

                                                        // value={examinationDetails.vulvaabnormal}
                                                        // id="vulvaabnormal"
                                                        // checked={examinationDetails.vulvaabnormal}
                                                        // onClick={(e) => {
                                                        //  setExamDetails({...examinationDetails, vulvaabnormal:  e.target.value === 'true' ? false : true})  
                                                        // }}
                                                        />
                                                        <label className="c" htmlFor="Ectocervix" >Ectocervix</label>
                                                        <div className='clearfix'></div><br />
                                                    </div>
                                                    <div className='pl-3'>
                                                        <input
                                                            type="radio" id="Canal"
                                                            value="Canal" checked={examinationDetails.squamocolumnarjunctionvisible === 'Canal'} name="squamocolumnarjunction_radio" className=""
                                                            onChange={(e) => {
                                                                setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Canal', tztype: "2" })
                                                            }}
                                                        />
                                                        <label className="c" htmlFor="Canal" >Canal</label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                </div>
                                                <div className='bordered col-md-8 mt-3'>
                                                    <h3 className="card-title bordered-title text-15 ml-3" htmlFor="vulvaabnormal" >Tz Type</h3>
                                                    <div style={{ alignItems: 'space-around', justifyContent: 'space-around', display: 'flex', flexDirection: 'row' }}>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="Ectocervix1"
                                                                value="1" checked={examinationDetails.tztype === '1'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Ectocervix', tztype: "1" })
                                                                }}
                                                            />
                                                            <label className="c" htmlFor="Ectocervix1" >1</label>
                                                        </div>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="Canal2"
                                                                value="2" checked={examinationDetails.tztype === '2'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Canal', tztype: "2" })
                                                                }}
                                                            />
                                                            <label className="c" htmlFor="Canal2">2</label>
                                                        </div>
                                                        <div className=''>
                                                            <input
                                                                type="radio" id="NotVisible3"
                                                                value="3" checked={examinationDetails.tztype === '3'} name="tztype_radio" className="" onChange={(e) => {
                                                                    setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Not Visible', tztype: "3" })
                                                                }}
                                                            />
                                                            <label className="c" htmlFor="NotVisible3" >3</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-left pl-3 mt-2 mb-2">
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="NotVisible"
                                                    value="Not Visible" checked={examinationDetails.squamocolumnarjunctionvisible === 'Not Visible'} name="squamocolumnarjunction_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, squamocolumnarjunctionvisible: 'Not Visible', tztype: "3" })
                                                    }}
                                                />
                                                <label className="c" htmlFor="NotVisible" >Not Visible</label>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <br />

                                </div>


                                <div className='col-md-6 txtLeft'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title bordered-title">Extent Of Lesion</h3>
                                        <div className="bordered">
                                            <h3 className="card-title ml-3 bordered-title text-15">Extent</h3>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="Endocervix"
                                                    value="Endocervix" checked={examinationDetails.extentoflesion === 'Not Visible'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, extentoflesion: 'Not Visible' })
                                                    }}
                                                />
                                                <label className="c" htmlFor="Endocervix" >Endocervix</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3 center'>
                                                <input
                                                    type="radio" id="intoupper"
                                                    value="Into endocervical canal (upper limit seen)" checked={examinationDetails.extentoflesion === 'Into endocervical canal (upper limit seen)'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, extentoflesion: 'Into endocervical canal (upper limit seen)' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2" htmlFor="intoupper">Into endocervical canal (upper limit seen)</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="intonotseen"
                                                    value="Into endocervical canal (upper limit not seen)" checked={examinationDetails.extentoflesion === 'Into endocervical canal (upper limit not seen)'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, extentoflesion: 'Into endocervical canal (upper limit not seen)' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2" htmlFor="intonotseen" >Into endocervical canal (upper limit not seen)</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='pl-3'>
                                                <input
                                                    type="radio" id="ontovagina"
                                                    value="Onto vagina" checked={examinationDetails.extentoflesion === 'Onto vagina'} name="extentoflesion_radio" className=""
                                                    onChange={(e) => {
                                                        setExamDetails({ ...examinationDetails, extentoflesion: 'Onto vagina' })
                                                    }}
                                                />
                                                <label className="w-75 pt-2" htmlFor="ontovagina" >Onto vagina</label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 pl-3 mt-3 mb-2'>
                                            <input
                                                type="radio" id="nolesion"
                                                value="No Lesion" checked={examinationDetails.extentoflesion === 'No Lesion'} name="extentoflesion_radio" className=""
                                                onChange={(e) => {
                                                    setExamDetails({ ...examinationDetails, extentoflesion: 'No Lesion' })
                                                }}
                                            />
                                            <label className="c" htmlFor="nolesion" >No Lesion</label>
                                            <div className='clearfix'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <br />
                        <div className="bordered pl-2 pr-2">
                            <h3 className="card-title text-center bordered-title">Appearance</h3>
                            <div className="d-flex">
                                <div className='col-md-8 txtLeft'>
                                    <div className="bordered pl-2 pr-2">
                                        <h3 className="card-title bordered-title text-14">Aceto White</h3>
                                        <div className='row pl-2 pr-2'>
                                            <div className='col-md-3'>
                                                <input
                                                    type="radio" id="acetowhitenone"
                                                    checked={(!examinationDetails.acetowhite) && (!examinationDetails.acetowhitemoderate && !examinationDetails.acetowhitesevere)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.detailslettersummary;
                                                        let detailslettersummary = manipulateSentence(olddetailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhite').value, true)
                                                        detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitesevere').value, true)
                                                        detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitemoderate').value, true)

                                                        setExamDetails({ ...examinationDetails, acetowhite: false, acetowhitemoderate: false, acetowhitesevere: false, detailslettersummary })
                                                    }}
                                                />
                                                <label className="c" htmlFor="acetowhitenone" >None</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="acetowhitemild"
                                                    value={examinationDetails.acetowhite} checked={examinationDetails.acetowhite} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.detailslettersummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'acetowhite');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let detailslettersummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)

                                                        if (isTrue) {
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitesevere').value, true)
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitemoderate').value, true)
                                                            setExamDetails({ ...examinationDetails, acetowhite: isTrue, acetowhitemoderate: false, acetowhitesevere: false, detailslettersummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, acetowhite: isTrue, detailslettersummary })
                                                        }

                                                    }}
                                                />
                                                <label className="c" htmlFor="acetowhitemild">Mild</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="acetowhitemoderate"
                                                    value={examinationDetails.acetowhitemoderate} checked={(!examinationDetails.acetowhite && examinationDetails.acetowhitemoderate)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.detailslettersummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'acetowhitemoderate');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let detailslettersummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)



                                                        if (isTrue) {
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitesevere').value, true)
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhite').value, true)
                                                            setExamDetails({ ...examinationDetails, acetowhite: false, acetowhitemoderate: isTrue, acetowhitesevere: false, detailslettersummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, acetowhitemoderate: isTrue, detailslettersummary })
                                                        }

                                                    }}
                                                />
                                                <label className="c" htmlFor="acetowhitemoderate" >Moderate</label>
                                                <div className='clearfix'></div>
                                            </div>
                                            <div className='col-md-3 '>
                                                <input
                                                    type="radio" id="acetowhitesevere"
                                                    value={examinationDetails.acetowhitesevere} checked={(!examinationDetails.acetowhite && examinationDetails.acetowhitesevere)} name="acetowhite_radio" className=""
                                                    onChange={(e) => {
                                                        let olddetailslettersummary = examinationDetails.detailslettersummary;
                                                        let withSummary = examinationAppearanceLetterData.find(a => a.key === 'acetowhitesevere');
                                                        let isTrue = e.target.value == 'true' ? false : true;
                                                        let detailslettersummary = manipulateSentence(olddetailslettersummary, withSummary.value, isTrue ? false : true)

                                                        if (isTrue) {
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhitemoderate').value, true)
                                                            detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'acetowhite').value, true)

                                                            setExamDetails({ ...examinationDetails, acetowhite: false, acetowhitemoderate: false, acetowhitesevere: isTrue, detailslettersummary })
                                                        } else {
                                                            setExamDetails({ ...examinationDetails, acetowhitesevere: isTrue, detailslettersummary })
                                                        }



                                                    }}

                                                />
                                                <label className="c" htmlFor="acetowhitesevere" >Severe</label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className='row mt-3 pl-3'>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="endocervicalcanalupperlimitseen" className=""
                                                id="endocervicalcanalupperlimitseen"
                                                value={examinationDetails.endocervicalcanalupperlimitseen ? false : true}
                                                checked={examinationDetails.endocervicalcanalupperlimitseen}
                                                onChange={handleChanges('endocervicalcanalupperlimitseen')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="endocervicalcanalupperlimitseen" >Upper Limit Seen</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="stenosedos" className=""
                                                id="stenosedos"
                                                value={examinationDetails.stenosedos ? false : true}
                                                checked={examinationDetails.stenosedos}
                                                onChange={handleChanges('stenosedos')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="stenosedos" >Stenosed os</Label>
                                            <div className='clearfix'></div>
                                        </div>

                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="wartyfeatures" className=""
                                                id="wartyfeatures"
                                                value={examinationDetails.wartyfeatures ? false : true}
                                                checked={examinationDetails.wartyfeatures}
                                                onChange={handleChanges('wartyfeatures')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="wartyfeatures" >Warty Features</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="squamometaplasia" className=""
                                                id="squamometaplasia"
                                                value={examinationDetails.squamometaplasia ? false : true}
                                                checked={examinationDetails.squamometaplasia}
                                                onChange={handleChanges('squamometaplasia')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="squamometaplasia" >Squamo metaplasia</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="suspectinvasion" className=""
                                                id="suspectinvasion"
                                                value={examinationDetails.suspectinvasion ? false : true}
                                                checked={examinationDetails.suspectinvasion}
                                                onChange={handleChanges('suspectinvasion')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="suspectinvasion" >Suspect Invasion</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="atrophy" className=""
                                                id="atrophy"
                                                value={examinationDetails.atrophy ? false : true}
                                                checked={examinationDetails.atrophy}
                                                onChange={handleChanges('atrophy')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="atrophy" >Atrophy</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="iodinenegative" className=""
                                                id="iodinenegative"
                                                value={examinationDetails.iodinenegative ? false : true}
                                                checked={examinationDetails.iodinenegative}
                                                onChange={handleChanges('iodinenegative')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="iodinenegative" >Iodine negative</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className='col-md-6 pl-4'>
                                            <Input
                                                type="checkbox" name="condylomawart" className=""
                                                id="condylomawart"
                                                value={examinationDetails.condylomawart ? false : true}
                                                checked={examinationDetails.condylomawart}
                                                onChange={handleChanges('condylomawart')}

                                            />
                                            {' '}
                                            <Label className="" htmlFor="condylomawart" >Condyloma/Wart</Label>
                                            <div className='clearfix'></div>
                                        </div>
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                            <div className="row col-md-6 " >
                                                <div className='col-md-6 pl-3'>
                                                    <Input
                                                        type="checkbox" name="ectopy" className=""
                                                        id="ectopy"
                                                        value={examinationDetails.ectopy ? false : true}
                                                        checked={examinationDetails.ectopy}
                                                        onChange={handleChanges('ectopy')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="ectopy" >Ectopy</Label>
                                                    <div className='clearfix'></div>

                                                </div>
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="polyp" className=""
                                                        id="polyp"
                                                        value={examinationDetails.polyp ? false : true}
                                                        checked={examinationDetails.polyp}
                                                        onChange={handleChanges('polyp')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="polyp" >Polyp</Label>
                                                    <div className='clearfix'></div>

                                                </div>
                                            </div>
                                            <div className="row col-md-6">
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="cervicitis" className=""
                                                        id="cervicitis"
                                                        value={examinationDetails.cervicitis ? false : true}
                                                        checked={examinationDetails.cervicitis}
                                                        onChange={handleChanges('cervicitis')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="cervicitis" >Cervicitis</Label>
                                                    <div className='clearfix'></div>
                                                </div>
                                                <div className='col-md-6 pl-4'>
                                                    <Input
                                                        type="checkbox" name="hpv" className=""
                                                        id="hpv"
                                                        value={examinationDetails.hpv ? false : true}
                                                        checked={examinationDetails.hpv}
                                                        onChange={handleChanges('hpv')}

                                                    />
                                                    {' '}
                                                    <Label className="" htmlFor="hpv" >HPV</Label>
                                                    <div className='clearfix'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                </div>
                                <div className='col-md-4 txtLeft pl-2 pr-2'>
                                    <div className="bordered">
                                        <h3 className="card-title bordered-title">Vessels</h3>
                                        <div className="pl-2 pr-2">
                                            <div className="bordered pl-2 pr-2">
                                                <h3 className="card-title bordered-title ml-3 text-14">Mosaicism</h3>
                                                <div className="row pl-2">

                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="mosaiccourse" className=""
                                                            id="mosaiccourse"
                                                            value={examinationDetails.mosaiccourse ? false : true}
                                                            checked={examinationDetails.mosaiccourse}
                                                            onChange={(e) => {
                                                                let detailslettersummary = examinationDetails.detailslettersummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'mosaiccourse');


                                                                if (e.target.value == 'true') {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, false)
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'mosaic').value, true)

                                                                    setExamDetails({ ...examinationDetails, mosaiccourse: true, mosaic: false, detailslettersummary })
                                                                } else {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, mosaiccourse: false, detailslettersummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="mosaiccourse" >Coarse</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="mosaic" className=""
                                                            id="mosaic"
                                                            value={examinationDetails.mosaic ? false : true}
                                                            checked={examinationDetails.mosaic}
                                                            onChange={(e) => {
                                                                let detailslettersummary = examinationDetails.detailslettersummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'mosaic');

                                                                if (e.target.value == 'true') {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, false)
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'mosaiccourse').value, true)

                                                                    setExamDetails({ ...examinationDetails, mosaiccourse: false, mosaic: true, detailslettersummary })
                                                                } else {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, mosaic: false, detailslettersummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="mosaic" >Fine</Label>
                                                        <div className='clearfix'></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <div className="bordered pl-2 pr-2">
                                                <h3 className="card-title bordered-title ml-3 text-14">Punctation</h3>
                                                <div className="row pl-2">

                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="punctationcourse" className=""
                                                            id="punctationcourse"
                                                            value={examinationDetails.punctationcourse ? false : true}
                                                            checked={examinationDetails.punctationcourse}
                                                            onChange={(e) => {
                                                                let detailslettersummary = examinationDetails.detailslettersummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'punctationcourse');
                                                                if (e.target.value == 'true') {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, false)
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'punctation').value, true)

                                                                    setExamDetails({ ...examinationDetails, punctationcourse: true, punctation: false, detailslettersummary })
                                                                } else {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, punctationcourse: false, detailslettersummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="punctationcourse" >Coarse</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                    <div className='col-md-6 pl-5'>
                                                        <Input
                                                            type="checkbox" name="punctation" className=""
                                                            id="punctation"
                                                            value={examinationDetails.punctation ? false : true}
                                                            checked={examinationDetails.punctation}
                                                            onChange={(e) => {
                                                                let detailslettersummary = examinationDetails.detailslettersummary;
                                                                let withSummary = examinationAppearanceLetterData.find(a => a.key === 'punctation');

                                                                if (e.target.value == 'true') {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, false)
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, examinationAppearanceLetterData.find(a => a.key === 'punctationcourse').value, true)
                                                                    setExamDetails({ ...examinationDetails, punctationcourse: false, punctation: true, detailslettersummary })
                                                                } else {
                                                                    detailslettersummary = manipulateSentence(detailslettersummary, withSummary.value, true)
                                                                    setExamDetails({ ...examinationDetails, punctation: false, detailslettersummary })
                                                                }
                                                            }}

                                                        />
                                                        {' '}
                                                        <Label className="" htmlFor="punctation" >Fine</Label>
                                                        <div className='clearfix'></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-10 pl-5 ml-2 mt-3 mb-3'>
                                                <Input
                                                    type="checkbox" name="grossabnormality" className=""
                                                    id="grossabnormality"
                                                    value={examinationDetails.grossabnormality ? false : true}
                                                    checked={examinationDetails.grossabnormality}
                                                    onChange={handleChanges('grossabnormality')}

                                                />
                                                {' '}
                                                <Label className="" htmlFor="grossabnormality" >Gross abnormality</Label>
                                                <div className='clearfix'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <br />
                        <div className="bordered">
                            <h3 className="bordered-title card-title text-center">Details - Summary for letters</h3>
                            <div className='pl-3 pb-3 pr-3'>
                                {/* <label className="control-label">Specify Abnormality</label> */}
                                <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                                    value={examinationDetails.detailslettersummary}
                                // onChange={handleChanges('specifyabnormality')}
                                />
                            </div>
                        </div>
                        <br /> <br /> <br />

                        <div className="text-xs-center fR">
                            <Button className="btn btnIri marRight20" onClick={handleBack}>Previous</Button>
                            <Button type="submit" className="btn btnIri marRight20">Next</Button>
                            <Button type="reset" className="btn btnIri marRight20">Exit</Button>

                        </div>
                    </Form>
                </div>
            </div>
            <div className='clearfix'></div>
        </>
    )
}

export default ColposcopicFindings;
