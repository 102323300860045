import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_EDITED, CLEAR_PATIENT_DETAILS, CLEAR_VIEW_OPTION, OPEN_MODAL, SET_DATA, SET_EDITED, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS, SET_PEROSNAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS, SET_SEARCH_STRING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { createExaminationDetails, createPatientDetails, createSmearResultDetails, createTreatmentDetails, getCervixExaminationDetailsById } from '../../redux/actions/data.action';



export default function SavePatientModal({ }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { modal, edited } = useSelector(({ ui }) => ui)
    const { datasheetpictures, smearResults, patientDetails, personalReferralDetails, referralReasons, treatmentDetails, patientAssociatedSymptoms, relevantDetails, examinationDetails, managementDetails, examination, personalReferralHistology } = useSelector(({ data }) => data)


    const handleCreateNewPatient = () => {
        if (edited.find(a => a === 'personal')) {

            dispatch(createPatientDetails({
                reasons: referralReasons, patient: patientDetails, referral: personalReferralDetails, associatedSymptoms: patientAssociatedSymptoms, relevantDetails, referralHistology: personalReferralHistology
                , systemid: patientDetails.systemid
            }))
                .then((res) => {

                    const { patient, referral, reasons, associatedSymptoms, relevantDetails } = res.data
                    dispatch({ type: SET_PATIENT_DETAILS, payload: patient })
                    dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: referral })
                    dispatch({ type: SET_REFERRAL_REASONS, payload: reasons })
                    dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: associatedSymptoms })
                    dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: relevantDetails })


                    // if(viewOption)
                    // navigate('/dashboard/home')
                })
                .catch(err => {
                    console.log(err, "CREATE ERROR")
                })
        }

        if (edited.find(a => a === 'examination')) {
            dispatch(createExaminationDetails({ examinationDetails: { ...examination, ...examinationDetails, systemid: patientDetails.systemid, referralid: personalReferralDetails.referralid }, managementDetails, treatmentDetails, datasheetpictures }))
                .then(res => {
                    // dispatch(getCervixExaminationDetailsById(examinationDetails.examinationid))
                    console.log(res, 'examinations creat')
                })
                .catch(err => {
                    console.log(err)
                })
        }

        if (examination.examinationid && edited.find(a => a === 'treatment')) {
            dispatch(createTreatmentDetails({ ...treatmentDetails, examinationid: examination.examinationid }))
                .then(res => {

                })
                .catch(err => {
                    console.log(err)
                })
        }

        if (examination.examinationid && edited.find(a => a === 'results')) {
            dispatch(createSmearResultDetails({ ...smearResults, examinationid: examination.examinationid }))
                .then(res => {

                })
                .catch(err => {
                    console.log(err)
                })
        }

        dispatch({ type: SET_DATA, payload: [] })
        dispatch({ type: SET_SEARCH_STRING, payload: '' })
        dispatch({ type: OPEN_MODAL, payload: null });
        dispatch({ type: CLEAR_EDITED })

        localStorage.removeItem('formDetails')
        localStorage.removeItem('edited')

    }

    const toggleNo = () => {
        if (modal === 'save_details') {
            if (!patientDetails.systemid) {
                dispatch({ type: CLEAR_PATIENT_DETAILS })
            }
            dispatch({ type: CLEAR_EDITED })
        }
        dispatch({ type: OPEN_MODAL, payload: modal === 'save_details' ? null : 'save_details' });
        // dispatch({ type: CLEAR_VIEW_OPTION })

        localStorage.removeItem('formDetails')
        localStorage.removeItem('edited')

    }

    const handleClose = () => {
        dispatch({ type: OPEN_MODAL, payload: modal === 'save_details' ? null : 'save_details' });
        navigate(-1)
    }


    return (
        <Modal
            isOpen={modal === 'save_details'}
        // toggle={toggle}
        // className={this.props.className}
        >
            <ModalHeader className='btnIri' closeButton toggle={handleClose}>Entering Personal Details</ModalHeader>
            <ModalBody>
                Do you want to save the details?
            </ModalBody>
            <ModalFooter className='center' style={{ justifyContent: 'space-around' }}>
                <Button color="primary" className="btnIri" onClick={handleCreateNewPatient}>Yes</Button>{' '}
                <Button color="secondary" onClick={toggleNo}>No</Button>
            </ModalFooter>
        </Modal>
    )
}
