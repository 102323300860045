import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const Appointment = () => {
    const navigate = useNavigate();
    const { patientDetails } = useSelector(({ data }) => data)



    useEffect(() => {
        if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
            //   setPatient(patientDetails)
        } else {
            navigate('/dashboard/home')
        }




    }, [patientDetails])



    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Enter Appointment</h4>
            </div>

            <div className="card-body">

            </div>
        </>
    )
}

export default Appointment;
