import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getPersonalReferralDetailsById } from "../../../redux/actions/data.action";
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PEROSNAL_RELEVANT_DETAILS, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { Form, Row, Col, Input, Label, Button } from "reactstrap"




const ExaminationDetails = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let { examType } = useParams();
  const { viewOption, edited } = useSelector(({ ui }) => ui)
  const { patientDetails, examination, personalReferralDetails, examinationDetails } = useSelector(({ data }) => data)


  const handleChanges = prop => event => {
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }



    let newVal = { ...examinationDetails, [prop]: event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value }

    dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
    localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
    dispatch({ type: SET_EDITED, payload: 'examination' })
  }




  let handleReferralDetails = (id) => {
    dispatch(getPersonalReferralDetailsById(id))
      .then((res) => {
        let { tbl_personalreferralreason, tbl_personalassociatedsymptom, tbl_personalrelevantdetail } = res.data;
        dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_personalreferralreason })
        dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_personalassociatedsymptom })
        dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })

      })
      .catch(err => {
        console.log(err)
      })
  }






  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
      })
  }



  const handleSubmit = (e) => {
    e.preventDefault()
    let { tbl_cervixmanagement } = examination;
    // props.handleNext()
    if (!examinationDetails.opinioncervical) {
      return
    }

    if (tbl_cervixmanagement && tbl_cervixmanagement.managementplan === 'Treatment') {
      navigate(`${viewOption === "enter" ? "/dashboard/patient/results" : "/dashboard/viewPatient/results"}`)
      return
    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient/management" : "/dashboard/viewPatient/management"}`)

  };


  const handleBack = () => {
    console.log("BACKING")
    navigate(-1)
  };



  const handleExit = (id) => {
    if (edited.find(a => a === 'examination')) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_EXAMINATION })
      dispatch({ type: CLEAR_EXAMINATION_DETAILS })

    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)
  }






  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
      //   setPatient(patientDetails)
      if (personalReferralDetails && personalReferralDetails.referralid) {
        handleReferralDetails(personalReferralDetails.referralid)
      }


      if (examination && examination.examinationid) {
        handleGetExamination(examination.examinationid)
      }


    } else {
      navigate("/dashboard/home")
    }




  }, [patientDetails, examination])

  const nurseledclinic = (
    <Row className="row" >
      <Col sm={2} className="mb-3"></Col>
      <Col sm={8} style={{ display: "flex", flexDirection: "column", justifyContent: "center", flexGrow: 1 }} >
        <div className="row">
          <div className="col-md-12">
            <div className="bordered">
              <h3 className="card-title bordered-title" >Cervix</h3>
              <div className="d-flex pl-2 pr-2 mt-2">
                <div className="col-md-6 text-center  mb-3">
                  <Input
                    type="radio" name="cervixpresent" className=""
                    id="cervixpresent"
                    value={true}
                    checked={examinationDetails.cervixpresent}
                    onChange={handleChanges('cervixpresent')}
                  />
                  {" "}
                  <Label htmlFor="cervixpresent">
                    Present
                  </Label>
                </div>
                <div className="col-md-6 text-center mb-3">
                  <Input
                    type="radio" name="cervixpresent" className=""
                    id="notpresent"
                    value={false}
                    checked={!examinationDetails.cervixpresent}
                    onChange={handleChanges('cervixpresent')}
                  />
                  {" "}
                  <Label htmlFor="notpresent">
                    Not Present
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row" >
          <div className="col-md-6 mb-3" >
            <div className="bordered pl-2 pr-2">
              <h3 className="card-title txtLeft bordered-title">Cytology</h3>
              <div className="custom-radio text-left pl-4 mb-1">
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ectosmeartaken" className=""
                    id="ectosmeartaken"
                    value={examinationDetails.ectosmeartaken ? false : true}
                    checked={examinationDetails.ectosmeartaken}
                    onChange={handleChanges('ectosmeartaken')}
                  />
                  {" "}
                  <Label htmlFor="ectosmeartaken">
                    Ecto Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="vaultsmeartaken" className=""
                    id="vaultsmeartaken"
                    value={examinationDetails.vaultsmeartaken ? false : true}
                    checked={examinationDetails.vaultsmeartaken}
                    onChange={handleChanges('vaultsmeartaken')}
                  />
                  {" "}
                  <Label htmlFor="vaultsmeartaken">
                    Vault Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="endosmeartaken" className=""
                    id="endosmeartaken"
                    value={examinationDetails.endosmeartaken ? false : true}
                    checked={examinationDetails.endosmeartaken}
                    onChange={handleChanges('endosmeartaken')}
                  />
                  {" "}
                  <Label htmlFor="endosmeartaken">
                    Endo Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="hrhpvtyping" className=""
                    id="hrhpvtyping"
                    value={examinationDetails.hrhpvtyping ? false : true}
                    checked={examinationDetails.hrhpvtyping}
                    onChange={handleChanges('hrhpvtyping')}
                  />
                  {" "}
                  <Label htmlFor="hrhpvtyping">
                    HPV Testing
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3" >
            <div className="bordered pl-2 pr-2">
              <h3 className="card-title  bordered-title">Swab</h3>
              <div className="custom-radio text-left pl-4">
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="hvsswabtaken" className=""
                    id="hvsswabtaken"
                    value={examinationDetails.hvsswabtaken ? false : true}
                    checked={examinationDetails.hvsswabtaken}
                    onChange={handleChanges('hvsswabtaken')}
                  />
                  {" "}
                  <Label htmlFor="hvsswabtaken">
                    HVS Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="ecstaken" className=""
                    id="ecstaken"
                    value={examinationDetails.ecstaken ? false : true}
                    checked={examinationDetails.ecstaken}
                    onChange={handleChanges('ecstaken')}
                  />
                  {" "}
                  <Label htmlFor="ecstaken">
                    ECS Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="chlamydiau25" className=""
                    id="chlamydiau25"
                    value={examinationDetails.chlamydiau25 ? false : true}
                    checked={examinationDetails.chlamydiau25}
                    onChange={handleChanges('chlamydiau25')}
                  />
                  {" "}
                  <Label htmlFor="chlamydiau25">
                    Chlamydia U25
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="chlamydiaswabtaken" className=""
                    id="chlamydiaswabtaken"
                    value={examinationDetails.chlamydiaswabtaken ? false : true}
                    checked={examinationDetails.chlamydiaswabtaken}
                    onChange={handleChanges('chlamydiaswabtaken')}
                  />
                  {" "}
                  <Label htmlFor="chlamydiaswabtaken">
                    Chlamydia Taken
                  </Label>
                  <div className="clearfix"></div>
                </div>
                <div className="text-left pl-5">
                  <Input
                    type="checkbox" name="guscreenswabtaken" className=""
                    id="guscreenswabtaken"
                    value={examinationDetails.guscreenswabtaken ? false : true}
                    checked={examinationDetails.guscreenswabtaken}
                    onChange={handleChanges('guscreenswabtaken')}
                  />
                  {" "}
                  <Label htmlFor="guscreenswabtaken">
                    GUM Screen
                  </Label>
                  <div className="clearfix"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <br />
      </Col>
      <Col sm={2} className="mb-3"></Col>

    </Row >
  )


  const formDetails = (
    <>
      <Row className="row">
        <Col sm={4} className="mb-3">
          {examType !== "cytologyonly" &&
            <div className="bordered pr-2 pl-2">
              <h3 className="card-title txtLeft bordered-title">Diagnostic Biopsies</h3>
              <Col xs={6} lg={6} className="bordered border-bottom-0 pl-2">
                <h3 className="card-title bordered-title ml-3  text-14">None</h3>
                <div className="text-left pl-5">
                  <Input type="checkbox" name="none-check" id="none-check" />
                  {" "}
                  <Label htmlFor="none-check">
                    None
                  </Label>
                </div>
              </Col>
              <div className="mt-2 bordered">
                <h3 className="card-title txtLeft bordered-title  text-14">Directed (punch) Biopsy</h3>
                <div className="d-flex pl-2 pr-2">
                  <div className="col-md-6 pl-5 text-left">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 pl-5 text-left">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Multiple Direct (punch) Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">

                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5 ">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
                <div className="d-flex pl-2 pr-2">
                  <div className="col-md-12 text-left pl-3 mb-3 mt-2">
                    <Label className="col-md-5 text-left" >Sites</Label>
                    <Input type="text" id="firstName" className="form-control col-md-7" placeholder="" required />
                  </div>
                </div>
              </div>
              <div className="mt-3 bordered">
                <h3 className="card-title bordered-title text-14 " >Wedge Biopsy (diagnostic loop) Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Excessional Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Endometrial Biopsy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Endometrial
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Polypectomy</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Cervical
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <div className="mt-3  bordered">
                <h3 className="card-title txtLeft bordered-title text-14">ECC</h3>
                <div className="d-flex pl-2 pr-2 mt-2">
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      ECC
                    </Label>
                  </div>
                  <div className="col-md-6 text-left pl-5">
                    <Input type="checkbox" name="none-check" id="none-check" />
                    {" "}
                    <Label htmlFor="none-check">
                      Vaginal
                    </Label>
                  </div>
                </div>
              </div>
              <br />
            </div>
          }
        </Col>


        <Col sm={8} style={{ display: "flex", flexDirection: "column" }} >
          <div className="row" >
            <div className="col-md-6 mb-3" >
              <div className="bordered pl-2 pr-2">
                <h3 className="card-title txtLeft bordered-title">Cytology</h3>
                <div className="custom-radio text-left pl-4 mb-1">
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ectosmeartaken" className=""
                      id="ectosmeartaken"
                      value={examinationDetails.ectosmeartaken ? false : true}
                      checked={examinationDetails.ectosmeartaken}
                      onChange={handleChanges('ectosmeartaken')}
                    />
                    {" "}
                    <Label htmlFor="ectosmeartaken">
                      Ecto Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="vaultsmeartaken" className=""
                      id="vaultsmeartaken"
                      value={examinationDetails.vaultsmeartaken ? false : true}
                      checked={examinationDetails.vaultsmeartaken}
                      onChange={handleChanges('vaultsmeartaken')}
                    />
                    {" "}
                    <Label htmlFor="vaultsmeartaken">
                      Vault Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="endosmeartaken" className=""
                      id="endosmeartaken"
                      value={examinationDetails.endosmeartaken ? false : true}
                      checked={examinationDetails.endosmeartaken}
                      onChange={handleChanges('endosmeartaken')}
                    />
                    {" "}
                    <Label htmlFor="endosmeartaken">
                      Endo Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="hrhpvtyping" className=""
                      id="hrhpvtyping"
                      value={examinationDetails.hrhpvtyping ? false : true}
                      checked={examinationDetails.hrhpvtyping}
                      onChange={handleChanges('hrhpvtyping')}
                    />
                    {" "}
                    <Label htmlFor="hrhpvtyping">
                      HPV Testing
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3" >
              <div className="bordered pl-2 pr-2">
                <h3 className="card-title  bordered-title">Swab</h3>
                <div className="custom-radio text-left pl-4">
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="hvsswabtaken" className=""
                      id="hvsswabtaken"
                      value={examinationDetails.hvsswabtaken ? false : true}
                      checked={examinationDetails.hvsswabtaken}
                      onChange={handleChanges('hvsswabtaken')}
                    />
                    {" "}
                    <Label htmlFor="hvsswabtaken">
                      HVS Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="ecstaken" className=""
                      id="ecstaken"
                      value={examinationDetails.ecstaken ? false : true}
                      checked={examinationDetails.ecstaken}
                      onChange={handleChanges('ecstaken')}
                    />
                    {" "}
                    <Label htmlFor="ecstaken">
                      ECS Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="chlamydiau25" className=""
                      id="chlamydiau25"
                      value={examinationDetails.chlamydiau25 ? false : true}
                      checked={examinationDetails.chlamydiau25}
                      onChange={handleChanges('chlamydiau25')}
                    />
                    {" "}
                    <Label htmlFor="chlamydiau25">
                      Chlamydia U25
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="chlamydiaswabtaken" className=""
                      id="chlamydiaswabtaken"
                      value={examinationDetails.chlamydiaswabtaken ? false : true}
                      checked={examinationDetails.chlamydiaswabtaken}
                      onChange={handleChanges('chlamydiaswabtaken')}
                    />
                    {" "}
                    <Label htmlFor="chlamydiaswabtaken">
                      Chlamydia Taken
                    </Label>
                    <div className="clearfix"></div>
                  </div>
                  <div className="text-left pl-5">
                    <Input
                      type="checkbox" name="guscreenswabtaken" className=""
                      id="guscreenswabtaken"
                      value={examinationDetails.guscreenswabtaken ? false : true}
                      checked={examinationDetails.guscreenswabtaken}
                      onChange={handleChanges('guscreenswabtaken')}
                    />
                    {" "}
                    <Label htmlFor="guscreenswabtaken">
                      GUM Screen
                    </Label>
                    <div className="clearfix"></div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <br />
          {examType !== "cytologyonly" ?
            <>
              <div className="bordered" style={{ flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center" }}  >
                <h3 className="card-title txtLeft bordered-title">Colposcopic Opinion</h3>
                <br />
                <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "center", flexGrow: 1 }}>
                  <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                    <div className="bordered">
                      <h3 className="card-title txtLeft bordered-title text-14">Cervical</h3>
                      <div className="pl-3 pr-3">
                        <div className="row text-center">
                          <div className="custom-radio text-left">
                            <div className="row mb-2">
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Normal"
                                  value={examinationDetails.opinioncervical !== 'Normal' ? 'Normal' : null}
                                  checked={examinationDetails.opinioncervical === 'Normal'}
                                  className="" onChange={handleChanges('opinioncervical')}
                                />
                                <label htmlFor="Normal">Normal</label>
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Invasion"
                                  value={examinationDetails.opinioncervical !== 'Invasion' ? 'Invasion' : null}
                                  checked={examinationDetails.opinioncervical === 'Invasion'}
                                  className="" onChange={handleChanges('opinioncervical')}
                                />
                                <label htmlFor="Invasion">Invasion</label>
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Unsatisfactory"
                                  value={examinationDetails.opinioncervical !== 'Unsatisfactory' ? 'Unsatisfactory' : null}
                                  checked={examinationDetails.opinioncervical === 'Unsatisfactory'}
                                  className="" onChange={handleChanges('opinioncervical')}
                                />
                                <label htmlFor="Unsatisfactory">Unsatisfactory</label>
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="CGIN"
                                  value={examinationDetails.opinioncervical !== 'CGIN' ? 'CGIN' : null}
                                  checked={examinationDetails.opinioncervical === 'CGIN'}
                                  className="" onChange={handleChanges('opinioncervical')}
                                />
                                <label htmlFor="CGIN">CGIN</label>
                              </div>
                            </div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="HPV/inflammatory/benign"
                              value={examinationDetails.opinioncervical !== 'HPV/inflammatory/benign' ? 'HPV/inflammatory/benign' : null}
                              checked={examinationDetails.opinioncervical === 'HPV/inflammatory/benign'}
                              className="" onChange={handleChanges('opinioncervical')}
                            />
                            <label className="" htmlFor="HPV/inflammatory/benign">HPV/inflammatory/benign</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="CIN - Low Grade"
                              value={examinationDetails.opinioncervical !== 'CIN - Low Grade' ? 'CIN - Low Grade' : null}
                              checked={examinationDetails.opinioncervical === 'CIN - Low Grade'}
                              className="" onChange={handleChanges('opinioncervical')}
                            />

                            <label htmlFor="CIN - Low Grade">CIN - Low Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="CIN - High Grade"
                              value={examinationDetails.opinioncervical !== 'CIN - High Grade' ? 'CIN - High Grade' : null}
                              checked={examinationDetails.opinioncervical === 'CIN - High Grade'}
                              className="" onChange={handleChanges('opinioncervical')}
                            />
                            <label htmlFor="CIN - High Grade">CIN - High Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="No Cervix"
                              value={examinationDetails.opinioncervical !== 'No Cervix' ? 'No Cervix' : null}
                              checked={examinationDetails.opinioncervical === 'No Cervix'}
                              className="" onChange={handleChanges('opinioncervical')}
                            />
                            <label htmlFor="No Cervix">No Cervix</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Not Performed"
                              value={examinationDetails.opinioncervical !== 'Not Performed' ? 'Not Performed' : null}
                              checked={examinationDetails.opinioncervical === 'Not Performed'}
                              className="" onChange={handleChanges('opinioncervical')}
                            />
                            <label className="" htmlFor="Not Performed">Not Performed</label>
                            <div className="clearfix mb-2"></div>
                            <div className="row">
                              <div className="col-md-6 pt-1">
                                <input
                                  type="radio"
                                  name="cervicalopinion_radio"
                                  id="Other"
                                  value={examinationDetails.opinioncervical !== 'Other' ? 'Other' : null}
                                  checked={examinationDetails.opinioncervical === 'Other'}
                                  className="" onChange={handleChanges('opinioncervical')}
                                />
                                <label htmlFor="Other">Other</label>
                              </div>
                              <div className="col-md-6">
                                <input type="text" disabled={examinationDetails.opinioncervical !== 'Other' ? true : false} className="form-control mb-3"
                                  value={examinationDetails.opinioncervicalothertext}
                                  onChange={handleChanges('opinioncervicalothertext')}
                                  placeholder="" style={{ marginBottom: 20 }} />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                    <div className="bordered">
                      <h3 className="card-title txtLeft bordered-title text-14">Vaginal</h3>
                      <div className="pl-3 pr-3">
                        <div className="row text-center">
                          <div className="custom-radio text-left">

                            <input
                              type="radio"
                              id="NormalVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'Normal' ? 'Normal' : null}
                              checked={examinationDetails.opinionvaginal === 'Normal'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label htmlFor="NormalVaginal">Normal</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="No Opinion"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'No Opinion' ? 'No Opinion' : null}
                              checked={examinationDetails.opinionvaginal === 'No Opinion'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label htmlFor="No Opinion">No Opinion</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="HPV/inflammatory/benignVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'HPV/inflammatory/benign' ? 'HPV/inflammatory/benign' : null}
                              checked={examinationDetails.opinionvaginal === 'HPV/inflammatory/benign'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label className="" htmlFor="HPV/inflammatory/benignVaginal">HPV/inflammatory/benign</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="VaIN - Low Grade"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'VaIN - Low Grade' ? 'VaIN - Low Grade' : null}
                              checked={examinationDetails.opinionvaginal === 'VaIN - Low Grade'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />

                            <label htmlFor="VaIN - Low Grade">VaIN - Low Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="VaIN - High Grade"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'VaIN - High Grade' ? 'VaIN - High Grade' : null}
                              checked={examinationDetails.opinionvaginal === 'VaIN - High Grade'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label htmlFor="VaIN - High Grade">VaIN - High Grade</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="InvasionVaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'Invasion' ? 'Invasion' : null}
                              checked={examinationDetails.opinionvaginal === 'Invasion'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label htmlFor="InvasionVaginal">Invasion</label>
                            <div className="clearfix mb-2"></div>
                            <input
                              type="radio"
                              id="Not Performed Vaginal"
                              name="vaginaloption_radio"
                              value={examinationDetails.opinionvaginal !== 'Not Performed' ? 'Not Performed' : null}
                              checked={examinationDetails.opinionvaginal === 'Not Performed'}
                              className="" onChange={handleChanges('opinionvaginal')}
                            />
                            <label className="" htmlFor="Not Performed Vaginal">Not Performed</label>
                            <div className="clearfix mb-2"></div>
                            <div className="row">
                              <div className="col-md-6 pt-1">
                                <input
                                  type="radio"
                                  id="OtherVaginal"
                                  name="vaginaloption_radio"
                                  value={examinationDetails.opinionvaginal !== 'Other' ? 'Other' : null}
                                  checked={examinationDetails.opinionvaginal === 'Other'}
                                  className="" onChange={handleChanges('opinionvaginal')}
                                />
                                <label htmlFor="OtherVaginal">Other</label>
                              </div>
                              <div className="col-md-6">
                                <input type="text" id="firstName" className="form-control mb-3" placeholder=""
                                  disabled={examinationDetails.opinionvaginal !== 'Other' ? true : false}
                                  style={{ marginBottom: 20 }}
                                  value={examinationDetails.opinionvaginalothertext}
                                  onChange={handleChanges('opinionvaginalothertext')}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </> : <div style={{ height: "100%", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center" }} > </div>}
        </Col>
      </Row>
    </>
  )


  return (
    <>
      <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Examination Details</h4>
      </div>
      <div className="card-body">
        <Form noValidate={true} onSubmit={handleSubmit} onReset={handleExit} >
          <div style={{ minHeight: "350px", display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: examType === 'cytologyonly' ? "flex-start" : "center" }} >
            {examType === "nurseledclinic" ? nurseledclinic : formDetails}
          </div>
          <br />
          <div >
            <Button className="btn fL btn-secondary" disabled>Add Notes</Button>

            <Button className="btn btnIri mr-2 fR" onClick={handleExit} >Exit</Button>
            <Button className="btn btnIri mr-2 fR" type="submit">Next</Button>
            <Button className="btn btnIri mr-2 fR" onClick={() => handleBack()}>Previous</Button>
          </div>
        </Form>
      </div>
      <div className="clearfix"></div>
    </>
  )
}

export default ExaminationDetails;
