
import { CLEAR_PATIENT_DETAILS, LOGOUT_USER, SESSION_EXPIRED, SET_DATA, SET_PEROSNAL_REFERRAL_DETAILS } from "./types";

import axios from "axios";
import { env_vars } from '../../Utils/config';
import { checkAuth, removeUserSession } from "../../Utils/commonData";
import documentService from "Utils/document.service";

export const searchPatientDetails = (props) => dispatch => {
  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })

  dispatch({ type: CLEAR_PATIENT_DETAILS })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_personaldetails?${str}`)
    .then(res => {
      if (res.data.length !== 0) {
        dispatch({ type: SET_DATA, payload: res.data })
      } else {
        dispatch({ type: SET_DATA, payload: [] })
      }
      return res.data;
    }).catch(err => {
      if (!checkAuth(err)) {
        removeUserSession()
        dispatch({ type: LOGOUT_USER })
        window.location = '/login?session=expired'
      };
      console.log(err)
      return null
    })
};

export const createPatientDetails = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_personaldetails`, props)
};


export const createExaminationDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_cervixexamination`, props)
};


export const createTreatmentDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_cervixtreatment?examinationid=${props.examinationid}`, props)
};

export const createSmearResultDetails = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_cervixexaminationresults?examinationid=${props.examinationid}`, props)
};


export const getSmearResultDetailsById = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_cervixexaminationresults/${props}`)
};

export const getPatientDetailsById = (props) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_personaldetails/${props}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      let { systemid } = res.data;
      dispatch(getPersonalReferralDetailsById(systemid))
      return res.data;
    }).catch(err => {
    /*   if (!checkAuth(err)) {
        removeUserSession()
        dispatch({ type: LOGOUT_USER })
        window.location = '/login?session=expired'
      }; */
      dispatch({ type: SET_DATA, payload: [] })
      return null
    })
};


export const getCervixExaminations = (props) => dispatch => {

  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_cervixexamination?${str}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      // dispatch({ type: SET_EXAMINATIONS, payload: res.data })

      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getCervixExaminationById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_cervixexamination/${id}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};



export const getUserDetails = (props = {}) => dispatch => {

  let str = '';
  Object.entries(props).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_userdetails?${str}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};


export const getCervixExaminationDetailsById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_cervixexaminationdetails/${id}`)
    .then(res => {
      // dispatch({ type: SET_DATA, payload: res.data })
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getCervixTreatmentDetailsById = (id) => dispatch => {
  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_cervixexaminationdetails/${id}`)
    .then(res => {
      return res.data;
    }).catch(err => {
      console.log(err)
      return null
    })
};

export const getPersonalReferralDetailsById = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_personalreferraldetails/${props}`).then(a => {
    dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: a.data })
    return a
  })
};



//Referral Details
export const updatePatientDetails = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.put(`${env_vars.api_url}/tbl_personaldetails/${props.systemid}`, props)
};


export const updatePersonalReferralDetailsById = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.put(`${env_vars.api_url}/tbl_personalreferraldetails/${props.referralid}`, props)
};

//Update inUseBy
export const updateInUseBy = (props) => dispatch => {

  //  dispatch(fetchStart());
  return axios.get(`${env_vars.api_url}/tbl_personaldetails/inuseby/${props}`)
    .catch(err => {
      if (!checkAuth(err)) {
        removeUserSession()
        dispatch({ type: LOGOUT_USER })
        window.location = '/login?session=expired'
      };
    })
};


export const uploadFile = (data) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_cervixexamination/file-upload`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
};

export const uploadFiles = (data, examinationid) => dispatch => {
  //  dispatch(fetchStart());
  return axios.post(`${env_vars.api_url}/tbl_cervixexamination/files-upload?examination=${examinationid}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
};


export const getRefHpvSmearTest = (data) => dispatch => {
  //  dispatch(fetchStart());
  let str = '';
  Object.entries(data).forEach(([key, value], index) => {
    str += index ? `&${key}=${value}` : `${key}=${value}`
  })


  return axios.post(`${env_vars.api_url}/tbl_refhpvsmeartest`, data)
};

export const generateLetterTemplate = (data) => dispatch => {
  //  dispatch(fetchStart());

  return axios.post(`${env_vars.api_url}/document/generate`, data)
};

export const uploadLetterTemplate = (template, data) => dispatch => {
  //  dispatch(fetchStart());

  return axios.post(`${env_vars.editor_api}/upload`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    });
};

export const uploadDocxLetter = (data) => dispatch => {
  //  dispatch(fetchStart());

  return documentService.uploadFile(data);
};

export const getLetterTemplate = (template) => dispatch => {
  //  dispatch(fetchStart());

  return axios.get(`${env_vars.api_url}/document/template?letterTemplate=${template}`);
};


