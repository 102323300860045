import React, { useEffect, useRef, useState } from "react";
import '../../../css/PDFModal.css'; // Import your CSS file
import { Button } from "reactstrap";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import styles from 'css/styles.module.css';

import {  uploadDocxLetter } from "../../../redux/actions/data.action";
import { fileUpload, getFile } from "redux/actions/file.action";
import documentService from "Utils/document.service";
import { baseToFormData } from "Utils/helpers";
import { env_vars } from "Utils/config";



const LetterTemplate = ({ title }) => {
	const location = useLocation();
	const navigate = useNavigate()
	const dispatch = useDispatch();
	let {template} = useParams();
	const { user} = useSelector(({auth}) => auth);
	const [rnd, setRnd] = useState(0);
	const [urlContent, setUrlContent] = useState('http://localhost:3500/editor?fileName=new.docx&directUrl=true');
	const [activeFile, setActiveFile] = useState('new.docx');
	const componentRef = useRef();
	const fileInputRef = useRef(null);

	const handleButtonClick = () => {
	  // Trigger file input click
	  fileInputRef.current.click();
	};

	const handleLoad = (e) => {
		let file = e.target.files[0]
		setUrlContent('')
	
			if (file) {
			  const formData = new FormData();
			  formData.append('uploadedFile', file, `${String(template).replace(/ /g,"_")}.docx`);
		  
	
		
			console.log(file)
			dispatch(uploadDocxLetter(formData))
				.then((response) => {
				  let { filename} = response.data;
				  console.log(filename, 'FILE NAME')
			
				  console.log('File uploaded successfully:', response.data);
				//   handleGetTemplate(template)
				setActiveFile(filename)
				setUrlContent(`http://localhost:3500/editor?fileName=${filename}&userid=uid-0&lang=en&directUrl=true`)
				setRnd(Math.random())
				  // Handle success if needed
				})
				.catch((error) => {
				  console.error('Error uploading file:', error);
				  // Handle error if needed
				});
			} else {
			  console.warn('No file selected for upload.');
			  // Handle no file selected if needed
			}
		  };
	  


	
	const handleSave = async(val) => {
		// setUrlContent(null);
		setRnd(Math.random());
		console.log(activeFile, 'active file')
		let dlFile = await documentService.downloadFile(activeFile)
		console.log(dlFile, 'DL FILE')
	let file = await baseToFormData(`http://localhost:3500/download?fileName=${activeFile}`, activeFile);
		console.log(file)
		const formData = new FormData();
		formData.append('file', file, activeFile);
		dispatch(fileUpload(formData, '/Templates'))
			.then((res) => {
						console.log(res, 'res')
			  // getItems(e.destinationDirectory)
			})
			.catch(err => {
			  console.log(err)
			})
	  }
	

	const handleExit = () => {
		navigate(-1)
	}
	
	const handleActiveFile = async (val) => {
		let actv = await dispatch(getFile('filePath', `/Templates/${val}`))
			if(!actv){
				setActiveFile('new.docx')
				setUrlContent(`http://localhost:3500/editor?fileName=new.docx&userid=uid-0&lang=en&directUrl=false`)
			} else {
				documentService.deleteFile(val)

				let file = await baseToFormData(`${env_vars.api_url}/files/download/filePath?filePath=/Templates/${val}`, activeFile);
				const formData = new FormData();
				formData.append('uploadedFile', file, val);
				dispatch(uploadDocxLetter(formData))
				.then((response) => {
				  let { filename} = response.data;
				  setActiveFile(filename)
				  setUrlContent(`http://localhost:3500/editor?fileName=${filename}&userid=uid-0&lang=en&directUrl=true`)
				})
				.catch(err => {
					console.log(err)
				})
			}
		

	}
	
	
	useEffect(() => {
		handleActiveFile(`${String(template).replace(/ /g,"_")}.docx`);
		
		return () => {
			documentService.deleteFile(`${String(template).replace(/ /g,"_")}.docx`)
		}
	}, [template])

	
	useEffect(() => {
		console.log('trerender')
		componentRef.current = componentRef.current
	}, [rnd])
	
	

console.log(location, user, urlContent, 'location',template)
	return (
		<>
		 <div className={styles.wrapper}>
    {urlContent && 
      <iframe
      title="Embedded Website"
      src={urlContent}
      width="85%"
      height="550px"
      maxHeight="100%"
      frameBorder="0"
      allowFullScreen
    ></iframe>  
    }
    <div className="d-flex w-100 justify-content-end pdf-actions">
    <input
       type="file"
       ref={fileInputRef}
       onChange={handleLoad}
       style={{ display: 'none' }}
        />
          <Button className="btn btnIri mr-3"  onClick={handleButtonClick}>
      Load
          </Button>
      <Button className="btn  mr-3" onClick={() => handleSave(urlContent)}>Save</Button>
      <Button className="btn btnIri mr-3" onClick={handleExit}>Exit</Button>
    </div>
    </div>
		</>

	);
};

export default LetterTemplate;
