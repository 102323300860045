import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import StepWizard from "react-step-wizard";



const TreatmentDetails = () => {
    const navigate = useNavigate();
    const { patientDetails } = useSelector(({ data }) => data)

    const [validated, setValidated] = useState(false);

    const [stepWizard, setStepWizard] = useState(null);
    const [user, setUser] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const assignUser = (val) => {
        setUser((user) => ({
            ...user,
            ...val,
        }));
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = () => {
        alert("You r done. TQ");

    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };



    const ActionButtons = (props) => {
        const handleBack = () => {
            props.previousStep();
        };

        const handleNext = () => {
            props.nextStep();
        };

        const handleFinish = () => {
            props.lastStep();

        };

        return (
            <div>
                <Row>
                    {props.currentStep > 1 && (
                        <Col>
                            <Button onClick={handleBack}>Back</Button>
                        </Col>
                    )}
                    <Col>
                        {props.currentStep < props.totalSteps && (
                            <Button onClick={handleNext}>Next</Button>
                        )}
                        {props.currentStep === props.totalSteps && (
                            <Button onClick={handleFinish}>Finish</Button>
                        )}
                    </Col>
                </Row>
            </div>
        );
    };

    const One = (props) => {
        const [info1, setInfo1] = useState({});
        const [error, setError] = useState("");

        const onInputChanged = (event) => {
            const targetName = event.target.name;
            const targetValue = event.target.value;

            setInfo1((info1) => ({
                ...info1,
                [targetName]: targetValue,
            }));
        };

        const [validated, setValidated] = useState(false);

        const handleSubmit = (event) => {
            console.log('handleSubmit', event)
            const form = event.currentTarget;

            console.log('handleSubmit form', form)
            console.log('handleSubmit form checkValidity', form.checkValidity());
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                setError("");
                props.nextStep();
                props.userCallback(info1);
                event.preventDefault();
                event.stopPropagation();
            }

            setValidated(true);
        };



        return (
            <div>
                <Form
                    id="formValid"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                >
                    <div className="text-xs-center fL">

                        <Button type="submit" className="btn btnIri marRight20">NEXT</Button>

                    </div>
                    <br /><br /><br />
                    <div className="clr"></div>
                    <Row className="mb-3">

                        <Form.Group as={Col} md="6" controlId="validationCustom01">


                            {/* <Form.Check
                                className="fL"
                                label="Waiting Time Target Not Met"
                                feedback="You must agree before submitting."
                                feedbackType="invalid"
                                onChange={onInputChanged}
                            /> */}
                            <br />
                            <br />
                            <br />
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustomFirstApp"
                        >
                            <Form.Label className="col-md-6">
                                Examination Ref No
                            </Form.Label>
                            <Form.Control
                                className="col-md-6"
                                type="text"
                                placeholder=""
                                defaultValue=""
                                name="FirstApptDate"
                                onChange={onInputChanged}
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="8" controlId="validationCustom01">
                            <div className="col-md-12 ">
                                <h3 className="card-title txtLeft">Treatment</h3>
                                <hr />
                                <div className="clr"></div>
                                <br />
                                <h4 className="text-left"><b>Cervical</b></h4>
                                <br />
                            </div>

                            <Row className="mb-3" >


                                <Form.Group as={Col} md="12">
                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""

                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Endometrial Biopsy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Laser Loop
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Fisher Cone
                                    </Form.Label>

                                    <div className="clr"></div>
                                    <br />
                                    {/* ===================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Trachalectomy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Laser Ablation
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Laser Cone
                                    </Form.Label>

                                    <div className="clr"></div>
                                    <br />
                                    {/* ===================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Cold zaogulation
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Cryotheraphy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Knife Cone
                                    </Form.Label>

                                    <div className="clr"></div><br />
                                    {/* ===================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Radical Hysterectomy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Loop Exision
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Diathermy
                                    </Form.Label>

                                    <div className="clr"></div><br />
                                    {/* ===================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Total Hysterectomy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        None
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        SEW/NETZ
                                    </Form.Label>

                                    <div className="clr"></div><br />
                                    {/* ===================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Polypectomy
                                    </Form.Label>

                                    <Form.Check
                                        className="col-md-1 fL"

                                        type="radio"
                                        placeholder=""
                                        defaultValue=""
                                        name="check1"
                                    />
                                    <Form.Label className="col-md-3 fL text-left">
                                        Others
                                    </Form.Label>
                                    <Form.Control
                                        className="col-md-4 fL"
                                        type="text"
                                        placeholder=""
                                        defaultValue=""
                                        name="FirstApptDate"
                                        onChange={onInputChanged}
                                    />



                                    <div className="clr"></div><br /><br />
                                    {/* ===================================== */}

                                    <div className="col-md-6 fL">
                                        <Form.Check
                                            className="col-md-2 fL"

                                            type="checkbox"
                                            placeholder=""
                                            defaultValue=""
                                            name="check1"
                                        />
                                        <Form.Label className="col-md-3 fL text-left">
                                            Biopsy
                                        </Form.Label>
                                        <div className="clearfix"></div> <br />
                                        {/* <Form.Label className="fL">Select</Form.Label> */}
                                        <Form.Select className="col-md-12 fL form-control" aria-label="Default select example">
                                            <option></option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div>
                                    <div className="col-md-6 fL">
                                        <Form.Label className=" fL text-left">
                                            Reason for no Biopsy
                                        </Form.Label>
                                        <div className="clearfix"></div> <br />
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            defaultValue=""
                                        />

                                    </div>


                                </Form.Group>


                            </Row>
                            <br />
                            <br />
                        </Form.Group>
                        {/* END OF LEFT */}

                        {/* RIGHT */}

                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <div className="col-md-12 ">
                                <h3 className="card-title txtLeft transparent">
                                    .
                                </h3>
                                <hr />
                                <div className="clr"></div>
                                <br />
                                <h4 className="text-left"><b>Vaginal</b></h4>
                                <br />
                            </div>

                            <Row className="mb-3" >


                                <Form.Group as={Col} md="12">
                                    <Form.Check
                                        className="col-md-1 fL"
                                        type="radio"
                                        placeholder=""
                                        name="check2"
                                    />
                                    <Form.Label className="col-md-11 fL text-left">
                                        Name
                                    </Form.Label>
                                    <br />
                                    <div className="clearfix"></div>

                                    {/* ==================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"
                                        type="radio"
                                        placeholder=""
                                        name="check2"
                                    />
                                    <Form.Label className="col-md-11 fL text-left">
                                        Local Excision
                                    </Form.Label>
                                    <br />
                                    <div className="clearfix"></div>

                                    {/* ==================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"
                                        type="radio"
                                        placeholder=""
                                        name="check2"
                                    />
                                    <Form.Label className="col-md-11 fL text-left">
                                        Partial vaginectomy
                                    </Form.Label>
                                    <br />
                                    <div className="clearfix"></div>

                                    {/* ==================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"
                                        type="radio"
                                        placeholder=""
                                        name="check2"
                                    />
                                    <Form.Label className="col-md-11 fL text-left">
                                        Total vaginectomy
                                    </Form.Label>
                                    <br />
                                    <div className="clearfix"></div>

                                    {/* ==================================== */}

                                    <Form.Check
                                        className="col-md-1 fL"
                                        type="radio"
                                        placeholder=""
                                        name="check2"
                                    />
                                    <Form.Label className="col-md-11 fL text-left">
                                        Vaporisation
                                    </Form.Label>
                                    <br />
                                    <div className="clearfix"></div>

                                    {/* ==================================== */}

                                    <Form.Select className="col-md-12 fL form-control" aria-label="Default select example">
                                        <option></option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>



                                </Form.Group>
                            </Row>

                        </Form.Group>

                        <br />
                        <br />
                        {/* RIGHT */}
                    </Row>
                    {/* END OF ROW */}

                    <br></br>
                    <br></br>
                    <div className="text-xs-center fL">

                        <Button type="submit" className="btn btnIri marRight20">NEXT</Button>

                    </div>

                </Form>

            </div>
        );
    };

    const Two = (props) => {
        const [info2, setInfo2] = useState({});
        const [error, setError] = useState("");
        const [validated, setValidated] = useState(false);

        const onInputChanged = (event) => {
            const targetName = event.target.name;
            const targetValue = event.target.value;

            setInfo2((info2) => ({
                ...info2,
                [targetName]: targetValue,
            }));
        };

        const handleSubmit = (event) => {
            console.log('handle sumbit 2')
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();


            } else {
                console.log("SHOW SUCCESS VALIDATION SUBMIT")
                setError("");
                props.nextStep();
                props.userCallback(info2);
                event.preventDefault();
                event.stopPropagation();

            }

            setValidated(true);
        };


        const handleBack = () => {
            props.previousStep();
        };

        return (
            <div>
                <div className="card-title txtLeft">
                    <label className="card-title txtLeft">Patient Management Plan</label>
                </div>
                <div className="card-body">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>



                        <div className="text-xs-center fL">
                            <Button className="btn btnIri marRight20" onClick={handleBack}>PREV</Button>
                            <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
                        </div>
                        <div className="clearfix"></div>
                        <br />
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustom01"
                            >
                                <Form.Label>First sds</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First asdsad"
                                    defaultValue="Mark"
                                />
                                <Form.Control.Feedback>
                                    Looks good!
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustom02"
                            >
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    defaultValue="Otto"
                                />
                                <Form.Control.Feedback>
                                    Looks good!
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationCustomUsername"
                            >
                                <Form.Label>Username</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">
                                        @
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        placeholder="Username"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a username.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <div className="clearfix"></div>
                        <br />
                        <div className="text-xs-center fL">
                            <Button className="btn btnIri marRight20" onClick={handleBack}>PREV</Button>
                            <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
                        </div>
                    </Form>
                </div>

            </div>
        );
    };

    const Three = (props) => {
        console.log("step3 receive user object", props);
        console.log(props.user);

        const handleLastStep = () => {
            props.lastStep();
            // props.completeCallback();
         
        };

        return (
            <div>
                <h2>Summary user detail</h2>
                <p>Name: {props.user.name}</p>
                <p>Age: {props.user.age}</p>
                <br />
                <ActionButtons {...props} lastStep={handleLastStep} />
            </div>
        );
    };





    useEffect(() => {
        if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
            //   setPatient(patientDetails)
        } else {
            navigate('/dashboard/home')
        }




    }, [patientDetails])


    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Patient Treatment Details</h4>
            </div>
            <div className="card-body">
                <   StepWizard
                    instance={assignStepWizard}
                    onStepChange={handleStepChange}
                >
                    <One userCallback={assignUser} />
                    <Two user={user} userCallback={assignUser} />
                    <Three user={user} completeCallback={handleComplete} />
                </StepWizard>
            </div>
        </>
    );
};

export default TreatmentDetails;
