import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "reactstrap";
import { CLEAR_SMEAR_RESULTS, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_SMEAR_RESULTS, SET_TREATMENT_DETAILS } from "../../../redux/actions/types";
import { getCervixExaminationDetailsById, getRefHpvSmearTest, getSmearResultDetailsById } from "../../../redux/actions/data.action";
import { groupByField } from "../../../Utils/helpers";
import moment from "moment/moment";


const Results = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { viewOption, edited } = useSelector(({ ui }) => ui)
  const { patientDetails, smearResults, examination, examinationDetails } = useSelector(({ data }) => data);
  const [hpvProps, setHpvProps] = useState({});
  const [grpResultsCode, setGrpResultsCode] = useState([]);
  const [grpTestName, setGrpTestName] = useState([]);
  const [grpTestRange, setGrpTestRange] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  const handleChanges = prop => event => {
    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let val = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value;
    let newVal = { ...smearResults, [prop]: val }

    if (val) {
      newVal.smearresultsdate1 = moment().format('YYYY-MM-DD');
    }


    dispatch({ type: SET_SMEAR_RESULTS, payload: newVal })
    dispatch({ type: SET_EDITED, payload: 'results' })
  }

  const handleSelectHpv = prop => event => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let newHpvProps = { ...hpvProps, [prop]: event.target.value }



    if (prop === 'hpvresultcode1') {
      newHpvProps = { ...newHpvProps, resultscode: event.target.value, testname: '', testrange: '', subtypes: '' }
    }

    if (prop === 'hpvtestname1') {
      newHpvProps = { ...newHpvProps, testname: event.target.value, testrange: '', subtypes: '' }
    }

    if (prop === 'hpvtestrange1') {
      let refhpv = grpTestRange.find(a => a.title === event.target.value);
      if (refhpv) {
        newHpvProps = { ...newHpvProps, testrange: refhpv.testrange, subtypes: refhpv.subtypes }
      } else {
        newHpvProps = { ...newHpvProps, testrange: "", subtypes: "" }
      }
    }

    handleRefHpvSmearTests(newHpvProps)
    dispatch({ type: SET_SMEAR_RESULTS, payload: { ...smearResults, hpvresultcode1: newHpvProps.resultscode, hpvtestname1: newHpvProps.testname, hpvtestrange1: newHpvProps.testrange, hpvsubtypestested1: newHpvProps.subtypes } })
    dispatch({ type: SET_EDITED, payload: 'results' })
  }


  const handleRefHpvSmearTests = async (prop) => {
    return await dispatch(getRefHpvSmearTest(prop))
      .then(res => {
        let grpResultCode = groupByField(res.data, 'resultscode')
        let grpTestName = groupByField(res.data, 'testname')
        let grpTestRange = groupByField(res.data, 'testrange')
        if (!prop.resultscode) {
          setGrpResultsCode(grpResultCode)
        }
        if (!prop.testname) {
          setGrpTestName(grpTestName)
        }
        if (!prop.testrange) {
          setGrpTestRange(grpTestRange)
        }


        setHpvProps(prop)

        return res.data
      })
      .catch(err => {
        console.log(err, 'hpverr')
        return []
      })


  }


  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${viewOption === "enter" ? "/dashboard/patient/treatment" : "/dashboard/viewPatient/treatment"}`)
  }

  const handleExit = (e) => {
    if (edited.length !== 0) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_SMEAR_RESULTS })
    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)

  }


  const handleBack = () => {
    navigate(-1)
  }


  const handleGetSmearResult = (id) => {
    dispatch(getSmearResultDetailsById(id))
      .then(res => {
        if (res.data) {
          dispatch({ type: SET_SMEAR_RESULTS, payload: res.data })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
      //   setPatient(patientDetails)
      if (examination && examination.examinationid) {
        handleGetSmearResult(examination.examinationid)
      }
      let newHpv = handleRefHpvSmearTests({})
    } else {
      navigate('/dashboard/home')
    }


    return () => {
      setHpvProps({ resultscode: '', testname: '', testrange: '', subtypes: '' })
      setGrpResultsCode([])
      setGrpTestName([])
      setGrpTestRange([])
    }
  }, [])



  return (
    <>
      <div className="card-header bg-iri txtCenter mt-0">
        <h4 className="m-b-0 text-white">Smear Results</h4>
      </div>
      <div className="card-body pt-1">
        <Form
          id="formValid"
          noValidate
          onSubmit={handleSubmit}

        >
          <div className="pl-2 pr-2 mt-0">
            <div className="d-flex mt-2 mb-2">
              <div className='col-md-5 text-left mb-3 pl-2 pr-2' >
              </div>
              <div className='col-md-7 text-right' >
                {/* <label className="col-md-5 text-right disabled" >Treatment Date</label> */}
                <label className="col-md-5 text-right text-13"  >Patient Notification Date</label>
                <input
                  type="date" id="mdmdate" name="mdmdate" className="form-control col-md-3" placeholder="MDM Date"
                  value={smearResults.histology1referralnotifydate}
                  onChange={handleChanges('histology1referralnotifydate')}
                // onChange={handleChanges('mdmdate')}
                />
                <input
                  // disabled
                  type={smearResults.smearresultsdate1 ? "date" : "text"} id="mdmdate" name="mdmdate" className="form-control col-md-3 ml-2" placeholder="MDM Date"
                  onChange={handleChanges('smearresultsdate1')}
                  value={smearResults.smearresultsdate1 ? moment(smearResults.smearresultsdate1).format('YYYY-MM-DD') : "Waiting"}
                  style={{ backgroundColor: '#FFFFCC', fontWeight: 500 }}
                />
              </div>
            </div>
            <div className='row bordered mt-1'>
              <h3 className='card-title text-center bordered-title'>Cytological Pattern</h3>
              <div className="d-flex pl-1 mt-1 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Negative (Normal)"
                    id="Negative (Normal)"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1negative}
                    checked={smearResults.cytologicalpattern1negative}
                    onChange={handleChanges('cytologicalpattern1negative')}
                  // className="" onChange={handleCha nges('treatmentverbalconsent')}
                  />&nbsp;
                  <label htmlFor="Negative (Normal)">Negative (Normal)</label>
                </div>
                <div className="col-md-3 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Mild Dyskaryosis (Low Grade)"
                    id="Mild Dyskaryosis (Low Grade)"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1milddyskaryosis}
                    checked={smearResults.cytologicalpattern1milddyskaryosis}
                    onChange={handleChanges('cytologicalpattern1milddyskaryosis')}
                  // className="" onChange={handleCha nges('treatmentverbalconsent')}
                  />&nbsp;
                  <label htmlFor="Mild Dyskaryosis (Low Grade)">Mild Dyskaryosis (Low Grade)</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Possible Invasion"
                    id="Possible Invasion"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1possibleinvasion}
                    checked={smearResults.cytologicalpattern1possibleinvasion}
                    onChange={handleChanges('cytologicalpattern1possibleinvasion')}

                  // value={Boolean(treatmentDetails.treatmentverbalconsent) == true ? false : true}
                  // checked={Boolean(treatmentDetails.treatmentverbalconsent) == true}
                  // className="" onChange={handleCha nges('treatmentverbalconsent')}
                  />&nbsp;
                  <label htmlFor="Possible Invasion">Possible Invasion</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Unsatisfactory"
                    id="Unsatisfactory"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1unsatisfactory}
                    checked={smearResults.cytologicalpattern1unsatisfactory}
                    onChange={handleChanges('cytologicalpattern1unsatisfactory')}
                  />&nbsp;
                  <label htmlFor="Unsatisfactory">Unsatisfactory</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Moderate Dyskaryosis"
                    id="Moderate Dyskaryosis"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1moderatedyskaryosis}
                    checked={smearResults.cytologicalpattern1moderatedyskaryosis}
                    onChange={handleChanges('cytologicalpattern1moderatedyskaryosis')}
                  />&nbsp;
                  <label htmlFor="Moderate Dyskaryosis">Moderate Dyskaryosis</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Glandular Neoplasia"
                    id="Glandular Neoplasia"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1glandularneoplasia}
                    checked={smearResults.cytologicalpattern1glandularneoplasia}
                    onChange={handleChanges('cytologicalpattern1glandularneoplasia')}
                  />&nbsp;
                  <label htmlFor="Glandular Neoplasia">Glandular Neoplasia</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Borderline Endo Cervical"
                    id="Borderline Endo Cervical"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1borderlineabnormalities}
                    checked={smearResults.cytologicalpattern1borderlineabnormalities}
                    onChange={handleChanges('cytologicalpattern1borderlineabnormalities')}
                  />&nbsp;
                  <label htmlFor="Borderline Endo Cervical">Borderline Endo Cervical</label>
                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Unclear Changes"
                    id="Unclear Changes"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1abnormalunclassifiable}
                    checked={smearResults.cytologicalpattern1abnormalunclassifiable}
                    onChange={handleChanges('cytologicalpattern1abnormalunclassifiable')}
                  />&nbsp;
                  <label htmlFor="Unclear Changes">Unclear Changes</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Severe Dyskaryosis"
                    id="Severe Dyskaryosis"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1severedyskaryosis}
                    checked={smearResults.cytologicalpattern1severedyskaryosis}
                    onChange={handleChanges('cytologicalpattern1severedyskaryosis')}
                  />&nbsp;
                  <label htmlFor="Severe Dyskaryosis">Severe Dyskaryosis</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Borderline Endometrial"
                    id="Borderline Endometrial"
                    className="mr-1"
                    value={!smearResults.cytologicalpattern1borderlineendometrial}
                    checked={smearResults.cytologicalpattern1borderlineendometrial}
                    onChange={handleChanges('cytologicalpattern1borderlineendometrial')}
                  />&nbsp;
                  <label htmlFor="Borderline Endometrial">Borderline Endometrial</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>
            </div>
            <div className='row bordered pt-1 mt-2'>
              <h3 className='card-title text-center bordered-title mb-1'>HPV</h3>
              <div className="d-flex pl-1 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-4 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0"   >Result Code</h3>
                    <select name="resultscode" id="resultscode" className="form-control m-0 "
                      value={smearResults.hpvresultcode1}
                      onChange={handleSelectHpv('hpvresultcode1')}
                    >
                      <option value="">Select</option>
                      {grpResultsCode.length !== 0 && grpResultsCode.map(a => {
                        return (
                          <option value={a.title}>{a.title}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Test Name</h3>
                    <select name="testname" id="testname" className="form-control m-0"
                      value={smearResults.hpvtestname1}
                      onChange={handleSelectHpv('hpvtestname1')}

                    >
                      <option value="">Select</option>
                      {grpTestName.length !== 0 && grpTestName.map(a => {
                        return (
                          <option value={a.title}>{a.title}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <div className="bordered pl-1 pr-1 pb-1">
                    <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Test Range</h3>
                    <select name="testrange" id="testrange" className="form-control m-0"
                      value={smearResults.hpvtestrange1}
                      onChange={handleSelectHpv('hpvtestrange1')}

                    >
                      <option value="">Select</option>
                      {grpTestRange.length !== 0 && grpTestRange.map(a => {
                        return (
                          <option value={a.title}>{a.title}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

              </div>
              <div className="d-flex pl-5 pr-3 mb-1 mt-2" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>


                <div className="bordered pl-1 pr-1 pb-1 w-100">
                  <h3 className="card-title bordered-title ml-1 text-14 ml-0 mr-0 mb-0" >Sub Type Tested</h3>
                  <input name="subtypes" id="subtypes" className="form-control m-0"
                    value={smearResults.hpvsubtypestested1 ? smearResults.hpvsubtypestested1 : ''}
                    onChange={handleChanges('hpvsubtypestested1')}
                  />

                </div>

              </div>

            </div>
            <div className='row bordered mt-2 pt-1'>
              <h3 className='card-title text-center bordered-title mb-0'>Specific Infection</h3>
              <div className="d-flex pl-1 mt-2 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Trichomonas"
                    id="Trichomonas"
                    className="mr-1"
                    value={!smearResults.specificinfection1trichomonas}
                    checked={smearResults.specificinfection1trichomonas}
                    onChange={handleChanges('specificinfection1trichomonas')}
                  />&nbsp;
                  <label htmlFor="Trichomonas">Trichomonas</label>
                </div>
                <div className="col-md-3 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Actinomyces"
                    id="Actinomyces"
                    className="mr-1"
                    value={!smearResults.specificinfection1actinomyces}
                    checked={smearResults.specificinfection1actinomyces}
                    onChange={handleChanges('specificinfection1actinomyces')}
                  />&nbsp;
                  <label htmlFor="Actinomyces">Actinomyces</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Herpes"
                    id="Herpes"
                    className="mr-1"
                    value={!smearResults.specificinfection1herpes}
                    checked={smearResults.specificinfection1herpes}
                    onChange={handleChanges('specificinfection1herpes')}
                  />&nbsp;
                  <label htmlFor="Herpes">Herpes</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="None"
                    id="None"
                    className="mr-1"
                    value={!smearResults.specificinfection1none}
                    checked={smearResults.specificinfection1none}
                    onChange={handleChanges('specificinfection1none')}
                  />&nbsp;
                  <label htmlFor="None">None</label>
                </div>
              </div>
              <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Candida"
                    id="Candida"
                    className="mr-1"
                    value={!smearResults.specificinfection1candida}
                    checked={smearResults.specificinfection1candida}
                    onChange={handleChanges('specificinfection1candida')}
                  />&nbsp;
                  <label htmlFor="Candida">Candida</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="Wart virus"
                    id="Wart virus"
                    className="mr-1"
                    value={!smearResults.specificinfection1wartvirus}
                    checked={smearResults.specificinfection1wartvirus}
                    onChange={handleChanges('specificinfection1wartvirus')}
                  />&nbsp;
                  <label htmlFor="Wart virus">Wart virus</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                  <input
                    type="checkbox"
                    name="BV (Clue cells)"
                    id="BV (Clue cells)"
                    className="mr-1"
                    value={!smearResults.specificinfection1bacterialvaginosis}
                    checked={smearResults.specificinfection1bacterialvaginosis}
                    onChange={handleChanges('specificinfection1bacterialvaginosis')}
                  />&nbsp;
                  <label htmlFor="BV (Clue cells)">BV (Clue cells)</label>
                </div>
                <div className="col-md-3 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >

                </div>
              </div>

            </div>

            <div className="d-flex pl-1 mt-2 " style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
              <div className="col-md-6 mb-1 pl-5 text-left" style={{ flexGrow: 1 }} >
                <label className="col-md-3 text-right font-500"  >Laboratory</label>
                <select name="hvslaboratoryname" id="hvslaboratoryname" className="form-control col-md-7"
                  value={smearResults.hvslaboratoryname}
                  onChange={handleSelectHpv('hvslaboratoryname')}

                >
                  <option value="">Select</option>
                  {laboratories.length !== 0 && laboratories.map(a => {
                    return (
                      <option value={a.title}>{a.title}</option>
                    )
                  })}
                </select>
              </div>
              <div className="col-md-6 mb-1  pl-5 text-left" style={{ flexGrow: 1 }} >
                <label className="col-md-3 text-right font-500"  >Prescription</label>
                <input
                  name="smearprescription"
                  id="smearprescription"
                  className="form-control col-md-7"
                  value={smearResults.smearprescription}
                  onChange={handleChanges('smearprescription')}
                // value={Boolean(treatmentDetails.treatmentverbalconsent) == true ? false : true}
                // checked={Boolean(treatmentDetails.treatmentverbalconsent) == true}
                // className="" onChange={handleCha nges('treatmentverbalconsent')}
                />
              </div>

            </div>


            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
              <Button className="btn marRight20"
                disabled
                onClick={() => handleBack()}
              // disabled={attendance === 'scheduled' || !referral.appointmentcancellationreason || viewOption === 'review'}
              >Previous</Button>

              <Button type="submit" className="btn btnIri marRight20"
              // disabled={referral.appointmentcancellationreason}
              >Next</Button>
              <Button className="btn btnIri"
                onClick={() => handleExit()}
              // type="reset"
              >Exit</Button>
            </div>

          </div>
        </Form >
      </div>
    </>
  )
}

export default Results;
