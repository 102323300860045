import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { removeUserSession } from '../../Utils/commonData';
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_PATIENT_DETAILS, LOGOUT_USER, OPEN_MODAL, SET_VIEW_OPTION } from '../../redux/actions/types';
import { env_vars } from '../../Utils/config';
import NewPatientModal from '../../component/Modals/NewPatientModal';

const Sidebar = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(({ auth }) => auth);
    const { patientDetails } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const LOCAL_STORAGE_KEY_PATIENT_MENU = 'isPatientExpanded';
    const LOCAL_STORAGE_KEY_STAT_MENU = 'isStatsExpanded';
    const [expanded, setExpanded] = useState(null);
    const navigate = useNavigate();

    const handleNavigate = (val) => {
        // navigate(val)
        navigate(val)

    }



    const handleLogout = () => {
        removeUserSession();
        dispatch({ type: LOGOUT_USER })
        navigate('/login');

    }


    const setViewOption = (e) => {
        if (edited.length !== 0 && viewOption !== e && e !== 'enter') {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        dispatch({ type: SET_VIEW_OPTION, payload: e })

    }

    const setActiveTab = (e) => {
        clearLocalStorage();
        // if(e !== 'patient' && edited){
        //     dispatch({type: OPEN_MODAL, payload: 'save_details'})
        // }
        setExpanded(e == expanded ? null : e)

    }

    // const setActiveTabFailsafe= (e) =>{
    //             setExpanded(null)    
    //             if(edited && viewOption === 'enter' ){
    //                 dispatch({type: OPEN_MODAL, payload: 'save_details'})
    //             }
    // }

    const clearLocalStorage = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY_PATIENT_MENU, false);
        localStorage.setItem(LOCAL_STORAGE_KEY_STAT_MENU, false);
    }

    const handleEnter = (val) => {

        dispatch({ type: OPEN_MODAL, payload: (edited.length !== 0 && val === 'enter') ? 'save_details' : 'hospitalNumber' });
        setViewOption(val);
        setActiveTab(val === 'enter' ? 'patient' : val);
        if (edited.length === 0) {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
        }
    }



    useEffect(() => {
        //  JQUERY SIDE BARS

    }, []);

    return (
        <>
            <NewPatientModal
                navigate={(e) => navigate(e)}
            />
            <aside className="left-sidebar">
                <div className='logoProfile'>
                    <img src={env_vars.api_url + `/static/${user.imgUrl}`} alt="user-img" className="img-circle img-fluid"
                        width={150}
                        height={150}
                    />

                    <span className=" waves-effect waves-dark" onClick={(e) => e.preventDefault()} aria-expanded="false">
                        {/* <span className="hide-menu">{user.name}</span> */}
                    </span>
                </div>
                <div className='clearfix'></div>
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            {/* //PARENT MENU 1 */}
                            {viewOption === 'enter' && patientDetails && (patientDetails.hospitalnumber || patientDetails.nhsnumber) ?
                                <>
                                    <li onClick={() => setViewOption('enter')}
                                        onMouseEnter={() => setActiveTab('patient')}
                                        onMouseLeave={() => setActiveTab(null)}
                                    >
                                        <Link className={"has-arrow waves-effect waves-dark " + (expanded !== 'patient' ? 'has-arrow-custom no' : ' has-arrow-custom active')} onClick={() => setActiveTab('patient')}>
                                            <i className="ti-user"></i>
                                            <span className="hide-menu" >Enter
                                                {/* <span className="badge badge-pill badge-cyan ml-auto">3</span> */}
                                            </span>
                                        </Link>
                                        <ul aria-expanded="false" className={expanded == 'patient' ? '' : 'collapse'}>
                                            <li
                                                onClick={() => handleNavigate(`/dashboard/patient/enterPatient`)}
                                            ><NavLink
                                                className={`waves-effect waves-dark`} aria-expanded="false"
                                                onClick={() => handleNavigate(`/dashboard/patient/enterPatient`)}
                                            //  to={`/dashboard/patient/enterPatient`}
                                            > <span className="hide-menu">Personal Details</span></NavLink></li>
                                            <li
                                                onClick={() => patientDetails.systemid && handleNavigate(`/dashboard/patient/referralDetails`)}
                                            >
                                                <NavLink
                                                    className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    //  aria-expanded="false"
                                                    //  to={`/dashboard/patient/referralDetails`}
                                                    onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/referralDetails`))}

                                                > <span className="hide-menu">Referral Details</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/examination`))}
                                            >

                                                <NavLink
                                                    className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    aria-expanded="false"
                                                    onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/examination`))}
                                                // to={`/dashboard/patient/examination`}
                                                > <span className="hide-menu">Examination</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/management`))}
                                            ><NavLink
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/management`))}
                                                className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                aria-expanded="false" > <span className="hide-menu">Management</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/treatment`))}
                                            ><NavLink
                                                className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/treatment`))}
                                                aria-expanded="false"

                                            > <span className="hide-menu">Treatment</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/results`))}
                                            ><NavLink
                                                className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/patient/results`))}
                                                aria-expanded="false"
                                            // to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/results`}
                                            > <span className="hide-menu">Results</span></NavLink></li>
                                            {/* <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/appointment`}> <span className="hide-menu">Appointment</span></NavLink></li> */}

                                        </ul>
                                    </li></> : <li onClick={() => setViewOption('enter')}
                                    >
                                    <Link className="waves-effect waves-dark" onClick={() => handleEnter('enter')} aria-expanded="false" > <i className="ti-user"></i><span className="hide-menu">Enter</span></Link>
                                </li>}
                            {viewOption === 'review' && patientDetails && (patientDetails.hospitalnumber || patientDetails.nhsnumber) ?
                                <>
                                    <li onClick={() => setViewOption('review')}
                                        onMouseEnter={() => setActiveTab('review')}
                                        onMouseLeave={() => setActiveTab(null)}
                                    >
                                        <Link className={"has-arrow waves-effect waves-dark " + (expanded !== 'review' ? 'has-arrow-custom no' : ' has-arrow-custom active')}
                                            onClick={() => { setViewOption('review'); setActiveTab('review'); }}
                                            to="/dashboard/viewPatient"
                                        >
                                            <i className="ti-folder"></i>
                                            <span className="hide-menu" >Review
                                                {/* <span className="badge badge-pill badge-cyan ml-auto">3</span> */}
                                            </span>
                                        </Link>
                                        <ul aria-expanded="false" className={expanded == 'review' ? '' : 'collapse'}>
                                            <li
                                                onClick={() => handleNavigate(`/dashboard/viewPatient/enterPatient`)}
                                            ><NavLink
                                                className={`waves-effect waves-dark`}
                                                aria-expanded="false"
                                            //  onClick={() =>   handleNavigate(`/dashboard/viewPatient/enterPatient`)}
                                            //  to={`/dashboard/patient/enterPatient`}
                                            > <span className="hide-menu">Personal Details</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/viewPatient/examination`))}
                                            >

                                                <NavLink
                                                    className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                    aria-expanded="false"
                                                    onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/viewPatient/examination`))}
                                                // to={`/dashboard/patient/examination`}
                                                > <span className="hide-menu">Examination</span></NavLink></li>
                                            <li
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/viewPatient/management`))}
                                            ><NavLink
                                                onClick={() => (patientDetails.systemid && handleNavigate(`/dashboard/viewPatient/management`))}
                                                className={`${patientDetails.systemid ? 'waves-effect waves-dark' : 'disabled'}`}
                                                aria-expanded="false" > <span className="hide-menu">Management</span></NavLink></li>

                                            {/* <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to={`/dashboard/${viewOption === 'enter' ? 'patient' : 'viewPatient'}/appointment`}> <span className="hide-menu">Appointment</span></NavLink></li> */}

                                        </ul>
                                    </li>
                                </>
                                :
                                <>
                                    <li><Link className="waves-effect waves-dark" onClick={() => handleEnter('review')} aria-expanded="false" ><i className="ti-folder"></i><span className="hide-menu">Review</span></Link></li>
                                </>
                            }

                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setViewOption('trace'); setActiveTab('trace') }} aria-expanded="false" to="/dashboard/trace"><i className="ti-search"></i><span className="hide-menu">Trace</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('letters'); setViewOption('letters'); }} aria-expanded="false" to="/dashboard/letter-reports/update"><i className="ti-email"></i><span className="hide-menu">Letter</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('appointments'); setViewOption('appointments'); }} aria-expanded="false" to="/dashboard/appointments"><i className="ti-calendar"></i> <span className="hide-menu">Appointments</span></NavLink></li>
                            <li><NavLink className="waves-effect waves-dark" onClick={() => { setActiveTab('failsafe'); setViewOption('failsafe'); }} aria-expanded="false" to="/dashboard/failsafe"><i className="ti-shield"></i><span className="hide-menu">Failsafe</span></NavLink></li>
                            {/* //PARENT MENU 2 */}
                            <li
                                onMouseEnter={() => setActiveTab('statistic')}
                                onMouseLeave={() => setActiveTab(null)}
                                onClick={() => setViewOption('statistics')}
                            >
                                <Link className={"has-arrow waves-effect waves-dark" + (expanded !== 'statistic' ? 'has-arrow-custom  no' : ' has-arrow-custom active')} onClick={() => setActiveTab('statistic')} >
                                    <i className="ti-stats-up"></i>
                                    <span className="hide-menu">Statistics</span>
                                </Link>
                                <ul aria-expanded="false" className={expanded == 'statistic' ? '' : 'collapse'}>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/bsccp" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">BSCCP</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/automatic" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Automatic</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/manual" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Manual</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/brokerlog" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Broker Log</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/treatment" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Treatment</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/specimen" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Specimen</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/referralleters" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Referral Letters</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/statistics/waitingtimes" data-toggle="tooltip" title="Default tooltip"> <span className="hide-menu">Waiting Times</span></NavLink></li>
                                </ul>
                            </li>
                            {/* <li>
                                <Link className={"has-arrow waves-effect waves-dark " + ( !patientExpand ?'has-arrow-custom no':' has-arrow-custom active' )} onClick={setActiveTabPatient}>
                                    <i className="ti-user"></i>
                                    <span className="hide-menu" >Test Screens 
                                    </span>
                                    </Link>
                                <ul aria-expanded="false" className={patientExpand ? '' : 'collapse'}>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/patient/referralDetails"> <span className="hide-menu">Referral Details</span></NavLink></li>
                                    <li><NavLink className={"waves-effect waves-dark"} aria-expanded="false" to="/dashboard/patient/casereview"> <span className="hide-menu">Case Review</span></NavLink></li>
                                </ul>
                            </li> */}
                            {<li> <Link className="waves-effect waves-dark" aria-expanded="false" to={`/dashboard/testPage`}><i className="fa fa-circle-o text-success"></i><span className="hide-menu">Test Page</span></Link></li>}
                            <li> <a className="waves-effect waves-dark" aria-expanded="false" onClick={handleLogout}><i className="fa fa-circle-o text-success"></i><span className="hide-menu">Log Out</span></a></li>
                            {/* <li> <a className="waves-effect waves-dark" onClick={(e) => e.preventDefault()} aria-expanded="false"><i className="fa fa-circle-o text-info"></i><span className="hide-menu">FAQs</span></a></li> */}

                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}



export default Sidebar;