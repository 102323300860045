import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, FormFeedback, Input, Label } from 'reactstrap';
import { removeUserSession } from "../../Utils/commonData";
import moment from 'moment';
import '../../css/Licensing.css'; // Import your CSS file
import CustomModal from '../../component/Modals/CustomModal';
import { useDispatch, useSelector } from 'react-redux';
import { OPEN_MODAL } from '../../redux/actions/types';
import { loginUser } from '../../redux/actions/auth.action';



export default function SetupScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { modal } = useSelector(({ ui }) => ui)
    const [entry, setEntry] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);

    const handleEntry = (e) => {
        e.preventDefault()
        if (entry) {
            // removeUserSession();
            // navigate('/login')
            if (entry === 'systemmanagement') {
                dispatch({ type: OPEN_MODAL, payload: 'adminpass' })

            }
        } else {
            localStorage.setItem('isSetup', true)
            removeUserSession();
            navigate('/login')
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(loginUser({
            password: password,
            loginType: 'admin'
        }))
            .then((res) => {
                if (res.token) {
                    dispatch({ type: OPEN_MODAL })
                    return navigate('/systemmanagement');
                }
                if (res.message) {
                    setError('Invalid!')
                }
            })

    }




    return (
        <>
            <CustomModal
                open={modal === 'adminpass'}
                title="Enter Your Password"
            >
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <label className="col-md-3 d-flex text-15">Password</label>
                        <div className='col-md-9'>
                            <Input
                                id="Password" name="Password"
                                className="form-control "
                                placeholder="Enter Admin Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                invalid={error}
                            />
                            <FormFeedback>
                                {error}
                            </FormFeedback>
                        </div>
                    </div>

                    {/* <div className="center pl-3 pr-3">
                        <label className="control-label col-md-3 center">Password</label>
                        <input type="text" id="hospitalnumber" className="col-md-9 form-control" placeholder="Password"
                            // style={{ width: '300px' }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div> */}
                    <hr style={{ borderColor: 'black' }} />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        < Button type="submit" className='btn btnIri' style={{ width: '75px' }}>OK</Button>
                        < Button onClick={() => dispatch({ type: OPEN_MODAL })} className='btn color-black' style={{ width: '75px' }} >Cancel</Button>

                    </div>
                </form>
            </CustomModal>
            <div className="licensing-container">
                <div className="licensing-wrapper">
                    <div className="bordered p-2">
                        <div className="licensing-viewer p-2">
                            <div className="paper">
                                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', minHeight: '40px' }}>
                                    <h3 style={{ fontSize: '50px', color: "#8f3644" }}>COMPU</h3>
                                    <h3 style={{ fontSize: '50px', fontWeight: '600' }} >SCOPE</h3>
                                </div>
                            </div>
                            <div className='mt-2' style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                                <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '20px', fontWeight: '600' }}>{moment().format('MM/DD/YYYY')}</h6>
                                <h6 className='pr-2' style={{ color: "#8f3644", fontWeight: '600', lineHeight: '20px' }} >Version 11</h6>
                            </div>
                            <br />
                            <div style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '10px', fontWeight: '500' }}>Copyright © 1996-2023 IRISOFT Ltd. All rights reserved.</h6>
                            </div>
                            <div className='mt-2' style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                                <p className='pl-2 text-13' style={{ fontWeight: '500' }} >This product is licensed to:</p>
                                <div className='d-flex'>
                                    <Input type="checkbox" name="productentry" className="mb-1"
                                        id="reportwriter"
                                        checked={entry === 'reportwriter'}
                                        onClick={(e) => setEntry(entry === 'reportwriter' ? null : 'reportwriter')}
                                    />
                                    <Label htmlFor="reportwriter" className="ml-1 text-12" style={{ lineHeight: '22px' }}>Report Writer</Label>
                                </div>
                                <div className='d-flex'>
                                    <Input type="checkbox" name="productentry" className=""
                                        id="systemmanagement"
                                        checked={entry === 'systemmanagement'}
                                        onClick={(e) => setEntry(entry === 'systemmanagement' ? null : 'systemmanagement')}
                                    />
                                    <Label htmlFor="systemmanagement" className="ml-1 text-12" style={{ lineHeight: '22px' }}>System Management</Label>
                                </div>
                            </div>
                            <div className="paper">
                                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: 'auto' }}>
                                    <div style={{ fontSize: '25px', fontWeight: '500', color: "#8f3644", lineHeight: '30px' }}>Your Hospital Name</div>
                                    <div style={{ fontSize: '25px', color: "#8f3644", lineHeight: '30px' }}>SITE 2</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <Button onClick={handleEntry} disabled={entry === 'reportwriter'}>Colposcopy</Button>
                                <Button className="btn btn-secondary" disabled>Hysteroscopy</Button>
                                <Button className="btn btn-secondary" disabled>Oncology</Button>
                                <Button className="btn btn-secondary" disabled >Vulvoscopy</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
