import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import { letterTemplates } from '../../../Utils/commonData';
import { useNavigate, useLocation, useParams  } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ReportLetters(props) {
	const location = useLocation();
  let {actionType} = useParams();
  const { patientDetails: patient } = useSelector(({ data }) => data)


  console.log(actionType, location, 'letter')
  
  const navigate = useNavigate();

  const handleClick = (prop) => {
    if(actionType === 'update'){
      navigate(`/dashboard/letterTemplate/${prop.title}`)
    } else {
      navigate(`/dashboard/generate-report/${prop.title}/${patient.systemid}`)
    }
  }



    return (
        <>
        <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Print Letter Options</h4>
      </div>


      <div className="card-body">
            {/* <ReportGenerator /> */}
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div 
            className='letterBtn pl-2 pr-2'
style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '50%'}}
>
            <label  className='mr-5 mt-2 text-15' >
                    Patient Letters
                  </label>
                  <input
                    type="checkbox" name="cervixpresent"
                    id="notpresent"
                    value={false}
                  />&nbsp;
                    <label htmlFor="notpresent" className='mr-3 mt-2' >
                   Informal
                  </label>
            </div>   
            <div className='row' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%'}}>
            
            <div className='col-md-6' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {letterTemplates.filter(a => (a.type === 'patientLetter' && a.align === 'left')).map(a => {
                return(
                    <Button className='letterBtn' key={a.title} onClick={() => handleClick(a)} >{a.title}</Button>
                )
            })}
            
            </div>
            <div className='col-md-6' style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {letterTemplates.filter(a => (a.type === 'patientLetter' && a.align === 'right')).map(a => {
                return(
                    <Button className='letterBtn' key={a.title} onClick={() => handleClick(a)}>{a.title}</Button>
                )
            })}
            </div>
            </div>
            </div>
            <br/><br/>   

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div 
            className='pl-2 pr-2'
style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '50%'}}
>
            <label  className='mr-5 mt-2 text-15' >
                    GP Letters
                  </label>
                  <input
                    type="checkbox" name="cervixpresent"
                    id="notpresent"
                    value={false}
                  />&nbsp;
                    <label htmlFor="notpresent" className='mr-3 mt-2' >
                   Referrer Copy
                  </label>
            </div>   
            <div className='row' style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '70%'}}>
            
            <div className='col-md-6' style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {letterTemplates.filter(a => (a.type === 'gpLetter' && a.align === 'left')).map(a => {
                return(
                    <Button className='letterBtn' key={a.title} onClick={() => handleClick(a)}>{a.title}</Button>
                )
            })}
            
            </div>
            <div className='col-md-6' style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {letterTemplates.filter(a => (a.type === 'gpLetter' && a.align === 'right')).map(a => {
                return(
                    <Button className='letterBtn' key={a.title} onClick={() => handleClick(a)}>{a.title}</Button>
                )
            })}
            </div>
            </div>
            </div>
        </div>

        <div className='pl-2 pr-2'>
                <div className='d-flex' style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <div className='col-md-8 text-left mb-4'>
                    <Button className="btn text-dark btn-secondary mr-2" disabled>Previous Letters</Button>
                    <Button className="btn text-dark btn-secondary mr-2" onClick={() => handleClick({title: "Data Sheet"})}>Data Sheet</Button>

                  </div>
                  <div className='col-md-4 mb-4 text-right' style={{ justifyContent: 'space-between' }}>
                    <Button className="btn btnIri" type="submit" >Exit</Button>
                  </div>
                </div>
          </div>
        </>
    );
}

export default ReportLetters;