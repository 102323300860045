import moment from 'moment';
import { referralReasonLetterData } from "./commonData";

export const reasonLetterSummary = (key, hpv) => {
    let str = null;
    let hasVal = referralReasonLetterData.find(a => a.key === key);
    if (!hasVal) {
        str = `...referred with no smear ${hpv ? 'with high risk HPV' : ''}`;
    } else {
        str = `...referred ${hasVal.value}  ${hpv ? 'with high risk HPV' : ''}`;
    }

    return str;
}

export function manipulateSentence(originalSentence, stringProp, removeString) {



    // Remove any trailing period, comma, and extra space
    let cleanedSentence = originalSentence.replace(/[.,\s]+$/, '');

    if (removeString) {
        // Remove the string and add a period at the end
        cleanedSentence = cleanedSentence.replace(new RegExp(stringProp + ',? '), '').replace(` ${stringProp}`, '');

    } else {
        // Add the string and a comma with extra space at the end
        cleanedSentence += (cleanedSentence.length > 0 ? ', ' : '') + stringProp;
    }

    // Add a period at the end
    cleanedSentence += '.';

    if (cleanedSentence.includes('no abnormality') && !removeString) {
        cleanedSentence = manipulateSentence(cleanedSentence, 'no abnormality', true);
    }

    if (cleanedSentence == '...Colposcopy examination revealed.') {
        cleanedSentence = '...Colposcopy examination revealed no abnormality.';
    }





    return cleanedSentence;
}

export function generateCode() {
    const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const generateRandomLetter = () => String.fromCharCode(getRandomInt(65, 90)); // ASCII codes for A to Z

    const generateRandomDigit = () => getRandomInt(0, 9);

    let code = '';

    // Generate 9 random digits
    for (let i = 0; i < 9; i++) {
        code += generateRandomDigit();
    }

    // Add 1 random capital letter
    code += generateRandomLetter();

    return code;
}

export function generateMotivationalPassword() {
    const motivationalWords1 = [
        "inspire", "dream", "believe", "achieve", "persevere", "succeed", "thrive", "victory", "overcome", "conquer",
        "ambition", "dedication", "optimism", "persistence", "commitment", "potential", "challenge", "focus", "resilience", "passion",
        "innovation", "empower", "imagine", "aspire", "transform", "excellence", "growth", "courage", "strength", "determination",
        "goals", "success", "hustle", "positivity", "grit", "drive", "inspiration", "belief", "uplift", "faith", "vibrant",
        "pursue", "motivate", "rise", "thrive", "vibrant", "endurance", "triumph", "vision"
    ];

    const motivationalWords2 = [
        "focus", "challenge", "strength", "passion", "courage", "resilience", "commitment", "purpose", "perseverance", "achievement",
        "dedication", "innovation", "empowerment", "optimism", "persistence", "potential", "drive", "inspiration", "belief", "uplift",
        "faith", "vibrancy", "excellence", "growth", "goals", "success", "hustle", "positivity", "grit", "transformation", "dream",
        "ambition", "determination", "perseverance", "triumph", "victory", "overcome", "conquer", "challenge", "focus", "resilience",
        "passion", "vision", "inspiration", "belief", "uplift", "thrive", "endurance", "motivation", "rise"
    ];

    const getRandomElement = (array) => array[Math.floor(Math.random() * array.length)];

    const password = getRandomElement(motivationalWords1) + getRandomElement(motivationalWords2);

    return password;
}

export function groupByField(arr, field) {
    let grped = arr.reduce((grouped, item) => {
        const value = item[field];

        if (!grouped[value]) {
            grouped[value] = {
                ...item,
                data: [],
                title: value
            }
        }

        grouped[value].data.push(item);

        return grouped;
    }, {});

    let newGrpd = []

    Object.entries(grped).map(([key, value]) => {
        newGrpd.push(value);
    })

    return newGrpd;
}


export function formatReportData({patientDetails, personalReferralDetails,  examination,  examinationDetails, referralReasons, treatmentDetails, managementDetails, patientAssociatedSymptoms}) {

let docData = {
    "TreatmentDate": moment(managementDetails.treatmentdate).format('MM/DD/YYYY'),
    "HospitalNumber": patientDetails.hospitalnumber,
    "NHSNumber":patientDetails.nhsnumber,
    "TodayDate": moment().format('MM/DD/YYYY'),
    "GPNameAndAddress": `${personalReferralDetails.gpname} ${personalReferralDetails.gpaddress}`,
    "PatientForename": patientDetails.forename,
    "PatientSurname": patientDetails.surname,
    "PatientDob": moment(patientDetails.dateofbirth).format('MM/DD/YYYY'),
    "TreatmentType":"Test Treatment Type",
    "ifTreatmentBiopsiesTaken":"true",
    "ColposcopistName":"Test Colposcopist Name",
    "ColposcopistPost":"Test Colposcopist Post",
    "ReferrerName":"Test Referrer Name",
    "ReferrerAddress":"Test Referer Address",
    "DNADate1":"12/11/2023",
    "UserName":"Test User Name",
    "UserQualification":"Test User Qualification",
    "UserPost":"Test User Post",
    "Userpost2":"Test User Post2",
    "ChartNumber":"321213",
    "TreatmentBiopsyResults":"Test Treatment Biopsy Results",
    "GPName": personalReferralDetails.gpname,
    "ExaminationDetails": "Test Examination Details",
    "ColposcopicOpinion":"Test Colposcopic Opinion",
    "PunchBiopsyResults":"Test PunchTreatmentBiopsy Results",
    "SmearResults":"Test Smear Results",
    "ClinicType":"Test Clinic Type",
    "ClinicMonths":"Test Clinic Months",
    "ReferralReasons":"Test Referral Reasons",
    "TreatmentStatus": "Test Treatment Status",
    "ComplicationsTextBox":"Test Complications TextBox",
    "SmearMonths":"'3','6','12' Smear Months 3/6/12",
    "AppointmentDate":"10/23/2023",
    "AppointmentTime":"12:45",
    "ContactName":"Test Contact Name",
    "PunchBiopsyType":"Test PunchBiopsy Type",
    "3MonthSmearResults":"Test 3 Month Smear Results",
    "6MonthSmearResults":"Test 6 Month Smear Results",
    "12MonthSmearResults":"Test 12 Month Smear Results",
    "SwabResults":"Test Swab Result",
    "TreatmentComplications":"Test Treatment Complications From Treatment Details screen",
    "TreatmentConsent":"Test Treatment consent",
    "TreatDate":"Treatment Date Short format",
    "TreatmentLenghtTime":"Test Treatment Time Displays length or 'Unknown",
    "TreatmentLesionSize":"Test Treatment Lesion Size",
    "TreatmentPain":"Test Treatment Pain",
    "TreatmentReason":"Test Treatment Method details",
    "PatientPPSNumber":"Test PPS Number as Displayed on Patient Personal Details",
    "PatientCSPID":"Test CSPID as displayed on Patient Personal details",
    "PatientPIDNumber":"Test PID Number as displayed",
    "DNANoOfTimes":"Test Displays more than on the letter if the DNA count is more than three",
    "FirstAppointmentClinic":"Test Displays the Patient's Clinic for their first appointment",
    "ifPunchBiopsiesAndSmearsTaken":"true",
    "ifPunchBiopsiesOnlyTaken":"true",
    "ifSmearsOnlyTaken":"true",
    "ifSwabsTaken":"True",
    "if6MonthSmearsTaken":"True",
    "if12MonthSmearsTaken":"True",
    "ifAnySamplesTaken":"True",
    "ifInfectionTrichomonas":"True",
    "ifInfectionCandida":"True",
    "ifInfectionChlamydia":"True",
    "ifInfectionBacterialVaginosis":"True",
    "ifClinicMonthsSet":"True",
    "ifClinicMonthsNotSet":"True",
    "ifDNANewAppointment":"True",
    "ifDNAFollow-Up":"True",
    "ifCompleteExcision":"True",
    "ifIncompleteEcto":"True",
    "ifIncompleteEndo":"True",
    "ifHPVPositive":"True",
    "ifHPVNegative":"True",
    "ifAnaestheticGeneral":"True",
    "ifAnaestheticLocal":"True",
    "ifClinicColposcopy":"true",
    "ifClinicNurseSmear":"true",
    "ifInfectionAny":"true",
    "ifInfectionNotCandida":"true",
    "ifInfectionNone":"true",
    "ifInfectionOther":"true",
    "ifPunchBiopsiesTaken":"true",
    "ifPunchBiopsiesNotTaken":"true",
    "ifSmearsTaken":"true",
    "ifTreatmentRequired":"true",
    "ifSmearAttendDate":"true",
    "ifColpAttendDate":"true",
    "ifCervixTreatmentNonAttendDate":"true",
    "ifCervixSmearNonAttendDate":"true",
    "ifDNADates":"true",
    "ifDNA":"true",
    "ifClinicNursesSmearNotDNA":"true",
    "ifClinicColposcopyNotDNA":"true",
    "ifNOTDNA":"true",
    "if2ConsecuitiveDNA's":"true",
    "FirstAppointmentName":"Test First Appointment Name",
    "ExamOrFollowUpDateAsHeading":"Test Exam Or Follow Up As Heading",
    "ExaminationsOrFollowUpDate":"Test Examinations Or Follow Up Date",
    "PatientAge": moment().diff(patientDetails.dateofbirth, 'years'),
    "PatientChartNumber":"123123",
    "PatientOccupation":"Test Patient Occupation",
    "PatientReferredBy":"Test Patient Referred By",
    "ifReReferralAdditionalDetails":"true",
    "AbnormalBleeding":"Test Abnormal Bleeding",
    "VaginalDischarge":"Test Vaginal Discharge",
    "LMP":"Test Lmp",
    "Cycle":"Test Cycle",
    "Contraception":"Test Contraception",
    "Parity+":"Test Parity+",
    "RiskFactor":"Test Risk Factor",
    "GynaecologicalCondition":"Test Gynaecological Condition",
    "GynaecologicalSurgery":"Test Gynaecological Surgery",
    "FreeTextAdditionalDetails":"Test Free Text Additional Details",
    "NonGynaecologicalInformation":"Test NonGynaecological Information",
    "ifNotContinue":"true",
    "ifContinue":"true",
    "Cervix":"Test Cervix",
    "CervicalLesion":"Test Cervical Lesion",
    "VaginalLesion":"Test Vaginal",
    "GynaecologicalExamination":"Test Gynaecological Examination",
    "SCJ":"Test SCJ",
    "SpecificLesion":"Test Specific Lesion",
    "BiopsiesTaken":"Test Biopssies Taken",
    "SmearsTaken":"Test Smears Taken",
    "SwabTaken":"Test Swab Taken",
    "ColposcopicOpinionCervix":"Test ColposcopicOpinion Cervix",
    "ColposcopicOpinionVagina":"Test ColposcopicOpinion Vagina",
    "ManagementPlan": managementDetails.managementplan,
    "GPAddress1": personalReferralDetails.gpaddress,
    "GPAddress2":"Test GPAddress 2",
    "GPAddress3":"Test GPAddress 3",
    "GPAddress4":"Test GPAddress 4",
    "PatientName": `${patientDetails.forename} ${patientDetails.surname}`
}

return docData 
}

export function baseToFormData(base64, filename) {

   return fetch(base64)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], filename);
      return file
    });
    
    }

