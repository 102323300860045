import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepWizard from "react-step-wizard";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { OPEN_MODAL, SET_EDITED, SET_PATIENT_ASSOCIATED_SYMPTOMS, SET_PEROSNAL_RELEVANT_DETAILS, SET_PERSONAL_REFERRAL_HISTOLOGY, SET_REFERRAL_REASONS } from "../../../redux/actions/types";
import { getPersonalReferralDetailsById } from "../../../redux/actions/data.action";
import CustomModal from "../../../component/Modals/CustomModal";
import { reasonLetterSummary } from '../../../Utils/helpers';
import { NonNeoplasticGrp, stdData, NeoplasticLeftGrp, NeoplasticRightGrp } from '../../../Utils/commonData';
import { FormGroup, Input, Label } from "reactstrap";


const CARD_TITLES = [
    '',
    `Reasons for Patient's Referral`,
    `Patient's Associated Symptoms`,
    `Patient's Relevant History`,
    'Referral Histology'

]


const One = (props) => {
    const { viewOption, edited, modal } = useSelector(({ ui }) => ui)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { referralReasons } = useSelector(({ data }) => data);


    const handleSubmit = (event) => {
        event.preventDefault();

        console.log('handle sumbit 2')
        if (referralReasons.referralhistologytaken) {
            props.nextStep();
        } else {
            props.goToStep(3)
        }

        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();


        // } else {
        //     setError("");
        //     props.nextStep();
        //     props.userCallback(info2);
        //     event.preventDefault();
        //     event.stopPropagation();

        // }
    };


    const handleChangeReasonsPatientReferral = prop => event => {
        if (viewOption === 'review') {
            return console.log('REVIEW MODE')
        }
        let formDetails = localStorage.getItem('formDetails')
        let val = event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value


        let newVal = { ...referralReasons, [prop]: val }

        if (prop === 'referralindicationcervix' && val !== 'Colp/Treatment elsewhere') {
            newVal = { ...newVal, referralhistologytaken: false }
        }



        if (prop === 'referralindicationcervix' && val !== referralReasons.referralindicationcervix) {
            newVal = { ...newVal, referralsmearcervix: 'No Smear' }
        }

        if (prop === 'referralindicationcervix' && val !== 'Other') {
            newVal = { ...newVal, referralindicationothercervix: '' }
        }

        if (prop === 'referralindicationcervix' && val !== 'Suspicious symptoms other') {
            newVal = { ...newVal, suspicioussymptomsother: '' }
        }

        let isHpvDetected = newVal.referralsmearhpv === true;







        if (prop !== 'occurenceofprevioussmears' && prop !== 'referralsmeardatecervix' && prop !== 'referralreasonlettersummarycervix') {


            if (newVal.referralsmearcervix === 'Negative (Normal)' && isHpvDetected) {
                newVal = { ...newVal, referralindicationcervix: 'Other', referralindicationothercervix: `negative cytology ${isHpvDetected ? 'with high risk HPV' : ''}`, referralreasonlettersummarycervix: reasonLetterSummary('Negative (Normal)', isHpvDetected) }
            } else if (referralReasons.referralsmearcervix === 'Negative (Normal)' && referralReasons.referralsmearhpv) {
                newVal = { ...newVal, referralindicationcervix: 'Abnormal smear de novo', referralindicationothercervix: '', referralreasonlettersummarycervix: '...referred with no smear' }
            } else {
                if (prop != 'referralsmearhpv') {
                    if (prop === 'referralhistologytaken') {
                        newVal = { ...newVal, referralreasonlettersummarycervix: reasonLetterSummary('histology', isHpvDetected) }
                    } else {
                        newVal = { ...newVal, referralreasonlettersummarycervix: reasonLetterSummary(val, isHpvDetected) }
                    }
                } else {
                    newVal = { ...newVal, referralreasonlettersummarycervix: val === true ? newVal.referralreasonlettersummarycervix + 'with high risk HPV' : newVal.referralreasonlettersummarycervix.replace('with high risk HPV', '') }
                }
            }

        }



        dispatch({ type: SET_REFERRAL_REASONS, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails), reasons: newVal }))
        if (((prop === 'referralindicationcervix' && referralReasons.referralindicationcervix !== val) || (prop === 'referralsmearcervix' && val === 'No Smear')) && referralReasons.referralsmearhpv === true) {

            dispatch({ type: OPEN_MODAL, payload: 'hpvDetected' });
            return;
        }

        dispatch({ type: SET_EDITED, payload: 'personal' })


    }

    const handleBack = () => {
        navigate(`${viewOption === 'enter' ? '/dashboard/patient/enterPatient' : '/dashboard/viewPatient/enterPatient'}`)
    };





    const handleExit = (e) => {
        e.preventDefault();
        if (edited.find(a => a === 'personal')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        // setExamDetails({})
        // dispatch({type: CLEAR_EXAMINATION})
        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }


    return (
        <>
            <CustomModal
                title="HPV Detected"
                open={modal === 'hpvDetected'}
                onOkay={() => {
                    let formDetails = localStorage.getItem('formDetails')
                    let newVal = { ...referralReasons, referralsmearhpv: false, referralsmearcervix: "No Smear", referralreasonlettersummarycervix: reasonLetterSummary(referralReasons.referralindicationcervix, false) }

                    if (referralReasons.referralindicationothercervix === 'negative cytology with high risk HPV') {
                        newVal = { ...newVal, referralindicationothercervix: '', referralindicationcervix: 'Abnormal smear de novo' }
                    }
                    dispatch({ type: SET_REFERRAL_REASONS, payload: newVal })
                    localStorage.setItem('formDetails', JSON.stringify({ ...JSON.parse(formDetails), reasons: newVal }))
                    dispatch({ type: OPEN_MODAL });
                    dispatch({ type: SET_EDITED, payload: 'personal' })
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <i className="ti-alert text-warning fa-2x mr-3"></i>
                    HPV Detected will be removed</div>
            </CustomModal>

            <Form noValidate onSubmit={handleSubmit}
                onReset={handleExit}
            >




                <Row className="bordered ml-2 mr-2">
                    <h3 className="card-title text-center bordered-title">
                        Referral Indication
                    </h3>
                    <div className='clearfix'></div>
                    <div className="col-md-6">
                        <div className='row'>
                            <div className='col-md-12 pl-4'>
                                <div className="custom-radio text-left">
                                    <input type="radio" id="Abnormal smear de novo"
                                        value="Abnormal smear de novo" checked={referralReasons.referralindicationcervix === 'Abnormal smear de novo'} name="referralindication_radio" className="" onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="" htmlFor="Abnormal smear de novo">Abnormal smear de novo</label>
                                    <div className='clearfix'></div>
                                    <input type="radio" id="Abnormal screening cytology" value="Abnormal screening cytology" checked={referralReasons.referralindicationcervix === 'Abnormal screening cytology'} name="referralindication_radio" className="" onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c" htmlFor="Abnormal screening cytology">Abnormal screening cytology</label>
                                    <div className='clearfix'></div>

                                    <input type="radio" id="Abnormal smear after colposcopy" value="Abnormal smear after colposcopy" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Abnormal smear after colposcopy'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c" htmlFor="Abnormal smear after colposcopy">Abnormal smear after colposcopy</label>
                                    <div className='clearfix'></div>


                                    <input type="radio" id="PCB (age>35 ) persist > 4 Weeks" value="PCB (age>35 ) persist > 4 Weeks" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'PCB (age>35 ) persist > 4 Weeks'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c text-danger" htmlFor="PCB (age>35 ) persist > 4 Weeks">PCB (age>35 ) persist > 4 Weeks</label>
                                    <div className='clearfix'></div>
                                    <input type="radio" id="Clinically Suspicious cervix" value="Clinically Suspicious cervix" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Clinically Suspicious cervix'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c text-danger" htmlFor="Clinically Suspicious cervix">Clinically Suspicious cervix</label>
                                    <div className='clearfix'></div>
                                    <div className='row'>
                                        <div className="col-md-8  ">
                                            <input type="radio" id="Colp/Treatment elsewhere" value="Colp/Treatment elsewhere" checked={referralReasons.referralindicationcervix === 'Colp/Treatment elsewhere'} name="referralindication_radio" className="" onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                            <label className="c" htmlFor="Colp/Treatment elsewhere">Colp/Treatment elsewhere</label>
                                        </div>
                                        <div className="col-md-4 text-right pr-5 mt-2">
                                            {referralReasons.referralindicationcervix === 'Colp/Treatment elsewhere' && <>
                                                <input type="checkbox" id="histology" value={referralReasons.referralhistologytaken ? false : true} checked={referralReasons.referralhistologytaken} name="referralhistology_radio" className="" onChange={handleChangeReasonsPatientReferral('referralhistologytaken')} />
                                                <label className="ml-2 mb-2" htmlFor="histology">Histology</label>
                                            </>}
                                        </div>
                                    </div>
                                    <div className='clearfix'></div>
                                    <div className='row mt-1'>
                                        <div className='col-md-2'>
                                            <input type="radio"
                                                id="Other"
                                                value="Other"
                                                className=""
                                                name="referralindication_radio"
                                                checked={referralReasons.referralindicationcervix === 'Other'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')}
                                            />
                                            <label className="" htmlFor="Other">Other</label>
                                        </div>
                                        <div className='col-md-5 text-left'>
                                            <input disabled={referralReasons.referralindicationcervix !== 'Other'} type="text" id="referralindicationothercervix" value={referralReasons.referralindicationothercervix} onChange={handleChangeReasonsPatientReferral('referralindicationothercervix')} className="form-control mb-3" placeholder="" style={{ marginBottom: 20 }} />
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* //end of 6 */}
                    <br />
                    <div className="col-md-6 mt-3">
                        <div className="bordered">

                            <h3 className="card-title bordered-title text-15" >
                                Non-Urgent (Suspicious Symptoms)
                            </h3>
                            <div className="row pl-3 pr-2">
                                <div className="col-md-6 text-left pl-2 mb-2">
                                    <input type="radio" id="PCB" value="PCB" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'PCB'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="" htmlFor="PCB">PCB</label>
                                    <div className='clearfix'></div>
                                    <input type="radio" id="Contact bleeding" value="Contact bleeding" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Contact bleeding'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="" htmlFor="Contact bleeding">Contact bleeding</label>
                                    <div className='clearfix'></div>
                                    <div className="row">
                                        <div className='col-md-4 '>
                                            <input type="radio" id="Suspicious symptoms other" value="Suspicious symptoms other" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Suspicious symptoms other'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                            <label className="" htmlFor="Suspicious symptoms other">Other</label>
                                        </div>
                                        <div className='col-md-5 text-left'>
                                            <input disabled={referralReasons.referralindicationcervix !== 'Suspicious symptoms other'} type="text" id="suspicioussymptomsother" value={referralReasons.suspicioussymptomsother} onChange={handleChangeReasonsPatientReferral('suspicioussymptomsother')} className="form-control mb-3" placeholder="" />
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-left pl-2">
                                    <input type="radio" id="Inflammation" value="Inflammation" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Inflammation'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c" htmlFor="Inflammation">Inflammation</label>
                                    <div className='clearfix'></div>
                                    <input type="radio" id="Polyps" value="Polyps" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'Polyps'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c" htmlFor="Polyps">Polyps</label>
                                    <div className='clearfix'></div>
                                    <input type="radio" id="IMB" value="IMB" name="referralindication_radio" className="" checked={referralReasons.referralindicationcervix === 'IMB'} onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                                    <label className="c" htmlFor="IMB">IMB</label>
                                    <div className='clearfix'></div>

                                    <br />

                                </div>
                                <div className="clr"></div>

                            </div>
                        </div>
                        <div className="clr"></div>
                        <br />
                        <div className="col-md-12 text-left pl-2" >
                            <input type="radio" id="Vaginal changes - No abnormal cytology" value="Vaginal changes - No abnormal cytology" checked={referralReasons.referralindicationcervix === 'Vaginal changes - No abnormal cytology'} name="referralindication_radio" className="" onChange={handleChangeReasonsPatientReferral('referralindicationcervix')} />
                            <label className="text-12" htmlFor="Vaginal changes - No abnormal cytology">Vaginal changes - No abnormal cytology</label>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    {/* //end of 6 */}

                </Row>
                <br />
                {/* END OF REFERER INDICATION */}
                <Row className="bordered ml-2 mr-2">
                    <h3 className="card-title text-center bordered-title">
                        Referral Smear
                    </h3>
                    <div className="clr"></div>
                    <div className="row pl-4">
                        <div className="col-md-3 custom-radio text-left">
                            <input type="radio" id="Borderline Endo Cervical"
                                value="Borderline Endo Cervical" checked={referralReasons.referralsmearcervix === 'Borderline Endo Cervical'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="" htmlFor="Borderline Endo Cervical">Borderline Endo Cervical</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Borderline Squamous" value="Borderline Squamous" checked={referralReasons.referralsmearcervix === 'Borderline Squamous'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Borderline Squamous">Borderline Squamous</label>
                            <div className='clearfix'></div>

                            <input type="radio" id="Glandular Neoplasia" value="Glandular Neoplasia" name="smear_radio" className="" checked={referralReasons.referralsmearcervix === 'Glandular Neoplasia'} onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Glandular Neoplasia">Glandular Neoplasia</label>
                            <div className='clearfix'></div>

                            <input type="radio" id="Borderline Nuc. Abn." value="Borderline Nuc. Abn." name="smear_radio" className="" checked={referralReasons.referralsmearcervix === 'Borderline Nuc. Abn.'} onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Borderline Nuc. Abn.">Borderline Nuc. Abn.</label>
                            <div className='clearfix'></div>

                            {/*  
                      <input type="radio" id="Smear Colp/Treatment elsewhere" value="Colp/Treatment elsewhere" checked={referralReasons.referralsmearcervix === 'Colp/Treatment elsewhere'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                      <label className="c" htmlFor="Smear Colp/Treatment elsewhere">Colp/Treatment elsewhere</label>
                      <div className='clearfix'></div> */}

                        </div>
                        <div className="col-md-3 custom-radio text-left">
                            <input type="radio" id="Borderline Change"
                                value="Borderline Change" checked={referralReasons.referralsmearcervix === 'Borderline Change'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="" htmlFor="Borderline Change">Borderline Change</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Borderline ?High Grade" value="Borderline ?High Grade" checked={referralReasons.referralsmearcervix === 'Borderline ?High Grade'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Borderline ?High Grade">Borderline ?High Grade</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Inadequate Specimen" value="Inadequate Specimen" checked={referralReasons.referralsmearcervix === 'Inadequate Specimen'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Inadequate Specimen">Inadequate Specimen</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Abnormal Unclassifiable" value="Abnormal Unclassifiable" name="smear_radio" className="" checked={referralReasons.referralsmearcervix === 'Abnormal Unclassifiable'} onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Abnormal Unclassifiable">Abnormal Unclassifiable</label>
                            <div className='clearfix'></div>

                            {/*  
                      <input type="radio" id="Smear Colp/Treatment elsewhere" value="Colp/Treatment elsewhere" checked={referralReasons.referralsmearcervix === 'Colp/Treatment elsewhere'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                      <label className="c" htmlFor="Smear Colp/Treatment elsewhere">Colp/Treatment elsewhere</label>
                      <div className='clearfix'></div> */}

                        </div>
                        <div className="col-md-3 custom-radio text-left">
                            <input type="radio" id="Mild Dyskaryosis"
                                value="Mild Dyskaryosis" checked={referralReasons.referralsmearcervix === 'Mild Dyskaryosis'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="" htmlFor="Mild Dyskaryosis">Mild Dyskaryosis</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Moderate Dyskaryosis" value="Moderate Dyskaryosis" checked={referralReasons.referralsmearcervix === 'Moderate Dyskaryosis'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Moderate Dyskaryosis">Moderate Dyskaryosis</label>
                            <div className='clearfix'></div>

                            <input type="radio" id="Severe Dyskaryosis" value="Severe Dyskaryosis" name="smear_radio" className="" checked={referralReasons.referralsmearcervix === 'Severe Dyskaryosis'} onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Severe Dyskaryosis">Severe Dyskaryosis</label>
                            <div className='clearfix'></div>
                            <input type="radio" id="Possible Invasion"
                                value="Possible Invasion" checked={referralReasons.referralsmearcervix === 'Possible Invasion'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="" htmlFor="Possible Invasion">Possible Invasion</label>
                            <div className='clearfix'></div>
                        </div>
                        <div className="col-md-3 custom-radio text-left">

                            <input type="radio" id="Unsatisfactory" value="Unsatisfactory" checked={referralReasons.referralsmearcervix === 'Unsatisfactory'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Unsatisfactory">Unsatisfactory</label>
                            <div className='clearfix'></div>

                            <input type="radio" id="Negative (Normal)" value="Negative (Normal)" name="smear_radio" className="" checked={referralReasons.referralsmearcervix === 'Negative (Normal)'} onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="Negative (Normal)">Negative (Normal)</label>
                            <div className='clearfix'></div>


                            <input type="radio" id="No Smear" value="No Smear" checked={referralReasons.referralsmearcervix === 'No Smear'} name="smear_radio" className="" onChange={handleChangeReasonsPatientReferral('referralsmearcervix')} />
                            <label className="c" htmlFor="No Smear">No Smear</label>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="bordered pl-3 pr-2">
                                    <h3 className="card-title txtLeft bordered-title">
                                        Occurence of Previous Smear
                                    </h3>
                                    <div className="row text-left pl-2 pr-2 mt-3 mb-3">
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Check
                                                    className="col-md-1"
                                                    type="radio"
                                                    placeholder=""
                                                    id="Unknown" value="Unknown" checked={referralReasons.occurenceofprevioussmears === 'Unknown'} name="occurence_smear_radio"
                                                    onChange={handleChangeReasonsPatientReferral('occurenceofprevioussmears')}
                                                />
                                                <Form.Label className="col-md-11 text-left" htmlFor="Unknown">
                                                    Unknown
                                                </Form.Label>
                                                <div className="clr"></div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group>
                                                <Form.Check
                                                    className="col-md-1"
                                                    type="radio"
                                                    placeholder=""
                                                    id="None" value="None" checked={referralReasons.occurenceofprevioussmears === 'None'} name="occurence_smear_radio"
                                                    onChange={handleChangeReasonsPatientReferral('occurenceofprevioussmears')}
                                                />
                                                <Form.Label className="col-md-11 text-left" htmlFor="None">
                                                    None
                                                </Form.Label>
                                                <div className="clr"></div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group  >
                                                <Form.Check
                                                    className="col-md-1"
                                                    type="radio"
                                                    placeholder=""
                                                    id="Infrequent" value="Infrequent" checked={referralReasons.occurenceofprevioussmears === 'Infrequent'} name="occurence_smear_radio"
                                                    onChange={handleChangeReasonsPatientReferral('occurenceofprevioussmears')}
                                                />
                                                <Form.Label className="col-md-11 text-left" htmlFor="Infrequent">
                                                    Infrequent
                                                </Form.Label>
                                                <div className="clr"></div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group  >
                                                <Form.Check
                                                    className="col-md-1 fL"
                                                    type="radio"
                                                    placeholder=""
                                                    id="Regular" value="Regular" checked={referralReasons.occurenceofprevioussmears === 'Regular'} name="occurence_smear_radio"
                                                    onChange={handleChangeReasonsPatientReferral('occurenceofprevioussmears')}
                                                />
                                                <Form.Label className="col-md-11 fL text-left" htmlFor="Regular">
                                                    Regular
                                                </Form.Label>
                                                <div className="clr"></div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="bordered pl-3 pr-2">
                                    <h3 className="card-title bordered-title">
                                        Smear Date
                                    </h3>
                                    <input
                                        className="form-control mb-2    "
                                        type="date"
                                        placeholder=""
                                        value={referralReasons.referralsmeardatecervix ? moment(referralReasons.referralsmeardatecervix).format('YYYY-MM-DD') : null}
                                        onChange={handleChangeReasonsPatientReferral('referralsmeardatecervix')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 text-left pl-5 mt-3">
                                <input type="radio" id="referralsmearhpv" value={!Boolean(referralReasons.referralsmearhpv == true || referralReasons.referralsmearhpv == 'true')} checked={Boolean(referralReasons.referralsmearhpv == true || referralReasons.referralsmearhpv == 'true')} name="referralsmearhpv_radio" className="" onClick={handleChangeReasonsPatientReferral('referralsmearhpv')} />
                                <label className="c" htmlFor="referralsmearhpv">HPV Detected</label>
                            </div>
                        </div>

                    </div>
                    <div className="clr"></div>

                </Row>
                {/* END OF RERERER SMEAR */}

                <br />
                <Row className="row pl-3 pr-3">
                    <div className="bordered">
                        <h3 className="card-title text-center bordered-title text-13">
                            Referral Reasons - Summary for Letters
                        </h3>
                        <Form.Group className="row pr-2 pl-2 mb-3" >
                            <input className="form-control"
                                value={referralReasons.referralreasonlettersummarycervix}
                                onChange={handleChangeReasonsPatientReferral('referralreasonlettersummarycervix')}
                            />
                        </Form.Group>
                    </div>
                </Row>
                <br />
                <Row className="row pl-3 pr-3">
                    <div className=" bordered">
                        <h3 className="card-title text-center bordered-title">
                            Referral History
                        </h3>
                        <Form.Group className="row pr-2 pl-2 mb-3">
                            <input className="form-control"
                                value={".........."}
                            />

                        </Form.Group>
                    </div>
                </Row>
                {/* //  Referral History */}
                <br />
                <br />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                    <Button className="btn btnIri marRight20" onClick={handleBack}>Previous</Button>
                    <Button type="submit" className="btn btnIri marRight20">Next</Button>
                    <Button className="btn btnIri marRight20" type="reset">Exit</Button>
                </div>
            </Form>
        </>
    );
};

const Two = (props) => {
    const { personalReferralHistology: referralHistology } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const dispatch = useDispatch()
    const navigate = useNavigate();



    const handleChanges = prop => event => {
        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};
        let newVal = { ...referralHistology, [prop]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value }


        dispatch({ type: SET_PERSONAL_REFERRAL_HISTOLOGY, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, referralHistology: newVal }))
        dispatch({ type: SET_EDITED, payload: 'personal' })


    }


    const handleSubmit = (event) => {
        console.log('handleSubmit 3')
        event.preventDefault();
        props.nextStep();

    };

    const handleBack = () => {
        props.previousStep();
    };

    const handleCancel = (e) => {
        e.preventDefault();
        if (edited.find(a => a === 'personal')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        // setExamDetails({})
        // dispatch({type: CLEAR_EXAMINATION})
        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }






    return (
        <>
            <Form noValidate onSubmit={handleSubmit} onReset={handleCancel}>
                <Row className="" style={{ justifyContent: 'space-around', alignItems: 'space-between' }}>
                    <div className="col-md-6 text-center p-2 " >
                        <div className="text-center bordered pb-3">
                            <h3 className="card-title text-center bordered-title">
                                Neoplastic
                            </h3>
                            <div className="clr"></div>
                            <div className="row  pl-5">
                                <div className='col-md-6  pl-5 text-left'>
                                    {NeoplasticLeftGrp.map((row, index) => {

                                        return (
                                            <div key={index}>
                                                <Input type="checkbox" name={`neo-${row.title}`} id={`neo-${row.title}`} className="pl-2"
                                                    value={!referralHistology[row.field]}
                                                    checked={referralHistology[row.field]}
                                                    onChange={handleChanges(row.field)}
                                                />
                                                {' '}
                                                <Label htmlFor={`neo-${row.title}`} className="pl-2">
                                                    {row.title}
                                                </Label>
                                                <div className="clr"></div>

                                            </div>

                                        )
                                    })}
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <div className="mr-5">
                                            <Input type="checkbox" name={`neo-1a1`} id="1a1" className="pl-2"
                                                value={!referralHistology.referralhistology1a1}
                                                checked={referralHistology.referralhistology1a1}
                                                onChange={handleChanges('referralhistology1a1')}
                                            />
                                            {' '}
                                            <Label htmlFor="1a1" className="pl-2">
                                                1a1
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name={`neo-1a2`} id="1a2" className="pl-2"
                                                value={!referralHistology.referralhistology1a2}
                                                checked={referralHistology.referralhistology1a2}
                                                onChange={handleChanges('referralhistology1a2')}
                                            />
                                            {' '}
                                            <Label htmlFor="1a2" className="pl-2">
                                                1a2
                                            </Label>
                                            <div className="clr"></div>
                                        </div>
                                        <div className="text-center  mt-3">
                                            <Input type="checkbox" name={`neo-1a`} id="1a" className=""
                                                value={!referralHistology.referralhistology1a}
                                                checked={referralHistology.referralhistology1a}
                                                onChange={handleChanges('referralhistology1a')}
                                            />
                                            {' '}
                                            <Label htmlFor="1a" className="pl-2">
                                            >1a
                                            </Label>
                                            <div className="clr"></div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-md-6  pl-5 text-left'>
                                    {NeoplasticRightGrp.map((row, index) => {

                                        return (
                                            <div key={index}>
                                                <Input type="checkbox" name={`neoright-${row.field}`} id={`neoright-${row.field}`} className="pl-2"
                                                    value={!referralHistology[row.field]}
                                                    checked={referralHistology[row.field]}
                                                    onChange={handleChanges(row.field)}
                                                />
                                                {' '}
                                                <Label htmlFor={`neoright-${row.field}`} className="pl-2">
                                                    {row.title}
                                                </Label>
                                                <div className="clr"></div>

                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            {/*            <div className="row pl-5">
                                <div className="pl-5 text-left col-md-2">
                                    <Input type="checkbox" name={`nonneo-1a1`} id="1a1" className="pl-2"
                                        value={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? false : true}
                                        checked={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? true : false}
                                        onChange={handleChanges('postcoitalbleeding')}
                                    />
                                    {' '}
                                    <Label htmlFor="1a1" className="pl-2">
                                        1a1
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="checkbox" name={`nonneo-1a2`} id="1a2" className="pl-2"
                                        value={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? false : true}
                                        checked={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? true : false}
                                        onChange={handleChanges('postcoitalbleeding')}
                                    />
                                    {' '}
                                    <Label htmlFor="1a2" className="pl-2">
                                        1a2
                                    </Label>
                                    <div className="clr"></div>
                                </div>
                                <div className="text-left col-md-2 mt-3">

                                    <Input type="checkbox" name={`nonneo-1a`} id="1a" className="pl-2"
                                        value={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? false : true}
                                        checked={(referralHistology.postcoitalbleeding == 'true' || referralHistology.postcoitalbleeding === true) ? true : false}
                                        onChange={handleChanges('postcoitalbleeding')}
                                    />
                                    {' '}
                                    <Label htmlFor="1a" className="pl-2">
                                        >1a
                                    </Label>
                                    <div className="clr"></div>
                                </div>
                            </div> */}
                            <div className="row pl-5">
                                <div className="pl-5 text-left">
                                    <div className="text-left">
                                        <Input type="checkbox" name={`neo-other`} id="neo-other" className="pl-2"
                                            value={!referralHistology.referralhistologyneoplasticother}
                                            checked={referralHistology.referralhistologyneoplasticother}
                                            onChange={handleChanges('referralhistologyneoplasticother')}
                                        />
                                        {' '}
                                        <Label htmlFor="neo-other" className="pl-2">
                                            Other
                                        </Label>
                                        <div className="clr"></div>
                                    </div>
                                    <textarea type="text" id="abnormalbleedingdetails" className="form-control text-left w-75 mt-2" placeholder=""
                                        value={referralHistology.referralhistologyneoplasticothertext}
                                        onChange={handleChanges('referralhistologyneoplasticothertext')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 text-center p-2" >
                        <div className="text-center bordered pb-3">
                            <h3 className="card-title text-center bordered-title">
                                Non-Neoplastic
                            </h3>
                            <div className="clr"></div>

                            <div className='pl-5 ml-5 text-left'>
                                {NonNeoplasticGrp.map((row, index) => {

                                    return (
                                        <div key={index}>
                                            <Input type="checkbox" name={`nonneo-${row.title}`} id={`nonneo-${row.title}`} className="pl-2"
                                                value={!referralHistology[row.field]}
                                                checked={referralHistology[row.field]}
                                                onChange={handleChanges(row.field)}
                                            />
                                            {' '}
                                            <Label htmlFor={`nonneo-${row.title}`} className="pl-2">
                                                {row.title}
                                            </Label>
                                            <div className="clr"></div>

                                        </div>

                                    )
                                })}


                            </div>
                            <div className="pl-5 ml-5 text-left">
                                <div className="text-left">
                                    <Input type="checkbox" name={`nonneo-other`} id="nonneo-other" className="pl-2"
                                        value={!referralHistology.referralhistologynonneoplasticother}
                                        checked={referralHistology.referralhistologynonneoplasticother}
                                        onChange={handleChanges('referralhistologynonneoplasticother')}
                                    />
                                    {' '}
                                    <Label htmlFor="nonneo-other" className="pl-2">
                                        Other
                                    </Label>
                                    <div className="clr"></div>
                                </div>
                                <textarea type="text" id="referralhistologynonneoplasticothertext" className="form-control text-left w-75 mt-2" placeholder=""
                                    value={referralHistology.referralhistologynonneoplasticothertext}
                                    onChange={handleChanges('referralhistologynonneoplasticothertext')}
                                />
                            </div>
                        </div>
                    </div>

                </Row>
                <br />
                <div className="clr"></div>
                {/* //ACTIONS BUTTONS */}
                <div className="text-xs-center">
                    <Button type="reset" className="btn btnIri marRight20 fR" onClick={handleCancel}>Exit</Button>
                    <Button type="submit" className="btn btnIri marRight20 fR">Next</Button>
                    <Button className="btn btnIri marRight20 fR" onClick={handleBack}>Previous</Button>
                </div>
                {/* //ACTIONS BUTTONS */}
            </Form>
        </>
    );
};

const Three = (props) => {
    const { patientAssociatedSymptoms: associatedSymptoms, referralReasons } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const dispatch = useDispatch()
    const navigate = useNavigate();


    const handleChanges = prop => event => {
        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};
        let newVal = { ...associatedSymptoms, [prop]: event.target.value }

        if (prop === 'menstrualstate') {
            if (event.target.value === 'Cycling') {
                newVal = { ...newVal, menstrualloss: "Average", menstrualcycle: "Regular" }
            } else {
                newVal = { ...newVal, menstrualloss: "None", menstrualcycle: "None" }
            }
        }


        dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, associatedSymptoms: newVal }))
        dispatch({ type: SET_EDITED, payload: 'personal' })


    }


    const handleSubmit = (event) => {
        console.log('handleSubmit 3')
        props.nextStep();
        event.preventDefault();

    };

    const handleBack = () => {
        if (referralReasons.referralhistologytaken) {
            props.previousStep();
        } else {
            props.firstStep();
        }

    };

    const handleCancel = (e) => {
        e.preventDefault();
        if (edited.find(a => a === 'personal')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        // setExamDetails({})
        // dispatch({type: CLEAR_EXAMINATION})
        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }





    return (
        <>
            <Form noValidate onSubmit={handleSubmit} onReset={handleCancel}>
                <Row className="" style={{ justifyContent: 'space-around', alignItems: 'space-between' }}>
                    <div className="col-md-4 text-center p-2" >
                        <div className="text-center bordered">
                            <h3 className="card-title text-center bordered-title">
                                Abnormal Bleeding
                            </h3>
                            <div className="clr"></div>

                            <FormGroup className='pl-5 ml-5 text-left'>
                                <Input type="checkbox" name="abnormal-bleeding" id="post-costal" className="pl-2"
                                    value={(associatedSymptoms.postcoitalbleeding == 'true' || associatedSymptoms.postcoitalbleeding === true) ? false : true}
                                    checked={(associatedSymptoms.postcoitalbleeding == 'true' || associatedSymptoms.postcoitalbleeding === true) ? true : false}
                                    onChange={handleChanges('postcoitalbleeding')}
                                />
                                {' '}
                                <Label htmlFor='post-costal' className="pl-2">
                                    Post Coital
                                </Label>
                                <div className="clr"></div>
                                <Input type="checkbox" name="abnormal-bleeding" id="inter-menstrual"
                                    value={(associatedSymptoms.intermenstrualbleeding == 'true' || associatedSymptoms.intermenstrualbleeding === true) ? false : true}
                                    checked={(associatedSymptoms.intermenstrualbleeding == 'true' || associatedSymptoms.intermenstrualbleeding === true) ? true : false}
                                    onChange={handleChanges('intermenstrualbleeding')}
                                />


                                {' '}
                                <Label htmlFor='inter-menstrual' className="pl-2">
                                    Inter Menstrual
                                </Label>
                                <div className="clr"></div>
                                <Input type="checkbox" name="abnormal-bleeding" id="irregular"
                                    value={(associatedSymptoms.irregularbleeding == 'true' || associatedSymptoms.irregularbleeding === true) ? false : true}
                                    checked={(associatedSymptoms.irregularbleeding == 'true' || associatedSymptoms.irregularbleeding === true) ? true : false}
                                    onChange={handleChanges('irregularbleeding')}
                                />
                                {' '}
                                <Label htmlFor='irregular' className="pl-2">
                                    Irregular
                                </Label>
                                <div className="clr"></div>
                                <Input type="checkbox" name="abnormal-bleeding" id="post-menopausal"
                                    value={(associatedSymptoms.postmenopausalbleeding == 'true' || associatedSymptoms.postmenopausalbleeding === true) ? false : true}
                                    checked={(associatedSymptoms.postmenopausalbleeding == 'true' || associatedSymptoms.postmenopausalbleeding === true) ? true : false}
                                    onChange={handleChanges('postmenopausalbleeding')}

                                />
                                {' '}
                                <Label htmlFor='post-menopausal' className="pl-2">
                                    Post Menopausal
                                </Label>

                            </FormGroup>
                            <div className="form-group w-100 text-center pr-3">
                                <Label className="control-label text-right w-25 pr-3">Details</Label>
                                <Input type="text" id="abnormalbleedingdetails" className="form-control text-left w-75" placeholder=""
                                    value={associatedSymptoms.abnormalbleedingdetails}
                                    onChange={handleChanges('abnormalbleedingdetails')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-center p-2">
                        <div className="text-center  bordered ">
                            <h3 className="card-title txtLeft bordered-title">
                                Vaginal Discharge
                            </h3>
                            <FormGroup className='pl-5 ml-5 text-left'>
                                <Input type="radio" name="vaginal-discharge" id="present" className="pl-2" checked={associatedSymptoms.vaginaldischarge === 'Present'} value="Present" onChange={handleChanges('vaginaldischarge')} />
                                {' '}
                                <Label htmlFor='present' className="pl-2">
                                    Present
                                </Label>
                                <div className="clr"></div>
                                <Input type="radio" name="vaginal-discharge" id="managed-by-gu" value="Managed by G.U." checked={associatedSymptoms.vaginaldischarge === 'Managed by G.U.'} onChange={handleChanges('vaginaldischarge')} />
                                {' '}
                                <Label htmlFor='managed-by-gu' className="pl-2">
                                    Managed by G.U.
                                </Label>
                                <div className="clr"></div>
                                <Input type="radio" name="vaginal-discharge" id="absent" value="Absent" onChange={handleChanges('vaginaldischarge')}
                                    checked={associatedSymptoms.vaginaldischarge === 'Absent'}
                                />
                                {' '}
                                <Label htmlFor='absent' className="pl-2">
                                    Absent
                                </Label>
                                <div className="clr"></div>
                                <Input type="radio" name="vaginal-discharge" id="recurring" value="Recurring" onChange={handleChanges('vaginaldischarge')}
                                    checked={associatedSymptoms.vaginaldischarge === 'Recurring'}
                                />
                                {' '}
                                <Label htmlFor='recurring' className="pl-2">
                                    Recurring
                                </Label>

                            </FormGroup>
                            <div className="form-group w-100 text-center pr-3">
                                <Label className="control-label text-right w-25 pr-3">Details</Label>
                                <Input type="text" id="vaginaldischargedetails" className="form-control text-left w-75" placeholder=""
                                    value={associatedSymptoms.vaginaldischargedetails}
                                    onChange={handleChanges('vaginaldischargedetails')}
                                />
                            </div>
                        </div>
                        <div className="clr"></div>
                    </div>
                    <div className="col-md-3 text-center align-self-start p-2">
                        <div className="text-center bordered ">
                            <h3 className="card-title txtLeft bordered-title">
                                Pruritus Vulva
                            </h3>
                            <div className="clr"></div>



                            <FormGroup className='pl-5 ml-5 text-left'>
                                <Input type="checkbox" name="pruritus-vulva" id="vulva-present" className="pl-2"
                                    value={(associatedSymptoms.pruritusvulvapresent == 'true' || associatedSymptoms.pruritusvulvapresent === true) ? false : true}
                                    checked={(associatedSymptoms.pruritusvulvapresent == 'true' || associatedSymptoms.pruritusvulvapresent === true) ? true : false}
                                    onChange={handleChanges('pruritusvulvapresent')}
                                />
                                {' '}
                                <Label htmlFor='vulva-present' className="pl-2">
                                    Present
                                </Label>
                                <div className="clr"></div>


                            </FormGroup>
                            <br />
                        </div>
                        <div className="clr"></div>
                    </div>

                </Row>
                {/* //END OF PATIENT ASSOCIATED SYMPTOPMS */}
                <div className="text-center mt-3" style={{ justifyContent: 'space-around', alignItems: 'space-between' }}>
                    <div className="bordered text-left m-sm-3">
                        <h3 className="card-title text-center bordered-title">
                            Menstrual Details
                        </h3>

                        <div className="ml-3 mr-3" style={{ justifyContent: 'center', alignItems: 'center' }} >
                            <div className="row bordered text-center pl-3"  >
                                <div className="col-md-4  text-left " >
                                    <input type="radio" id="Cycling"
                                        name="menstrualstate_radio" required=""
                                        value="Cycling"
                                        onChange={handleChanges('menstrualstate')}
                                        checked={associatedSymptoms.menstrualstate === 'Cycling'}
                                    />
                                    <label className="mt-2" htmlFor="Cycling">Cycling</label>
                                </div>
                                <div className="col-md-4 text-left " >
                                    <input type="radio" id="Pregnant" value="Pregnant"
                                        name="menstrualstate_radio" required=""
                                        onChange={handleChanges('menstrualstate')}
                                        checked={associatedSymptoms.menstrualstate === 'Pregnant'}
                                    />
                                    <label className="mt-2" htmlFor="Pregnant">Pregnant</label>
                                    <div className='clearfix'></div>
                                </div>
                                <div className="col-md-4 text-left " >
                                    <input type="radio" id="Post-Menopausal" value="Post-Menopausal"
                                        name="menstrualstate_radio" required=""
                                        onChange={handleChanges('menstrualstate')}
                                        checked={associatedSymptoms.menstrualstate === 'Post-Menopausal'}
                                    />
                                    <label className="mt-2" htmlFor="Post-Menopausal">Post-Menopausal</label>
                                    <div className='clearfix'></div>
                                </div>
                            </div>
                        </div>
                        <div className="clr"></div>

                        <Row className="m-2" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div className="col-md-4 text-left bordered pl-3 pr-5 mt-3">
                                <h3 className="card-title text-left bordered-title">
                                    Cycle
                                </h3>
                                <div className="clr"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="radio"
                                            id="menstrualcycleRegular"
                                            value="Regular"
                                            name="menstrualcycle_radio" className=""
                                            onChange={handleChanges('menstrualcycle')}
                                            checked={associatedSymptoms.menstrualcycle === 'Regular'}
                                        />
                                        <label className="" htmlFor="menstrualcycleRegular">Regular</label>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <input type="radio" id="Amenorrhoea" name="menstrualcycle_radio" className=""
                                            value="Amenorrhoea"
                                            onChange={handleChanges('menstrualcycle')}
                                            checked={associatedSymptoms.menstrualcycle === 'Amenorrhoea'}
                                        />
                                        <label className="c" htmlFor="Amenorrhoea">Amenorrhoea</label>
                                    </div>
                                </div>
                                <input type="radio" id="menstrualcycleIrregular"
                                    name="menstrualcycle_radio" className=""
                                    value="Irregular"
                                    onChange={handleChanges('menstrualcycle')}
                                    checked={associatedSymptoms.menstrualcycle === 'Irregular'}
                                />
                                <label className="c" htmlFor="menstrualcycleIrregular">Irregular</label>
                                <div className='clearfix'></div>
                                <input type="radio"
                                    id="menstrualcycleNone"
                                    value="None"
                                    onChange={handleChanges('menstrualcycle')}
                                    name="menstrualcycle_radio" required="" className=""
                                    checked={associatedSymptoms.menstrualcycle === 'None'}
                                />
                                <label className="c" htmlFor="menstrualcycleNone">None</label>
                                <br />
                                <div className="text-left" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}  >
                                    <label className="col-md-6 text-right mr-3" >Length (days)</label>
                                    <input type="number" id="menstrualcyclelength" className="form-control col-md-3" placeholder="" required
                                        value={associatedSymptoms.menstrualcyclelength}
                                        onChange={handleChanges('menstrualcyclelength')}
                                    />
                                </div>
                                <div className='clearfix'></div>
                                <br />
                            </div>
                            <div className="col-md-4 text-left bordered pl-3 pr-5 mt-3">
                                <h3 className="card-title text-left bordered-title">
                                    Loss
                                </h3>
                                <div className="clr"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="radio" id="Scanty"
                                            value="Scanty"
                                            name="menstrualloss_radio" className=""
                                            onChange={handleChanges('menstrualloss')}
                                            checked={associatedSymptoms.menstrualloss === 'Scanty'}
                                        />
                                        <label className="" htmlFor="Scanty">Scanty</label>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <input type="radio" id="No periods" value="No periods" name="menstrualloss_radio" className=""
                                            onChange={handleChanges('menstrualloss')}
                                            checked={associatedSymptoms.menstrualloss === 'No periods'}
                                        />
                                        <label className="c" htmlFor="No periods">No periods</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="radio" id="Average"
                                            value="Average"
                                            name="menstrualloss_radio" className=""
                                            onChange={handleChanges('menstrualloss')}
                                            checked={associatedSymptoms.menstrualloss === 'Average'}
                                        />
                                        <label className="" htmlFor="Average">Average</label>
                                    </div>
                                    <div className="col-md-6 text-left">
                                        <input type="radio" id="menstruallossNone" value="None" name="menstrualloss_radio" className=""
                                            onChange={handleChanges('menstrualloss')}
                                            checked={associatedSymptoms.menstrualloss === 'None'}
                                        />
                                        <label className="c" htmlFor="menstruallossNone">None</label>
                                    </div>
                                </div>
                                <input type="radio" id="Heavy" value="Heavy" name="menstrualloss_radio" className=""
                                    onChange={handleChanges('menstrualloss')}
                                    checked={associatedSymptoms.menstrualloss === 'Heavy'}
                                />
                                <label className="c" htmlFor="Heavy">Heavy</label>
                                <br />
                                <div className="text-left" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}  >
                                    <label className="col-md-6 text-right mr-3" >Duration (days)</label>
                                    <input type="number" id="menstruallossduration" className="form-control col-md-3" placeholder=""
                                        value={associatedSymptoms.menstruallossduration}
                                        onChange={handleChanges('menstruallossduration')}
                                    />
                                </div>
                                <div className='clearfix'></div>
                                <br />
                            </div>


                            <div className="col-md-3  text-center bordered pl-3 pr-3 mt-3">
                                <h3 className="card-title bordered-title text-center">
                                    Date of LMP
                                </h3>
                                <div className="clr"></div>
                                <input type="date" id="dateoflmp" className="form-control text-center" placeholder=""
                                    value={associatedSymptoms.dateoflmp ? moment(associatedSymptoms.dateoflmp).format('YYYY-MM-DD') : null}
                                    onChange={handleChanges('dateoflmp')}
                                />
                                <br />                            <br />

                            </div>
                            <div className="clr"></div>
                        </Row>
                        <br />
                        <div className="clr"></div>
                        {/* END OF ROW */}
                        <div className=" ml-3 mr-3" style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                            <div className="row bordered ">
                                <h3 className="card-title bordered-title text-center">
                                    Pregnancy Test
                                </h3>

                                <div className="row text-center pl-5 mb-3"  >
                                    <div className="col-md-4  text-left" >
                                        <input type="radio" id="Positive" value="Positive"
                                            name="pregnancytest_radio"
                                            onChange={handleChanges('pregnancytest')}
                                            checked={associatedSymptoms.pregnancytest === 'Positive'}
                                        />
                                        <label className="" htmlFor="Positive">Positive</label>
                                    </div>
                                    <div className="col-md-4 text-left " >
                                        <input type="radio" id="Negative" value="Negative"
                                            name="pregnancytest_radio"
                                            onChange={handleChanges('pregnancytest')}
                                            checked={associatedSymptoms.pregnancytest === 'Negative'}
                                        />
                                        <label className="" htmlFor="Negative">Negative</label>
                                        <div className='clearfix'></div>
                                    </div>
                                    <div className="col-md-4 text-left " >
                                        <input type="radio" id="notapplic" value=""
                                            onChange={handleChanges('pregnancytest')}
                                            name="pregnancytest_radio"
                                            checked={associatedSymptoms.pregnancytest === ''}
                                        />
                                        <label className="" htmlFor="notapplic">N/A</label>
                                        <div className='clearfix'></div>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <br />
                        </div>
                        <br />
                    </div>
                    <br />
                </div>

                {/* END OF ROW */}
                <br />
                <div className="clr"></div>
                {/* //ACTIONS BUTTONS */}
                <div className="text-xs-center">
                    <Button type="reset" className="btn btnIri marRight20 fR" onClick={handleCancel}>Exit</Button>
                    <Button type="submit" className="btn btnIri marRight20 fR">Next</Button>
                    <Button className="btn btnIri marRight20 fR" onClick={handleBack}>Previous</Button>
                </div>
                {/* //ACTIONS BUTTONS */}
            </Form>
        </>
    );
};

const Four = (props) => {
    const dispatch = useDispatch();
    const { relevantDetails } = useSelector(({ data }) => data);
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        props.nextStep();
    };

    const handleBack = () => {
        props.previousStep();
    };


    const handleChanges = prop => event => {
        // contraception
        let formDetails = localStorage.getItem('formDetails');
        let frm = formDetails ? JSON.parse(formDetails) : {};
        let newVal = { ...relevantDetails, [prop]: event.target.value }

        if (prop === 'hpvvaccinestatus') {

            newVal = { ...newVal, [prop]: relevantDetails[prop] == event.target.value ? null : event.target.value }

        }

        dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: newVal })
        localStorage.setItem('formDetails', JSON.stringify({ ...frm, relevantDetails: newVal }))
        dispatch({ type: SET_EDITED, payload: 'personal' })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        if (edited.find(a => a === 'personal')) {
            dispatch({ type: OPEN_MODAL, payload: 'save_details' })
        }
        // setExamDetails({})
        // dispatch({type: CLEAR_EXAMINATION})
        navigate(`${viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient'}`)
    }



    return (
        <>
            <Form noValidate onSubmit={handleSubmit} onReset={handleCancel}>
                <Row >
                    <div className="col-md-6">
                        <div className="bordered text-center">
                            <h3 className="card-title text-center bordered-title">
                                Contraception
                            </h3>
                            <div className="clr"></div>
                            <div className="row pl-5">
                                <FormGroup className='col-md-4 text-left'>
                                    <Input type="radio" name="contraception_radio" id="Implanone" className="pl-2"
                                        value="Implanone" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Implanone'}
                                    />
                                    {' '}
                                    <Label htmlFor='Implanone' className="pl-2">
                                        Implanone
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Vasectomy" value="Vasectomy" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Vasectomy'}
                                    />
                                    {' '}
                                    <Label htmlFor='Vasectomy' className="pl-2">
                                        Vasectomy
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Depo" value="Depo" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Depo'}
                                    />
                                    {' '}
                                    <Label htmlFor='Depo' className="pl-2">
                                        Depo
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="P.O.P" value="P.O.P" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'P.O.P'}
                                    />
                                    {' '}
                                    <Label htmlFor='P.O.P' className="pl-2">
                                        P.O.P
                                    </Label>

                                </FormGroup>
                                <FormGroup className='col-md-4 text-left'>
                                    <Input type="radio" name="contraception_radio" id="I.U.C.D" className="pl-2"
                                        value="I.U.C.D" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'I.U.C.D'}
                                    />
                                    {' '}
                                    <Label htmlFor='I.U.C.D' className="pl-2">
                                        I.U.C.D
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="None" value="None"
                                        onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'None'}
                                    />
                                    {' '}
                                    <Label htmlFor='None' className="pl-2">
                                        None
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Barrier" value="Barrier" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Barrier'}
                                    />
                                    {' '}
                                    <Label htmlFor='Barrier' className="pl-2">
                                        Barrier
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Natural" value="Natural" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Natural'}
                                    />
                                    {' '}
                                    <Label htmlFor='Natural' className="pl-2">
                                        Natural
                                    </Label>

                                </FormGroup>
                                <FormGroup className='col-md-4 text-left'>
                                    <Input type="radio" name="contraception_radio" id="IUS" className="pl-2"
                                        value="IUS" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'IUS'}
                                    />
                                    {' '}
                                    <Label htmlFor='IUS' className="pl-2">
                                        IUS
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="TL" value="TL"
                                        onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'TL'}
                                    />
                                    {' '}
                                    <Label htmlFor='TL' className="pl-2">
                                        TL
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Sterilisation" value="Sterilisation" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Sterilisation'}
                                    />
                                    {' '}
                                    <Label htmlFor='Sterilisation' className="pl-2">
                                        Sterilisation
                                    </Label>
                                    <div className="clr"></div>
                                    <Input type="radio" name="contraception_radio" id="Combined O.C" value="Combined O.C" onChange={handleChanges('contraception')}
                                        checked={relevantDetails.contraception === 'Combined O.C'}
                                    />
                                    {' '}
                                    <Label htmlFor='Combined O.C' className="pl-2">
                                        Combined O.C
                                    </Label>

                                </FormGroup>
                            </div>
                            <div className="clr"></div>
                        </div>
                        <br />

                        <div className="bordered">
                            <h3 className="card-title txtLeft bordered-title">
                                Obsteric History
                            </h3>
                            <div className="row pl-3 pr-3">
                                <FormGroup className="col-md-6">
                                    <Label className="col-md-8 control-label text-left">
                                        Ectopic Pregnancy
                                    </Label>
                                    <Input type="number" id="ectopicpregnancy" className="form-control col-md-4" placeholder=""
                                        value={relevantDetails.ectopicpregnancy}
                                        onChange={handleChanges('ectopicpregnancy')}
                                    />
                                    <div className="clr"></div>   <br />
                                    <Label className="col-md-8 control-label text-left">
                                        Live Births
                                    </Label>
                                    <Input type="number" id="livebirths" className="form-control col-md-4" placeholder=""
                                        value={relevantDetails.livebirths}
                                        onChange={handleChanges('livebirths')}
                                    />
                                    <div className="clr"></div>   <br />
                                    <Label className="col-md-8 control-label text-left">
                                        Terminations
                                    </Label>
                                    <Input type="number" id="terminations" className="form-control col-md-4" placeholder=""
                                        value={relevantDetails.terminations}
                                        onChange={handleChanges('terminations')}
                                    />
                                </FormGroup>
                                <FormGroup className="col-md-6">
                                    <Label className="col-md-8 control-label text-left">
                                        Still Births
                                    </Label>
                                    <Input type="number" id="stillbirths" className="form-control col-md-4" placeholder=""
                                        value={relevantDetails.stillbirths}
                                        onChange={handleChanges('stillbirths')}
                                    />
                                    <div className="clr"></div>   <br />
                                    <Label className="col-md-8 control-label text-left">
                                        Miscarriages
                                    </Label>
                                    <Input type="number" id="miscarriages" className="form-control col-md-4" placeholder=""
                                        value={relevantDetails.miscarriages}
                                        onChange={handleChanges('miscarriages')}
                                    />
                                </FormGroup>



                                <div className="clr"></div>
                                <br />
                            </div>
                        </div>
                        <br />
                        <div className="bordered">
                            <h3 className="card-title text-center bordered-title">
                                STI'S
                            </h3>
                            <FormGroup className="p-2">
                                <Input
                                    type="select"
                                    id="ethnic-origin" className="form-control" placeholder=""
                                    value={String(relevantDetails.std).trim()}
                                    onChange={handleChanges('std')}
                                >
                                    <option value="" style={{ fontSize: 13 }}></option>
                                    {stdData
                                        .map(a => {
                                            return (
                                                <option value={a} style={{ fontSize: 13 }}>{a}</option>
                                            )
                                        })}
                                </Input>
                            </FormGroup>
                            <div className="clr"></div>
                        </div>
                        <br />
                        <div className="bordered">
                            <h3 className="card-title text-center bordered-title">
                                Gynaecological
                            </h3>
                            <FormGroup className="text-left pl-2 pr-2" >
                                <label className="control-label text-danger">Condition</label>
                                <textarea name="textarea" id="textarea" className="form-control" placeholder="" aria-invalid="false"
                                    value={relevantDetails.gynaecologicalcondition}
                                    onChange={handleChanges('gynaecologicalcondition')}
                                />
                                <div className="clr"></div>
                                <label className="control-label text-danger">Treatment</label>
                                <textarea name="textarea" id="textarea" className="form-control" placeholder="" aria-invalid="false"
                                    value={relevantDetails.gynaecologicalsurgery}
                                    onChange={handleChanges('gynaecologicalsurgery')}
                                />
                            </FormGroup>
                        </div>
                        <br />
                        <div className="bordered">
                            <h3 className="card-title txtLeft bordered-title">
                                HPV Vaccine Status
                            </h3>
                            <div className="clr"></div>
                            <FormGroup className='row text-left pl-5'>
                                <div className="col-md-4">
                                    <Input type="checkbox" name="hpvvaccinestatus" id="hpvvaccinestatus_yes" className="pl-2"
                                        value="Yes"
                                        checked={relevantDetails.hpvvaccinestatus === 'Yes' ? true : false}
                                        onChange={handleChanges('hpvvaccinestatus')}
                                    />
                                    {' '}
                                    <Label htmlFor='hpvvaccinestatus_yes' className="pl-2">
                                        Yes
                                    </Label>
                                </div>
                                <div className="col-md-4">
                                    <Input type="checkbox" name="hpvvaccinestatus" id="hpvvaccinestatus_no" className="pl-2"
                                        value="No"
                                        checked={relevantDetails.hpvvaccinestatus === 'No' ? true : false}
                                        onChange={handleChanges('hpvvaccinestatus')}
                                    />
                                    {' '}
                                    <Label htmlFor='hpvvaccinestatus_no' className="pl-2">
                                        No
                                    </Label>
                                </div>
                                <div className="col-md-4">
                                    <Input type="checkbox" name="hpvvaccinestatus" id="hpvvaccinestatus_not_sure" className="pl-2"
                                        value="Not Sure"
                                        checked={relevantDetails.hpvvaccinestatus === 'Not Sure' ? true : false}
                                        onChange={handleChanges('hpvvaccinestatus')}
                                    />
                                    {' '}
                                    <Label htmlFor='hpvvaccinestatus_not_sure' className="pl-2">
                                        Not Sure
                                    </Label>
                                </div>
                                <div className="clr"></div>
                            </FormGroup>
                        </div>
                        <br />
                    </div>
                    <br />
                    {/* ================================ */}
                    <div className="col-md-6">
                        <div className="bordered pl-2 pr-2">
                            <h3 className="card-title text-center bordered-title">
                                Non-Gynaecological
                            </h3>
                            <div className="clr"></div>
                            <FormGroup className="col-md-12">
                                <Label className="col-md-4 control-label text-left">
                                    Current Medication
                                </Label>
                                <Input type="text" id="currentmedication" className="form-control col-md-8" placeholder=""
                                    value={relevantDetails.currentmedication}
                                    onChange={handleChanges('currentmedication')}
                                />
                                <div className="clr m-2"></div>
                                <div className="row text-left pl-5">
                                    <div className="col-md-6">
                                        <Input type="checkbox" name="diabetes" id="diabetes" className="pl-2"
                                            value={(relevantDetails.diabetes == 'true' || relevantDetails.diabetes === true) ? false : true}
                                            checked={(relevantDetails.diabetes == 'true' || relevantDetails.diabetes === true) ? true : false}
                                            onChange={handleChanges('diabetes')}
                                        />
                                        {' '}
                                        <Label htmlFor='diabetes' className="pl-2">
                                            Diabetes
                                        </Label>
                                    </div>
                                    <div className="col-md-6">
                                        <Input type="checkbox" name="cardiovascular" id="cardiovascular" className="pl-2"
                                            value={(relevantDetails.cardiovascular == 'true' || relevantDetails.cardiovascular === true) ? false : true}
                                            checked={(relevantDetails.cardiovascular == 'true' || relevantDetails.cardiovascular === true) ? true : false}
                                            onChange={handleChanges('cardiovascular')}
                                        />
                                        {' '}
                                        <Label htmlFor='cardiovascular' className="pl-2">
                                            Cardio-Vascular
                                        </Label>
                                    </div>
                                </div>
                                <div className="clr m-2"></div>
                                <Label className="col-md-4 control-label text-left">
                                    Medical
                                </Label>
                                <Input type="text" id="medicaldetails" className="form-control col-md-8" placeholder=""
                                    value={relevantDetails.medicaldetails}
                                    onChange={handleChanges('medicaldetails')}
                                />
                                <div className="clr m-2"></div>


                            </FormGroup>
                            <div className="clr"></div>
                            <div className="bordered ">
                                <h3 className="card-title bordered-title">
                                    Smoking / Cigarettes per day
                                </h3>
                                <div className="clr"></div>
                                <FormGroup className="row pl-3 pr-2">
                                    <div className="col-md-3">
                                        <Input type="radio" name="smoker_radio" id="Current" value="Current" onChange={handleChanges('smoker')}
                                            checked={relevantDetails.smoker === 'Current'}
                                        />
                                        {' '}
                                        <Label htmlFor='Current' className="pl-2">
                                            Current
                                        </Label>
                                    </div>
                                    <div className="col-md-3">
                                        <Input type="radio" name="smoker_radio" id="Previous" value="Previous" onChange={handleChanges('smoker')}
                                            checked={relevantDetails.smoker === 'Previous'}
                                        />
                                        {' '}
                                        <Label htmlFor='Previous' className="pl-2">
                                            Previous
                                        </Label>
                                    </div>
                                    <div className="col-md-3">
                                        <Input type="radio" name="smoker_radio" id="Never" value="Never" onChange={handleChanges('smoker')}
                                            checked={relevantDetails.smoker === 'Never'}
                                        />
                                        {' '}
                                        <Label htmlFor='Never' className="pl-2">
                                            Never
                                        </Label>
                                    </div>
                                    <div className="col-md-3">
                                        <Input type="radio" name="smoker_radio" id="Socially" value="Socially" onChange={handleChanges('smoker')}
                                            checked={relevantDetails.smoker === 'Socially'}
                                        />
                                        {' '}
                                        <Label htmlFor='Socially' className="pl-2">
                                            Socially
                                        </Label>
                                    </div>
                                    <div className="clr"></div>
                                    <div className="row text-left mt-3">
                                        <div className="col-md-4">
                                            <Label className="col-md-6 control-label">
                                                Perday
                                            </Label>
                                            <Input type="number" id="smokingcigarettesperday" className="form-control col-md-6" placeholder=""
                                                value={relevantDetails.smokingcigarettesperday}
                                                onChange={handleChanges('smokingcigarettesperday')}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <Label className="col-md-6 control-label">
                                                Date Last Smoked
                                            </Label>
                                            <Input type="date" id="smokedate" className="form-control col-md-6" placeholder=""
                                                value={relevantDetails.smokedate ? moment(relevantDetails.smokedate).format('YYYY-MM-DD') : ''}
                                                onChange={handleChanges('smokedate')}
                                            />
                                        </div>
                                    </div>
                                </FormGroup>

                            </div>
                            <br />
                            <div className="bordered">
                                <h3 className="card-title bordered-title">
                                    Allergy
                                </h3>
                                <div className="clr"></div>
                                <div className="row pl-5">

                                    <FormGroup className="row">
                                        <div className='col-md-4 text-left'>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergymetronidazole"
                                                value={(relevantDetails.allergymetronidazole == 'true' || relevantDetails.allergymetronidazole === true) ? false : true}
                                                checked={(relevantDetails.allergymetronidazole == 'true' || relevantDetails.allergymetronidazole === true) ? true : false}
                                                onChange={handleChanges('allergymetronidazole')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergymetronidazole' className="pl-2">
                                                Metronidazole
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergytetracyclines"
                                                value={(relevantDetails.allergytetracyclines == 'true' || relevantDetails.allergytetracyclines === true) ? false : true}
                                                checked={(relevantDetails.allergytetracyclines == 'true' || relevantDetails.allergytetracyclines === true) ? true : false}
                                                onChange={handleChanges('allergytetracyclines')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergytetracyclines' className="pl-2">
                                                Tetracyclines
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergycephalosporins"
                                                value={(relevantDetails.allergycephalosporins == 'true' || relevantDetails.allergycephalosporins === true) ? false : true}
                                                checked={(relevantDetails.allergycephalosporins == 'true' || relevantDetails.allergycephalosporins === true) ? true : false}
                                                onChange={handleChanges('allergycephalosporins')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergycephalosporins' className="pl-2">
                                                Cephalosporins
                                            </Label>
                                        </div>

                                        <div className='col-md-4 text-left'>

                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergypeanuts"
                                                value={(relevantDetails.allergypeanuts == 'true' || relevantDetails.allergypeanuts === true) ? false : true}
                                                checked={(relevantDetails.allergypeanuts == 'true' || relevantDetails.allergypeanuts === true) ? true : false}
                                                onChange={handleChanges('allergypeanuts')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergypeanuts' className="pl-2">
                                                Peanuts
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergymicrolides"
                                                value={(relevantDetails.allergymicrolides == 'true' || relevantDetails.allergymicrolides === true) ? false : true}
                                                checked={(relevantDetails.allergymicrolides == 'true' || relevantDetails.allergymicrolides === true) ? true : false}
                                                onChange={handleChanges('allergymicrolides')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergymicrolides' className="pl-2">
                                                Macrolides
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergyligocaine"
                                                value={(relevantDetails.allergyligocaine == 'true' || relevantDetails.allergyligocaine === true) ? false : true}
                                                checked={(relevantDetails.allergyligocaine == 'true' || relevantDetails.allergyligocaine === true) ? true : false}
                                                onChange={handleChanges('allergyligocaine')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergyligocaine' className="pl-2">
                                                Ligocaine
                                            </Label>
                                        </div>

                                        <div className='col-md-4 text-left'>

                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergylatex"
                                                value={(relevantDetails.allergylatex == 'true' || relevantDetails.allergylatex === true) ? false : true}
                                                checked={(relevantDetails.allergylatex == 'true' || relevantDetails.allergylatex === true) ? true : false}
                                                onChange={handleChanges('allergylatex')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergylatex' className="pl-2">
                                                Latex
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergypenicillin"
                                                value={(relevantDetails.allergypenicillin == 'true' || relevantDetails.allergypenicillin === true) ? false : true}
                                                checked={(relevantDetails.allergypenicillin == 'true' || relevantDetails.allergypenicillin === true) ? true : false}
                                                onChange={handleChanges('allergypenicillin')}
                                            />

                                            {' '}
                                            <Label htmlFor='allergypenicillin' className="pl-2">
                                                Penicillin
                                            </Label>
                                            <div className="clr"></div>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergyiodine"
                                                value={(relevantDetails.allergyiodine == 'true' || relevantDetails.allergyiodine === true) ? false : true}
                                                checked={(relevantDetails.allergyiodine == 'true' || relevantDetails.allergyiodine === true) ? true : false}
                                                onChange={handleChanges('allergyiodine')}
                                            />

                                            {' '}
                                            <Label htmlFor='allergyiodine' className="pl-2">
                                                Iodine
                                            </Label>
                                        </div>
                                        <div className='col-md-4 text-left'>
                                            <Input type="checkbox" name="allergy-checkbox"
                                                id="allergyelastoplast"
                                                value={(relevantDetails.allergyelastoplast == 'true' || relevantDetails.allergyelastoplast === true) ? false : true}
                                                checked={(relevantDetails.allergyelastoplast == 'true' || relevantDetails.allergyelastoplast === true) ? true : false}
                                                onChange={handleChanges('allergyelastoplast')}
                                            />
                                            {' '}
                                            <Label htmlFor='allergyelastoplast' className="pl-2">
                                                Elastoplast
                                            </Label>
                                        </div>
                                        <div className='row col-md-8 text-left'>
                                            <div className="col-md-3">
                                                <Input type="checkbox" name="allergy-checkbox"
                                                    id="allergyother"
                                                    value={(relevantDetails.allergyother == 'true' || relevantDetails.allergyother === true) ? false : true}
                                                    checked={(relevantDetails.allergyother == 'true' || relevantDetails.allergyother === true) ? true : false}
                                                    onChange={handleChanges('allergyother')}
                                                />
                                                {' '}
                                                <Label htmlFor='allergyother' className="pl-2">
                                                    Other
                                                </Label> </div>

                                            <Input type="text" disabled={!(relevantDetails.allergyother == 'true' || relevantDetails.allergyother === true)} id="allergyothertext" className="form-control col-md-9 text-left" placeholder=""
                                                value={relevantDetails.allergyothertext}
                                                onChange={handleChanges('allergyothertext')}
                                            />
                                        </div>

                                    </FormGroup>
                                </div>
                            </div>
                            <br />
                            <div className="bordered ">
                                <h3 className="card-title text-center bordered-title">
                                    HIV
                                </h3>
                                <div className="pl-5 pb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <div className="text-left">
                                        <Input type="radio" name="hiv_radio" id="hiv_yes" value="Yes" onChange={handleChanges('hiv')}
                                            checked={relevantDetails.hiv === 'Yes'}
                                        />
                                        {' '}
                                        <Label htmlFor='hiv_yes' className="pl-2">
                                            Yes
                                        </Label>
                                    </div>
                                    <div className="text-left">
                                        <Input type="radio" name="hiv_radio" id="hiv_no" value="No" onChange={handleChanges('hiv')}
                                            checked={relevantDetails.hiv === 'No'}
                                        />
                                        {' '}
                                        <Label htmlFor='hiv_no' className="pl-2">
                                            No
                                        </Label>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div className="bordered">
                                <h3 className="card-title txtLeft bordered-title">
                                    Immune-Suppression
                                </h3>
                                <FormGroup className="row pl-5 text-left">
                                    <div className="col-md-6">
                                        <Input type="checkbox" name="immune-suppression-checkbox"
                                            id="immunesuppressionhiv"
                                            value={(relevantDetails.immunesuppressionhiv == 'true' || relevantDetails.immunesuppressionhiv === true) ? false : true}
                                            checked={(relevantDetails.immunesuppressionhiv == 'true' || relevantDetails.immunesuppressionhiv === true) ? true : false}
                                            onChange={handleChanges('immunesuppressionhiv')}
                                        />
                                        {' '}
                                        <Label htmlFor='immunesuppressionhiv' className="pl-2">
                                            HIV
                                        </Label>
                                        <div className="clr"></div>
                                        <Input type="checkbox" name="immune-suppression-checkbox"
                                            id="immunesuppressiontransplant"
                                            value={(relevantDetails.immunesuppressiontransplant == 'true' || relevantDetails.immunesuppressiontransplant === true) ? false : true}
                                            checked={(relevantDetails.immunesuppressiontransplant == 'true' || relevantDetails.immunesuppressiontransplant === true) ? true : false}
                                            onChange={handleChanges('immunesuppressiontransplant')}
                                        />
                                        {' '}
                                        <Label htmlFor='immunesuppressiontransplant' className="pl-2">
                                            Transplant
                                        </Label>
                                    </div>
                                    <div className="col-md-6">
                                        <Input type="checkbox" name="immune-suppression-checkbox"
                                            id="immunesuppressionautoimmunedisease"
                                            value={(relevantDetails.immunesuppressionautoimmunedisease == 'true' || relevantDetails.immunesuppressionautoimmunedisease === true) ? false : true}
                                            checked={(relevantDetails.immunesuppressionautoimmunedisease == 'true' || relevantDetails.immunesuppressionautoimmunedisease === true) ? true : false}
                                            onChange={handleChanges('immunesuppressionautoimmunedisease')}
                                        />
                                        {' '}
                                        <Label htmlFor='immunesuppressionautoimmunedisease' className="pl-2">
                                            Auto-immune disease
                                        </Label>
                                        <div className="clr"></div>
                                        <div className="row pr-3">
                                            <div className="col-md-4">
                                                <Input type="checkbox" name="immune-suppression-checkbox"
                                                    id="immunesuppressionother"
                                                    value={(relevantDetails.immunesuppressionother == 'true' || relevantDetails.immunesuppressionother === true) ? false : true}
                                                    checked={(relevantDetails.immunesuppressionother == 'true' || relevantDetails.immunesuppressionother === true) ? true : false}
                                                    onChange={handleChanges('immunesuppressionother')}
                                                />
                                                {' '}
                                                <Label htmlFor='immunesuppressionother' className="pl-2">
                                                    Other
                                                </Label> </div>
                                            <div className="col-md-6">
                                                <Input type="text" disabled={!(relevantDetails.immunesuppressionother == 'true' || relevantDetails.immunesuppressionother === true)} id="immunesuppressionothertext" className="form-control text-left" placeholder=""
                                                    value={relevantDetails.immunesuppressionothertext}
                                                    onChange={handleChanges('immunesuppressionothertext')}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </FormGroup>
                            </div>
                            <br />
                        </div>
                        <div className="bordered mt-3">
                            <h3 className="card-title txtLeft bordered-title">
                                Additional Information
                            </h3>
                            <div className="clr"></div>

                            <FormGroup className="text-left pl-2 pr-2">
                                <textarea name="additionalinformation" id="additionalinformation" className="form-control" placeholder="" aria-invalid="false"
                                    value={relevantDetails.additionalinformation}
                                    onChange={handleChanges('additionalinformation')}
                                />
                            </FormGroup>
                            <div className="clr"></div>
                        </div>
                        <br />

                    </div>
                    {/* //end of column 6 */}
                </Row>

                {/* //ACTIONS BUTTONS */}
                <div className="text-xs-center">
                    <Button type="reset" className="btn btnIri marRight20 fR">Exit</Button>
                    <Button type="submit" className="btn btnIri marRight20 fR" disabled>Next</Button>
                    <Button className="btn btnIri marRight20  fR" onClick={handleBack}>Previous</Button>

                </div>
                {/* //ACTIONS BUTTONS */}
            </Form>
        </>
    );

};




const ReferralDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { patientDetails, personalReferralDetails } = useSelector(({ data }) => data)
    const [stepWizard, setStepWizard] = useState(null);

    const [activeStep, setActiveStep] = useState(1);
    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep);
    };

    const handleComplete = (props) => {
        // alert("You r done. TQ");

        // let timerInterval
        // Swal.fire({
        //     title: 'Auto close alert! Success',
        //     html: 'I will close in <b></b> milliseconds.',
        //     icon: 'success',
        //     timer: 2000,
        //     timerProgressBar: true,
        //     didOpen: () => {
        //         Swal.showLoading()
        //         const b = Swal.getHtmlContainer().querySelector('b')
        //         timerInterval = setInterval(() => {
        //         b.textContent = Swal.getTimerLeft()
        //         }, 100)
        //     },
        //     willClose: () => {
        //         clearInterval(timerInterval)
        //     }
        // }).then((result) => {
        // /* Read more about handling dismissals below */
        //     if (result.dismiss === Swal.DismissReason.timer) {
        //         navigate('/login');
        //         navigate("/dashboard/patient/referralDetails");
        //     }
        // })

    };


    let handleReferralDetails = (id) => {
        dispatch(getPersonalReferralDetailsById(id))
            .then((res) => {
                console.log(res.data, 'ref')
                let { tbl_personalreferralreason, tbl_personalassociatedsymptom, tbl_personalrelevantdetail, tbl_personalreferralhistology } = res.data;
                dispatch({ type: SET_REFERRAL_REASONS, payload: tbl_personalreferralreason })
                dispatch({ type: SET_PATIENT_ASSOCIATED_SYMPTOMS, payload: tbl_personalassociatedsymptom })
                dispatch({ type: SET_PEROSNAL_RELEVANT_DETAILS, payload: tbl_personalrelevantdetail })
                dispatch({ type: SET_PERSONAL_REFERRAL_HISTOLOGY, payload: tbl_personalreferralhistology })

            })
            .catch(err => {
                console.log(err)
            })
    }


    // useEffect(() => {
    //     if (referralReasons) {
    //         setRefReasons(referralReasons)
    //     }

    //     if (patientAssociatedSymptoms) {
    //         setAssociatedSymptoms(patientAssociatedSymptoms)
    //     }


    //     if (relevantDetails) {
    //         setRefRelevantDetails(relevantDetails)
    //     }



    // }, [referralReasons, patientAssociatedSymptoms, relevantDetails])




    useEffect(() => {


        if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
            //   setPatient(patientDetails)
            if (personalReferralDetails && personalReferralDetails.referralid) {
                handleReferralDetails(personalReferralDetails.referralid)
            }
        } else {
            navigate('/dashboard/home')
        }
    }, [patientDetails.systemid])





    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">
                    {CARD_TITLES[activeStep]}
                </h4>
            </div>
            <div className="card-body">

                <StepWizard
                    instance={assignStepWizard}
                    onStepChange={handleStepChange}
                >
                    <One />
                    <Two />
                    <Three />
                    <Four />

                </StepWizard>
            </div>
        </>
    );
};

export default ReferralDetails;
