import moment from 'moment';

const isEmail = (email) => {
  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(regEx)) return true;
  else return false;
};

const isEmpty = (string) => {
  if (!string) return true;
  if (String(string).trim() === '') return true;
  else return false;
};

const isShort = (string, len) => {
  if (!string) return true;
  if (String(string).length < len) return true;
  else return false;
};

export function isNotFutureDate(inputDate) {
  if (isEmpty(inputDate)) return false;
  // Create a new Date object from the input string
  const dateParts = inputDate.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);
  const inputDateObj = new Date(year, month - 1, day); // Note: Months are zero-based (0 = January)

  // Get the current date
  const currentDate = new Date();

  // Compare inputDate with currentDate
  return inputDateObj <= currentDate;
}

export function isTodayOrFutureDate(inputDate) {
  const momentDate = moment(inputDate, 'YYYY-MM-DD');
  const today = moment();

  return momentDate.isSameOrAfter(today, 'day');
}

export function isDateBefore(date1, date2) {
  const momentDate1 = moment(date1, 'YYYY-MM-DD');
  const momentDate2 = moment(date2, 'YYYY-MM-DD');

  return momentDate1.isBefore(momentDate2);
}

export function isDateAfter(date1, date2) {
  const momentDate1 = moment(date1, 'YYYY-MM-DD');
  const momentDate2 = moment(date2, 'YYYY-MM-DD');

  return momentDate1.isAfter(momentDate2);
}


export function capitalizeFirstLetter(value) {
  // Check if the value is a string
  if (typeof value === 'string' || value instanceof String) {
    // Capitalize the first letter and return the modified string
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    // If not a string, return the value as is
    return value;
  }
}



export const validateLoginData = (data) => {
  let errors = {};

  if (isEmpty(data.username)) errors.username = 'Username must not be empty!';
  if (isShort(data.password, 6)) errors.password = 'Password must be 6 characters!';
  if (isEmpty(data.password)) errors.password = 'Password must not be empty!';



  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
};


export const validateSignupData = (data) => {
  let errors = {};

  if (isEmpty(data.username)) errors.username = 'Username must not be empty!';
  if (isShort(data.password, 8)) errors.password = 'Password must be 8 characters!';
  if (isEmpty(data.password)) errors.password = 'Password must not be empty!';

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
};


export const validatePatientDetails = (data) => {

  let errors = {};

  if (isEmpty(data.surname)) errors.surname = 'Last Name must not be empty!';
  if (isEmpty(data.forename)) errors.forename = 'First Name must not be empty!';
  if (isEmpty(data.dateofbirth)) errors.dateofbirth = 'Date of Birth must not be empty!';
  if (!isNotFutureDate(data.dateofbirth)) errors.dateofbirth = "Date of birth cannot be after today's date!";

  if (data.nhsnumber && String(data.nhsnumber).length !== 10) errors.nhsnumber = 'NHS Number must be 10 digits!';

  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
};

export const validateReferralDetails = (data) => {
  let errors = {};

  if (!isNotFutureDate(data.referraldatecervix)) errors.referraldatecervix = "Letter Date cannot be after today's date!";
  if (!isNotFutureDate(data.referralreceivedratecervix)) errors.referralreceivedratecervix = "Referral Received Date cannot be after today's date!";
  // if (isNotFutureDate(data.appointmentdatecervix)) errors.appointmentdatecervix = "Appointment Date cannot be before today's date!";
  if (isDateAfter(data.referraldatecervix, data.referralreceivedratecervix)) errors.referraldatecervix = 'Referral Letter Date cannot be after receive date!';
  if (isDateBefore(data.referralreceivedratecervix, data.referraldatecervix)) errors.referralreceivedratecervix = "Referral Received Date cannot be before Referral letter date!";
  // if (isDateBefore(data.appointmentdatecervix, data.referraldatecervix)) errors.appointmentdatecervix = "Referral Received Date cannot be before Referral letter date!";

  if (isEmpty(data.referraldatecervix)) errors.referraldatecervix = 'Referral Letter Date must not be empty!';
  if (isEmpty(data.referralreceivedratecervix)) errors.referralreceivedratecervix = 'Referral Received Date must not be empty!';
  if (isEmpty(data.appointmentcancellationreason) && isEmpty(data.appointmentdatecervix)) errors.appointmentdatecervix = 'Appointment Date must not be empty!';
  return {
    errors,
    valid: Object.keys(errors).length === 0 ? true : false
  };
};



