import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, getUserDetails } from "../../redux/actions/data.action";
import { CLEAR_TREATMENT_DETAILS, CLOSE_MODAL, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_MANAGEMENT_DETAILS, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS, SET_TREATMENT_DETAILS } from "../../redux/actions/types";
import { TreatmentCervicalData, TreatmentVaginalData } from "../../Utils/commonData";
import CustomModal from "../../component/Modals/CustomModal";



const TreatmentDetailsForm = (props) => {
  const { patientDetails, examination, examinationDetails, lastExamination, treatmentDetails, personalReferralDetails } = useSelector(({ data }) => data)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewOption, edited, modal } = useSelector(({ ui }) => ui)
  const [complicationsData, setComplicationsData] = useState(null);


  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`${viewOption === "enter" ? "/dashboard/patient/management" : "/dashboard/viewPatient/management"}`)

  };

  const handleChanges = prop => event => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }


    let newVal = { ...treatmentDetails, [prop]: event.target.value === "false" ? false : event.target.value === "true" ? true : event.target.value }

    if (prop === 'treatment' && !treatmentDetails.treatmentanaesthetic) {
      newVal = { ...newVal, treatmentanaesthetic: 'Local' }
    }

    if (prop === 'treatment' && prop !== 'treatmentbiopsytaken') {
      newVal = { ...newVal, treatmentbiopsytaken: true }
    }

    if (prop === 'treatmentcompilationsyesno' && event.target.value === 'No') {
      newVal = { ...newVal, treatmentcomplications: '' }
    }


    dispatch({ type: SET_TREATMENT_DETAILS, payload: newVal })
    dispatch({ type: SET_EDITED, payload: 'treatment' })
    // setExamDetails({ ...examinationDetails, [prop]: event.target.value })
  }


  const handleExit = (e) => {
    if (edited.length !== 0) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_TREATMENT_DETAILS })
    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)

  }


  const handleBack = () => {
    navigate(-1)
  }

  const handleComplications = (prop) => event => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }


    setComplicationsData(event.target.value)


  }


  const handleComments = prop => event => {
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (prop === 'consentnotes' || prop === 'mdtdecision') {
      let newVal = { ...examinationDetails, [prop]: event.target.value }

      dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'examination' })
    }

    if (prop === 'additionalinformation') {
      let newVal = { ...patientDetails, [prop]: event.target.value }

      dispatch({ type: SET_PATIENT_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, patientDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'personal' })
    }

    if (prop === 'provisionalplan') {
      let newVal = { ...personalReferralDetails, [prop]: event.target.value }

      dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, personalReferralDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'personal' })
    }


  }



  const handleConsentNotes = () => {
    dispatch({ type: OPEN_MODAL, payload: 'consentnotes' })
  }


  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        let { tbl_cervixtreatment } = res ? res : {};
        dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, ...res } })
        if (tbl_cervixtreatment) {
          dispatch({ type: SET_TREATMENT_DETAILS, payload: tbl_cervixtreatment })
        }
      })
  }




  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {

      if (examination && examination.examinationid) {
        handleGetExamination(examination.examinationid)
      }

      // if (treatmentDetails && treatmentDetails.treatmentcomplications) {
      //   setComplicationsData(treatmentDetails.treatmentcomplications)
      // }

    } else {
      navigate('/dashboard/home')
    }
  }, [examination])




  return (
    <>
      <CustomModal
        size="lg"
        open={modal === 'consentnotes'}
        title="Additional Notes and Comments"
      /*      onOkay={() => {
               dispatch({ type: CLOSE_MODAL })
           }} */
      >
        <div className="bordered pb-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h3 className="card-title bordered-title">Additional Comments</h3>
          <div>
            <div className="d-flex">
              <div className="col-md-6">
                <label className="text-left font-14 disabled" htmlFor="additionalcomments">Additional Comments Examinations</label>
                <textarea name="additionalcomments" id="additionalcomments" className="form-control " disabled required="" placeholder="" aria-invalid="false"
                  value={examinationDetails.additionalcomments}
                  // onChange={handleComments('treatmentreason')}
                  rows="3"
                />
              </div>
              <div className="col-md-6">
                <label className="text-left font-14" htmlFor="consentnotes">Consent Details</label>
                <textarea name="consentnotes" id="consentnotes" className="form-control" placeholder="" aria-invalid="false"
                  value={examinationDetails.consentnotes}
                  onChange={handleComments('consentnotes')}
                  rows="3"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-6">
                <label className="text-left font-14 disabled" htmlFor="treatmentreason">Discussion</label>
                <textarea name="treatmentreason" id="treatmentreason" className="form-control" disabled placeholder="" aria-invalid="false"
                  value={treatmentDetails.treatmentreason}
                  // onChange={handleComments('treatmentreason')}
                  rows="3"

                />
              </div>
              <div className="col-md-6">
                <label className="text-left font-14" htmlFor="additionalinformation">Any Other Additional Information</label>
                <textarea name="additionalinformation" id="additionalinformation" className="form-control" placeholder="" aria-invalid="false"
                  value={patientDetails.additionalinformation}
                  onChange={handleComments('additionalinformation')}
                  rows="3"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-12">
                <label className="text-left font-14" htmlFor="provisionalplan">Provisional Plan</label>
                <textarea name="provisionalplan" id="provisionalplan" className="form-control w-100" placeholder="" aria-invalid="false"
                  value={personalReferralDetails.provisionalplan}
                  onChange={handleComments('provisionalplan')}
                  rows="2"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-12">
                <label className="text-left font-14" htmlFor="mdtdecision">MDT Decision</label>
                <textarea name="mdtdecision" id="mdtdecision" className="form-control" placeholder="" aria-invalid="false"
                  value={examinationDetails.mdtdecision}
                  onChange={handleComments('mdtdecision')}
                  rows="2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pr-3 pl-3">
          <Button className="float-right mt-3" onClick={() => {
            dispatch({ type: CLOSE_MODAL })
          }}>Exit</Button>
        </div>
      </CustomModal>
      <CustomModal
        size="lg"
        open={modal === 'complications'}
        title="Select a Treatment Complication"
      /*      onOkay={() => {
               dispatch({ type: CLOSE_MODAL })
           }} */
      >
        <div className="bordered" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h3 className="card-title bordered-title">Treatment Complication </h3>
          <div className='text-left  w-75 pb-3'>
            <input
              type="radio"
              name="treatmentcomplication_radio"
              id="addition"
              value="Additional haemostatic technique required"
              checked={complicationsData === 'Additional haemostatic technique required'}
              className="" onChange={handleComplications('treatmentcomplications')}
            />
            <label htmlFor="addition">Additional haemostatic technique required</label>
            <div className='clr'></div>
            <input
              type="radio"
              name="treatmentcomplication_radio"
              id="admission"
              value="Admission to hospital"
              checked={complicationsData === 'Admission to hospital'}
              className="" onChange={handleComplications('treatmentcomplications')}
            />
            <label htmlFor="admission">Admission to hospital</label>
            <div className='clr'></div>  <input
              type="radio"
              name="treatmentcomplication_radio"
              id="treatmentcompother"
              value="Other"
              checked={complicationsData && (complicationsData !== 'Additional haemostatic technique required' && complicationsData !== 'Admission to hospital')}
              className="" onChange={handleComplications('treatmentcomplications')}
            />
            <label htmlFor="treatmentcompother">Other</label>
            <div className='clr'></div>
            <input
              id="treatmentotherinput"
              value={complicationsData && (complicationsData === 'Additional haemostatic technique required' || complicationsData === 'Admission to hospital') ? '' : complicationsData}
              className="form-control ml-3" onChange={handleComplications('treatmentcomplications')}
            />
          </div>
        </div>
        <div className="pr-3 pl-3">
          <Button className="float-right mt-3" onClick={() => {
            dispatch({ type: SET_TREATMENT_DETAILS, payload: { ...treatmentDetails, treatmentcomplications: complicationsData } })
            dispatch({ type: SET_EDITED, payload: 'treatment' })
            dispatch({ type: CLOSE_MODAL })
          }}>Exit</Button>
        </div>
      </CustomModal>
      <Form
        id="formValid"
        noValidate
        onSubmit={handleSubmit}

      >
        <div className="pl-2 pr-2">
          <div className="d-flex pl-2 pr-2 mt-2 ">
            <div className='col-md-6 text-left mb-3 pl-2 pr-2' >
            </div>
            <div className='col-md-6 text-right mb-2 pl-2 pr-2' >
              <label className="col-md-5 text-right disabled" >Treatment Date</label>
              <input
                disabled
                type="date" id="mdmdate" name="mdmdate" className="form-control col-md-6" placeholder="MDM Date"
                onChange={handleChanges('mdmdate')}
              />
              <div className='clr'></div>
            </div>
          </div>
          <div className='row bordered mt-2'>
            <h3 className='card-title text-center bordered-title'>Treatment</h3>
            <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
              <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                <div className="bordered">
                  <h3 className="card-title txtLeft bordered-title text-14">Cervical</h3>
                  <div className="pl-3 pr-3">
                    <div className="row text-center">
                      <div className="custom-radio text-left">
                        <div className="row mb-2">
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="cervicalnone"
                              value="None"
                              checked={treatmentDetails.treatment === 'None'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="cervicalnone">None</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Laser Ablation"
                              value="Laser Ablation"
                              checked={treatmentDetails.treatment === 'Laser Ablation'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Laser Ablation">Laser Ablation</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Cold coagulation"
                              value="Cold coagulation"
                              checked={treatmentDetails.treatment === 'Cold coagulation'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Cold coagulation">Cold coagulation</label>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Diathermy"
                              value="Diathermy"
                              checked={treatmentDetails.treatment === 'Diathermy'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Diathermy">Diathermy</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Cryotherapy"
                              value="Cryotherapy"
                              checked={treatmentDetails.treatment === 'Cryotherapy'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Cryotherapy">Cryotherapy</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Knife Cone"
                              value="Knife Cone"
                              checked={treatmentDetails.treatment === 'Knife Cone'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Knife Cone">Knife Cone</label>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-8">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Loop/Laser Excision"
                              value="Loop/Laser Excision"
                              checked={treatmentDetails.treatment === 'Loop/Laser Excision'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Loop/Laser Excision">Loop/Laser Excision</label>
                          </div>
                          <div className="col-md-4">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Hysterectomy"
                              value="Hysterectomy"
                              checked={treatmentDetails.treatment === 'Hysterectomy'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Hysterectomy">Hysterectomy</label>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-8">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Loop with top hat(extended loop)"
                              value="Loop with top hat(extended loop)"
                              checked={treatmentDetails.treatment === 'Loop with top hat(extended loop)'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Loop with top hat(extended loop)">Loop with top hat(extended loop)</label>
                          </div>
                          <div className="col-md-4 pl-5">
                            <input
                              type="checkbox"
                              name="cervicalopinion_radio"
                              id="treatmentbiopsytaken"
                              value={!treatmentDetails.treatmentbiopsytaken}
                              checked={treatmentDetails.treatmentbiopsytaken}
                              className="" onChange={handleChanges('treatmentbiopsytaken')}
                            />&nbsp;
                            <label htmlFor="treatmentbiopsytaken">Biopsy</label>
                          </div>
                        </div>
                        <div className="clearfix mb-2"></div>
                        <div className="row">
                          <div className="col-md-6 pt-1">
                            <input
                              type="radio"
                              name="cervicalopinion_radio"
                              id="Other"
                              value="Other"
                              checked={treatmentDetails.treatment === 'Other'}
                              className="" onChange={handleChanges('treatment')}
                            />
                            <label htmlFor="Other">Other</label>
                          </div>
                          <div className="col-md-6">

                            <select name="treatmentothername" id="treatmentother" className="form-control mb-3"
                              value={treatmentDetails.treatment}
                              onChange={handleChanges('treatment')}
                              style={{ marginBottom: 20 }}

                            >
                              <option value={null}></option>
                              {TreatmentCervicalData.length !== 0 && TreatmentCervicalData.map(a => {
                                return (
                                  <option key={a} value={a}>{a}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                <div className="bordered">
                  <h3 className="card-title txtLeft bordered-title text-14">Vaginal</h3>
                  <div className="pl-3 pr-3">
                    <div className="row text-center">
                      <div className="custom-radio text-left">
                        <div className="row mb-2">
                          <div className="col-md-6">
                            <input
                              type="radio"
                              name="vaginaltreatmentradio"
                              id="None"
                              value="None"
                              checked={treatmentDetails.treatmentvaginal === 'None'}
                              className="" onChange={handleChanges('treatmentvaginal')}
                            />
                            <label htmlFor="None">None</label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="radio"
                              name="vaginaltreatmentradio"
                              id="Vaporisation"
                              value="Vaporisation"
                              checked={treatmentDetails.treatmentvaginal === 'Vaporisation'}
                              className="" onChange={handleChanges('treatmentvaginal')}
                            />
                            <label htmlFor="Vaporisation">Vaporisation</label>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-6">
                            <input
                              type="radio"
                              name="vaginaltreatmentradio"
                              id="Local Excision"
                              value="Local Excision"
                              checked={treatmentDetails.treatmentvaginal === 'Local Excision'}
                              className="" onChange={handleChanges('treatmentvaginal')}
                            />
                            <label htmlFor="Local Excision">Local Excision</label>
                          </div>
                          <div className="col-md-6 pl-5">
                            <input
                              type="checkbox"
                              name="vaginaltreatmentradio"
                              id="treatmentvaginalbiopsytaken"
                              value={Boolean(treatmentDetails.treatmentvaginalbiopsytaken) == true ? false : true}
                              checked={Boolean(treatmentDetails.treatmentvaginalbiopsytaken) == true}
                              className="" onChange={handleChanges('treatmentvaginalbiopsytaken')}
                            />&nbsp;
                            <label htmlFor="treatmentvaginalbiopsytaken">Biopsy</label>
                          </div>
                        </div>
                        <input
                          type="radio"
                          id="Partial Vaginectomy"
                          value="Partial Vaginectomy"
                          checked={treatmentDetails.treatmentvaginal === 'Partial Vaginectomy'}
                          className="" onChange={handleChanges('treatmentvaginal')}
                        />
                        <label htmlFor="Partial Vaginectomy">Partial Vaginectomy</label>
                        <div className="clearfix mb-2"></div>
                        <input
                          type="radio"
                          id="Total Vaginectomy"
                          value="Total Vaginectomy"
                          checked={treatmentDetails.treatmentvaginal === 'Total Vaginectomy'}
                          className="" onChange={handleChanges('treatmentvaginal')}
                        />

                        <label htmlFor="Total Vaginectomy">Total Vaginectomy</label>
                        <div className="clearfix mb-2"></div>
                        <div className="col-md-6">
                          <select name="treatmentvaginalvaporisation" id="treatmentvaginalvaporisation" className="form-control mb-3"
                            value={treatmentDetails.treatmentvaginalvaporisation}
                            onChange={handleChanges('treatmentvaginalvaporisation')}
                            style={{ marginBottom: 20 }}

                          >
                            <option value="None"></option>
                            {TreatmentVaginalData.length !== 0 && TreatmentVaginalData.map(a => {
                              return (
                                <option key={a} value={a}>{a}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex pl-1" style={{ justifyContent: "center", alignItems: "flex-start", flexGrow: 1 }}>
              <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                <div className="row mb-2 pl-2 pr-2">
                  <div className="col-md-5 text-left pl-2 pt-3">
                    <label className="text-left font-14" htmlFor="Unsatisfactory">Treatment method additional Details</label>
                  </div>
                  <div className="col-md-7">
                    <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false"
                      value={treatmentDetails.treatmentreason}
                      onChange={handleChanges('treatmentreason')}
                    />
                  </div>
                </div>
                <div className="row mb-2 pl-2 pr-2">
                  <div className="col-md-5 text-left pl-2 pt-3">
                    <label className="text-left font-14" htmlFor="Unsatisfactory">Treatment duration</label>
                  </div>
                  <div className="col-md-7">
                    <input type="number" name="treatmentduration" id="treatmentduration" className="form-control" placeholder="" aria-invalid="false"
                      value={treatmentDetails.treatmenttime}
                      onChange={handleChanges('treatmenttime')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3" style={{ flexGrow: 1 }} >
                <div className="col-md-7 text-left" >
                  <label className="control-label text-left col-md-4">Chaperone</label>
                  <input type="text" id="treatmentchaperone" style={{ fontSize: 12 }} className="form-control col-md-8 text-left" placeholder=""
                    value={treatmentDetails.treatmentchaperone}
                    onChange={handleChanges('treatmentchaperone')}

                  />
                  <div className='clr'></div>
                </div>
                <br />
                <div className="row mb-2 text-left pl-2 pr-2">
                  <div className="col-md-6">
                    <input
                      type="checkbox"
                      name="consent_radio"
                      id="treatmentverbalconsent"
                      value={!treatmentDetails.treatmentverbalconsent}
                      checked={treatmentDetails.treatmentverbalconsent}
                      className="" onChange={handleChanges('treatmentverbalconsent')}
                    />&nbsp;
                    <label htmlFor="treatmentverbalconsent">Verbal consent given</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="checkbox"
                      name="consent_radio"
                      id="treatmentconsent"
                      value={!treatmentDetails.treatmentconsent}
                      checked={treatmentDetails.treatmentconsent}
                      onChange={handleChanges('treatmentconsent')}
                    />&nbsp;
                    <label htmlFor="treatmentconsent" >Written consent given</label>
                  </div>
                </div>
                <div className="row mb-2 text-left pl-2 pr-2">
                  <div className="col-md-6 mb-3">
                    <Button
                      onClick={() => handleConsentNotes()}
                      disabled={(!treatmentDetails.consentdeclined && !treatmentDetails.treatmentconsent && !treatmentDetails.treatmentverbalconsent)}
                    >Add Consent Notes</Button>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="checkbox"
                      name="consent_radio"
                      id="consentdeclined"
                      value={!treatmentDetails.consentdeclined}
                      checked={treatmentDetails.consentdeclined}
                      onChange={handleChanges('consentdeclined')}
                    />&nbsp;
                    <label htmlFor="consentdeclined" >Consent declined</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className='col-md-6 text-left mb-3' >
              <div className="bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Analgesia</h3>
                <div className="pl-3 pr-3">
                  <div className="row text-center">
                    <div className="custom-radio text-left">
                      <div className="row mb-2">
                        <div className="col-md-4">
                          <input
                            type="radio"
                            name="treatmentanaesthetic_radio"
                            id="anaestheticnone"
                            value="None"
                            checked={treatmentDetails.treatmentanaesthetic === 'None'}
                            className="" onChange={handleChanges('treatmentanaesthetic')}
                          />
                          <label htmlFor="anaestheticnone">None</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="radio"
                            name="treatmentanaesthetic_radio"
                            id="anaestheticlocal"
                            value="Local"
                            checked={treatmentDetails.treatmentanaesthetic === 'Local'}
                            className="" onChange={handleChanges('treatmentanaesthetic')}
                          />
                          <label htmlFor="anaestheticlocal">Local</label>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="radio"
                            name="treatmentanaesthetic_radio"
                            id="anaestheticgeneral"
                            value="General"
                            checked={treatmentDetails.treatmentanaesthetic === 'General'}
                            className="" onChange={handleChanges('treatmentanaesthetic')}
                          />
                          <label htmlFor="anaestheticgeneral">General</label>
                        </div>
                      </div>
                      <div className="row pl-2 pr-2 mb-2">
                        <label className="control-label text-left col-md-3">Type/amount</label>
                        <input type="text" id="anaesthetictypeamount" style={{ fontSize: 12 }} className="form-control col-md-6 text-left" placeholder=""
                          value={treatmentDetails.anaesthetictypeamount}
                          onChange={handleChanges('anaesthetictypeamount')}
                        />
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 text-left mb-2' >
              <div className="bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Pain</h3>
                <div className="pl-3 pr-3">
                  <div className="row text-center">
                    <div className="custom-radio text-left">
                      <div className="row ">
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="pain_radio"
                            id="Nil"
                            value="Nil"
                            checked={treatmentDetails.treatmentpain === 'Nil'}
                            className="" onChange={handleChanges('treatmentpain')}
                          />
                          <label htmlFor="Nil">Nil</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="pain_radio"
                            id="More local anaesthetic"
                            value="More local anaesthetic"
                            checked={treatmentDetails.treatmentpain === 'More local anaesthetic'}
                            className="" onChange={handleChanges('treatmentpain')}
                          />
                          <label htmlFor="More local anaesthetic">More local anaesthetic</label>
                        </div>

                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="pain_radio"
                            id="Acceptable"
                            value="Acceptable"
                            checked={treatmentDetails.treatmentpain === 'Acceptable'}
                            className="" onChange={handleChanges('treatmentpain')}
                          />
                          <label htmlFor="Acceptable">Acceptable</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            name="pain_radio"
                            id="Procedure abandoned"
                            value={treatmentDetails.treatmentpain !== 'Procedure abandoned' ? 'Procedure abandoned' : null}
                            checked={treatmentDetails.treatmentpain === 'Procedure abandoned'}
                            className="" onChange={handleChanges('treatmentpain')}
                          />
                          <label htmlFor="Procedure abandoned">Procedure abandoned</label>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-md-6 text-left mb-3' >
              <div className="bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Lesion Size(s)</h3>
                <div className="row pl-3 pr-3 center">
                  <input type="text" id="treatmentsize" style={{ fontSize: 12 }} className="form-control col-md-10 text-left mt-2" placeholder=""
                    value={treatmentDetails.treatmentsize}
                    onChange={handleChanges('treatmentsize')}
                  />
                </div>
                <br />
              </div>
            </div>
            <div className='col-md-6 text-left mb-3' >
              <div className="bordered">
                <h3 className="card-title txtLeft bordered-title text-14">Complications</h3>
                <div className="row pl-3 pr-3 center mb-2">
                  <div className="col-md-3">
                    <div className="col-md-12">
                      <input
                        type="radio"
                        name="complicationyesno_radio"
                        id="treatmentcompilationsyes"
                        value="Yes"
                        checked={treatmentDetails.treatmentcompilationsyesno === 'Yes'}
                        className="" onChange={handleChanges('treatmentcompilationsyesno')}
                      />
                      <label htmlFor="treatmentcompilationsyes">Yes</label>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="radio"
                        name="complicationyesno_radio"
                        id="treatmentcompilationsno"
                        value="No"
                        checked={treatmentDetails.treatmentcompilationsyesno === 'No'}
                        className="" onChange={handleChanges('treatmentcompilationsyesno')}
                      />
                      <label htmlFor="treatmentcompilationsno">No</label>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <input type="text" id="appointmentcancellationreason" style={{ fontSize: 12 }} className="form-control text-left bg-primary" placeholder=""
                      value={treatmentDetails.treatmentcomplications}
                      onClick={() => {
                        setComplicationsData(treatmentDetails.treatmentcomplications)
                        dispatch({ type: OPEN_MODAL, payload: "complications" })
                      }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-xs-center fL" style={{ display: 'flex', float: 'right', padding: '5px' }}>
          <button onClick={() => handleBack()} disabled className="btn btnIri mr-2 ml-5 btn btn-secondary">Previous</button>
          <button type="submit" className="btn btnIri mr-2 btn btn-secondary">Next</button>
          <button onClick={() => handleExit()} className="btn btnIri mr-2 fR btn btn-secondary">Exit</button>
        </div>
      </Form>
    </>

  );
};


const TreatmentDetails = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { patientDetails, examination, lastExamination } = useSelector(({ data }) => data)
  const [examinationDetails, setExamDetails] = useState({});



  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        setExamDetails(res)
      })
  }



  useEffect(() => {
    if (examination && examination.examinationid) {
      handleGetExamination(examination.examinationid)
    }
  }, [examination])


  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
    } else {
      navigate('/dashboard/home')
    }
  }, [patientDetails])



  return (
    <>
      <div className="card-header bg-iri txtCenter">
        <h4 className="m-b-0 text-white">Patient's Treatment Details</h4>

      </div>
      <div className="card-body">
        <TreatmentDetailsForm />
      </div>
    </>
  )
}

export default TreatmentDetails;
