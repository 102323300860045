import React from 'react';
import { Col, Row, Button, ButtonGroup, Container } from "reactstrap";
import OptionsFrameHOC from '../../component/Forms/OptionsFrameHOC';
import { OPEN_MODAL, SET_VIEW_OPTION } from '../../redux/actions/types';
import { useDispatch } from 'react-redux';

const Letters = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ type: SET_VIEW_OPTION, payload: 'enter' })
    dispatch({ type: OPEN_MODAL, payload: 'newPatient' });
  }

  const handleReview = () => {
    alert('Review Clicked');
    console.log('Review Clicked');
  };

  const handlePrint = () => {
    alert('Print Clicked');
    console.log('Print Clicked');
  };

  const handleExit = () => {
    console.log('Exiting Now');
  };

  return (
    <>
      <OptionsFrameHOC
        title="Print Letter Options"
        titleSize="lg"
      >
        <div>

          <div className="letter-container">
            <div className="top">
              <label>Patient Letters</label>&nbsp;&nbsp;
              <input type="checkbox" />&nbsp;
              <label>Informal</label>
            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary" onClick={() => handleClick()}>First Appointment</Button>
                <Button className="btn-btn" variant="primary">First Appointment - Treatment</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Free Text</Button>
                <Button className="btn-btn" variant="primary">Negative Colposcopy NSC</Button>
              </ButtonGroup>
            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Hospital Cancel & Re-book  </Button>
                <Button className="btn-btn" variant="primary">Patient Cancel & Re-boook</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Results & Re-colp</Button>
                <Button className="btn-btn" variant="primary">Results & Treatment</Button>
              </ButtonGroup>
            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">DNA</Button>
                <Button className="btn-btn" variant="primary">Reminder</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Treatment Results</Button>
                <Button className="btn-btn" id="blank" variant="primary">.</Button>
              </ButtonGroup>
            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Discharge</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Post-Treatment Checkup</Button>
              </ButtonGroup>
            </div>
          </div>

          <br />
          <div className="letter-container">
            <div className="top">
              <label>GP Letters</label>&nbsp;&nbsp;
              <input type="checkbox" />&nbsp;
              <label id="label2">Referrer Copy</label>
            </div>
            <div className="button-group-container">

            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">First Examination</Button>
                <Button className="btn-btn" variant="primary">See & Treat</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Free Text</Button>
                <Button className="btn-btn" variant="primary">Results & Treatment</Button>
              </ButtonGroup>
            </div>
            <div className="button-group-container">
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Results & Manangement</Button>
                <Button className="btn-btn" variant="primary">Follow-up Examinaion</Button>
              </ButtonGroup>
              <ButtonGroup className="btn-group">
                <Button className="btn-btn" variant="primary">Treatment Done</Button>
                <Button className="btn-btn" variant="primary">Treatment Results</Button>
              </ButtonGroup>
            </div>
          </div>
          <div id="grp-btn" className="button-group-container">
            <ButtonGroup className="btn-group">
              <Button className="btn-btn" id="colm" variant="primary">Follow-up Examinaion</Button>
            </ButtonGroup>
            <ButtonGroup className="btn-group">
              <Button className="btn-btn" id="colms" variant="primary">Treatment Results</Button>
            </ButtonGroup>
            <div className="">
              <button id="ext-btn" className="btns btn btnIri">Exit</button>
            </div>
            <ButtonGroup className="btn-group">
            </ButtonGroup>
          </div>
          <div id="btns-grp" className="buttons-container">
            <div className="lefts-buttons">
              <button className="btns">Previous</button>
              <button className="btns">Discharge</button>
            </div>
            <div className="right-button">
              <button className="btns btn btnIri">Exit</button>
            </div>
          </div>
        </div>
      </OptionsFrameHOC>
    </>
  )
}

export default Letters;