import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";

import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getCervixExaminationDetailsById, uploadFiles } from 'redux/actions/data.action';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, OPEN_MODAL, SET_DATASHEET_PICTURES, SET_EXAMINATION_DETAILS } from '../../../redux/actions/types';
import { env_vars } from 'Utils/config';
import axios from 'axios';
import ImageEditor from 'tui-image-editor'; 
import './ImageEditor.css';
import AnnotationTools from 'component/AnnotationTools';
import DrawingTools from 'component/DrawingTools';
import { baseToFormData } from 'Utils/helpers';


export default function ColposcopicImagingReview() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const maxZoom = 2;
    const minZoom = 1;


    const { patientDetails, examination, datasheetpictures } = useSelector(({ data }) => data)
    const { viewOption, edited } = useSelector(({ ui }) => ui)
    const [selectedImages, setSelectedImages] = useState([]);
    const [enlargedImage, setEnlargedImage] = useState(null);
    const [imageEditor, setImageEditor] = useState(null);
    const [isZoom, setIsZoom] = useState(null);
    const [activeTool, setActiveTool] = useState(null);
    const [originPosition, setOriginPosition] = useState({});
    const [prevPosition, setPrevPosition] = useState({});
    const [zoom, setZoom] = useState(1)
    const [drawingStyle, setDrawingStyle] = useState({color: "#000",width: 10});
    const [styleSelected, setStyleSelected] = useState({fill: "#000",fontSize: 20, fontStyle: 'normal', fontWeight: 'normal', textDecoration: ''});
    const [activeObject, setActiveObject] = useState(null);
    const [rnd, setRnd] = useState(0)
    const handleImageClick = (url) => {
        if (!selectedImages.find(a => a.ipictureid === url.ipictureid)) {

            if (selectedImages.length < 4) {
                setSelectedImages([url, ...selectedImages]);
            } else {
                let newSel = selectedImages;
                newSel.splice(3, 1);
                setSelectedImages([url, ...newSel]);
            }
        } else {
            let newSel = selectedImages.filter(a => a.ipictureid !== url.ipictureid);
            setSelectedImages(newSel)

        }

    };

    const handleImageDoubleClick = (url) => {
        setEnlargedImage(url); 
        // Additional logic for showing functionality on the right side
    };

    const handleDrawing = (type) => {
        if(type === 'line'){
        
        setActiveTool(type)
        imageEditor.startDrawingMode('LINE_DRAWING', {
            width: drawingStyle.width,
            color: drawingStyle.color
           
       }, );    
    }
    
    console.log(type)
    if(type === 'area'){
    console.log("AREAAA")
    
        setActiveTool(type)
        imageEditor.startDrawingMode('FREE_DRAWING', {
            width: drawingStyle.width,
            color: drawingStyle.color
           
       });
    
    }
    }


    const handleSave = () =>{
            // Example: You can call TUI Image Editor's addText() method here
            // imageEditor.addImageObject(imagePath);
            
           let imgSrc = imageEditor.toDataURL();
           let imgPath = `examination-picture-${datasheetpictures.length + 1}.png`;
           console.log(imgSrc)
           baseToFormData(imgSrc, imgPath)
           .then((imgFile) => {
                    const formData = new FormData();
                        formData.append('files', imgFile);
                      return  dispatch(uploadFiles(formData, examination.examinationid))
           }).then(res => {
            handleGetExamination(examination.examinationid)
            imageEditor.loadImageFromURL(imgSrc, imgPath).then(() => {
                imageEditor.addImageObject(imgSrc);
            });
           })
           .catch(err => {
            console.log(err)
           })
    }


    const handleGetExamination = (id) => {
        dispatch(getCervixExaminationDetailsById(id))
            .then(res => {
                let { tbl_datasheetpicturenumbers } = res;
                dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...res, ...examination } })
                 dispatch({ type: SET_DATASHEET_PICTURES, payload: tbl_datasheetpicturenumbers })
                
            })
    }


    const handleNext = () => {


        navigate(`${viewOption === 'enter' ? '/dashboard/patient/colposcopicFindings' : '/dashboard/viewPatient/colposcopicFindings'}`)

    }


    const handleBack = () => {
        navigate(-1)
    };



    const handleExit = (id) => {
        if (edited.find(a => a === 'examination')) {
            dispatch({ type: OPEN_MODAL, payload: "save_details" })
            // return

        } else {
            dispatch({ type: CLEAR_EXAMINATION })
            dispatch({ type: CLEAR_EXAMINATION_DETAILS })

        }


        navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)
    }
    
    
    const handleDone = () => {
        setActiveTool(null);
        setActiveObject(null);
 
 
        if(imageEditor){
           imageEditor.deactivateAll();
           imageEditor.stopDrawingMode();

        }
    }
    
    const handleDoubleClick = () => {
        if((activeTool === 'annotation' && !activeObject && prevPosition)){
            handleAddText()
        } 
    }
    
    const handleAnnotation = (prop, event) => {
        let newStyles = {};
        if(prop === 'style'){
            if(event === 'bold'){
               let newWeight = event === styleSelected.fontWeight ? 'normal' : event;
               newStyles.fontWeight = newWeight
            }
            
            if(event === 'italic'){
                let newStyle = event === styleSelected.fontStyle ? 'normal' : event;
                newStyles.fontStyle = newStyle;
            }
            if(event === 'underline'){
                  let newStyle = event === styleSelected.textDecoration ? '' : 'underline';
                  newStyles.textDecoration = newStyle;
            }
            
            
        } else {
            if(prop === 'size'){
                newStyles.fontSize = event
            }
            
            if(prop === 'color'){
                newStyles.fill = event
            }        
        }
        
     
        

        
        if(imageEditor && activeObject){
            imageEditor.changeTextStyle(activeObject.id, {
                ...newStyles
            });
        }
        
        setStyleSelected({
            ...styleSelected,  ...newStyles
        })
        
        
        setRnd(Math.random())
        
    }
    
    
    const handleDrawingStyle = (prop, val) => {
            imageEditor.setBrush({[prop]: val});
            setDrawingStyle({[prop]: val})
    }


    const handleAddText = () => {
    
        imageEditor.addText('Write Text', {
            styles: {
                fill: styleSelected.fill,
                fontSize: styleSelected.fontSize,
                fontWeight: styleSelected.fontWeight
            },
        })
        
    }

    const handleZoom = (type) => {
        if(imageEditor){
        
        if(type){
            imageEditor.changeCursor('zoom-in');
            setIsZoom(true)   
        } else {
            imageEditor.changeCursor('zoom-out');
            setIsZoom(false)
        }
    }
    
        
    }
    

    useEffect(() => {
        if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
            if (examination && examination.examinationid) {
                handleGetExamination(examination.examinationid)
            }

        }



    }, [patientDetails, examination])




    useEffect(() => {
        // Load sample image when the component mounts
        let editor = null;
        if(enlargedImage){
       editor = new ImageEditor('.tui-image-editor', {
          cssMaxHeight: '100%',
          usageStatistics: false
        });
    
        // Additional initialization code goes here
        editor.loadImageFromURL(`${env_vars.api_url}/static/${enlargedImage.path}`,  enlargedImage.path)
        
        
        editor.on('objectActivated', function(props) {
            setPrevPosition(null)
            setActiveObject(props);
            if(props.type === 'i-text'){
                setActiveTool('annotation');
                setStyleSelected({
                    fill: props.fill,
                    fontWeight: props.fontWeight,
                    fontStyle: props.fontStyle,
                    textDecoration: props.textDecoration,
                    fontSize: Number(props.fontSize)
                })
            }
        });
            editor.on('mousedown', function(event, originPointer) {
                    let { offsetX, offsetY } = event;
                        setOriginPosition({x: offsetX, y: offsetY})
                });    
    
    
    
    
    
        setImageEditor(editor);
        
        
        
        
        
        
        }
    
        return () => {
          // Clean up code (e.g., remove event listeners) goes here
          if(editor){
            editor.destroy();
            editor = null;
            setIsZoom(null);
            setZoom(1);
            setActiveTool(null)
          }
        };
      }, [enlargedImage]);
      
      
      
      
      
    
      
    useEffect(() => {
        let newClick = true;
        
        if(prevPosition !== null && (prevPosition.x === originPosition.x && prevPosition.y === originPosition.y)){
            newClick = false;
        } else {
            setPrevPosition(originPosition);
        }
        if(originPosition && imageEditor){
            if(activeTool === 'zoom' && isZoom !== null){
                let updatedZoom = zoom;
                
                var canvasSize = imageEditor.getCanvasSize();
            
                let newX = originPosition.x;
                let newY = originPosition.y;
                
                
                if(isZoom){
                    updatedZoom = maxZoom <= zoom ? updatedZoom : updatedZoom + 0.1 ;
                    newX = originPosition.x - (originPosition.x - canvasSize.width / 2) * 0.1;
                    newY = originPosition.y - (originPosition.y - canvasSize.height / 2) * 0.1;
            } else {
                updatedZoom = minZoom >= zoom ? updatedZoom :  updatedZoom - 0.1;
            }
            imageEditor.zoom( {
                x: newX,
                y: newY,
                zoomLevel: updatedZoom
        }, newX, newY, updatedZoom)
        setZoom(updatedZoom);
        }
        
        if(activeTool === 'annotation'){
       
            if(prevPosition){
            if(newClick){
            //    setActiveTool(null);
            } else {
               handleAddText()
            } 
            } 
           
        }
        
        if(!activeTool){
            imageEditor.changeCursor('pointer');
             setActiveObject(null);
            // imageEditor.deactivateAll();
            // imageEditor.discardSelection();
        }
             
        
        
        
            }
        
        
      }, [originPosition, activeObject]) 
      
      
      useEffect(() => {
        console.log('RND', rnd)
      }, [rnd])
      
      
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* Left: Picture List */}
                <div style={{ width: '10%', overflowY: 'auto', minHeight: '450px', maxHeight: '80vh'}}>
                    {datasheetpictures.map((url, index) => (
                        <img
                            key={index}
                            src={`${env_vars.api_url}/static/${url.path}`}
                            alt={`Image ${index + 1}`}
                            onClick={() => handleImageClick(url)}
                            onDoubleClick={() => handleImageDoubleClick(url)}
                            style={{ width: '100%', cursor: 'pointer', marginBottom: '10px' }}
                        />
                    ))}
                </div>

                {/* Center: Picture Display Area */}
                <div style={{ width: '75%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {enlargedImage ?
                        <div
                            className={`editor-image body-container ${activeTool === "zoom" ? isZoom ? "cursor-zoom-in" : "cursor-zoom-out"   : "" }`}
                            style={{
                                gridColumn: 'span 4',
                                marginBottom: '20px',
                                width: '100%', // Adjust width as needed,
                                position: 'relative'
                            }}
                            
                            onDoubleClick={() => handleDoubleClick()}
                        >
                        <div
                        className='cursor-pointer'
                        style={{position: 'absolute', top: 10, right: 10, zIndex: 1}}
                        >
                        <i 
                        onClick={() => setEnlargedImage(null)}
                        className='fa fa-times text-danger img-close '></i>
                        </div>
                      
                        <div className={`tui-image-editor ${activeTool === "zoom" ? isZoom ? "cursor-zoom-in" : "cursor-zoom-out"   : "" }`}>
                        </div>
                      
                        </div>
                        : <>

                            {selectedImages.map((url, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: '50%', // Adjust width as needed
                                        cursor: 'pointer',
                                    }}
                                    onDoubleClick={() => setEnlargedImage(url)}
                                >
                                    <img
                                        src={`${env_vars.api_url}/static/${url.path}`}
                                        alt={`Selected ${index + 1}`}
                                        style={{ width: '98%', height: '250px', marginBottom: '5px' }}
                                    />
                                </div>
                            ))}
                        </>
                    }
                </div>

                {/* Right: Additional Functionality */}
                <div style={{ width: '20%', padding: '10px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='bordered pl-2 pr-2' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                        
                        {!activeTool ? 
                        <>
                        <div className='mt-2' style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                        <div className='row align-items-center  mt-1 mb-1'>
                        <div className='col-md-5'>
                        <Button size="sm" className='btn-w-85 text-11 text-center'
                        onClick={() => setActiveTool('zoom')}
                        disabled={!enlargedImage}
                        ><span className='text-11'>Zoom</span></Button>
                        </div>
                        <div className='col-md-7' style={{display: 'flex', alignItem: 'center', justifyContent: 'center'}}>
                             <span className='text-12 mt-1 text-primary'>Zoom in and out</span>
                            </div>
                      </div>
                      <div className='row align-items-center  mt-1 mb-1'>
                      <div className='col-md-5'>
                      <Button size="sm" className='btn-w-85 text-11 text-center'
                    //   onClick={() => setActiveTool('zoom')}
                      disabled={true}
                      ><span className='text-11'>Biopsy</span></Button>
                      </div>
                      <div className='col-md-7' style={{display: 'flex', alignItem: 'center', justifyContent: 'center'}}>
                           <span className='text-12 mt-1 text-primary'>Show biopsy sites</span>
                          </div>
                    </div>
                    <br/>
                    <div className='row align-items-center mt-1 mb-1'>
                    <div className='col-md-6'>
                    <Button size="sm" className='btn-w-85 text-11 text-center'
                    onClick={() => {
                       setActiveTool('annotation');
                        handleAddText()
                    
                    }}
                    disabled={!enlargedImage}
                    ><span className='text-11'>Annotation</span></Button>
                    </div>
                    <div className='col-md-6 pt-2 flex-grow-1'>
                    <span className='text-12 ml-1 text-primary'>Write Text</span>
                        </div>
                  </div>
                  <div className='row align-items-center  mt-1 mb-1'>
                  <div className='col-md-6'>
                  <Button size="sm" className='btn-w-85 text-11 text-center'
                  onClick={() => handleDrawing('line')}
                  disabled={!enlargedImage}
                  ><span className='text-11'>Line</span></Button>
                  </div>
                  <div className='col-md-6 pt-2  flex-grow-1'>
                  <span className='text-12 ml-1 text-primary'>Draw Line</span>
                      </div>
                </div>
                <div className='row align-items-center  mt-1 mb-1'>
                  <div className='col-md-6'>
                  <Button size="sm" className='btn-w-85 text-11 text-center'
                  onClick={() => handleDrawing('area')}
                  disabled={!enlargedImage} 
                  ><span className='text-11'>Area</span></Button>
                  </div>
                  <div className='col-md-6 pt-2  flex-grow-1'>
                  <span className='text-12 ml-1 text-primary'>Draw shape</span>
                      </div>
                </div>
                <br/>
                <div className='row align-items-center  mt-1 mb-1'>
                  <div className='col-md-6'>
                  <Button size="sm" className='btn-w-85 text-11 text-center'
                  onClick={() => handleSave()}
                  disabled={!enlargedImage} 
                  ><span className='text-11'>Save</span></Button>
                  </div>
                  <div className='col-md-6 pt-2  flex-grow-1'>
                  <span className='text-12 ml-1 text-primary'>Save image</span>
                      </div>
                </div>
                <div className='row align-items-center  mt-1 mb-1'>
                  <div className='col-md-6'>
                  <Button size="sm" className='btn-w-85 text-11 text-center'
                  onClick={() => setActiveTool('Save')}
                  disabled={true}
                  ><span className='text-11'>Print</span></Button>
                  </div>
                  <div className='col-md-6 pt-2  flex-grow-1'>
                  <span className='text-12 ml-1 text-primary'>Print image</span>
                      </div>
                </div>
                <div className='row align-items-center  mt-1 mb-1'>
                  <div className='col-md-6'>
                  <Button size="sm" className='btn-w-85 text-11 text-center'
                  onClick={() => setActiveTool('Save')}
                  disabled={true}
                  ><span className='text-11'>Copy</span></Button>
                  </div>
                  <div className='col-md-6 pt-2  flex-grow-1'>
                  <span className='text-12 ml-1 text-primary'>Copy to disk</span>
                      </div>
                </div>
                </div>
                        <div className='pb-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>

                            <Button type="submit" className="btn mr-2 ml-2 color-black" onClick={() => handleBack()}>Previous</Button>

                            <Button type="submit" className="btn btnIri mr-2"
                                // disabled={!(examinationDetails.name && Object.keys(errors).length === 0 ? true : false)}
                                onClick={() => handleNext()}
                            >NEXT</Button>

                            <Button className="btn btnIri"
                                onClick={() => handleExit()}
                            >EXIT</Button>

                        </div>
                        </>
                        
                        :
                        <>
                        { activeTool === 'zoom' && 
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <div className='row align-items-center mt-2 mb-2'>
                        <label className='text-left text-primary'>Zoom</label>
                        </div>

                        <div className='row align-items-center'>
                        <div className='col-md-4'>
                        <Button className={`btn-w-50 text-11 text-center ${isZoom ? "btnIri" : ""}`}
                        onClick={() => handleZoom(true)}
                        disabled={maxZoom <= zoom && true}
                        ><span className='text-11'><i className='fa fa-search-plus' /></span></Button>
                        </div>
                        <div className='col-md-4' style={{display: 'flex', alignItem: 'center', justifyContent: 'center'}}>
                                <span className='text-12 mt-2 text-primary'>{Number(zoom * 100).toFixed(0)}%</span>
                          </div>
                          <div className='col-md-4'>
                      <Button className={`btn-w-50 text-11 text-center ${( isZoom !== null && !isZoom) ?"btnIri" : "" }`}
                      onClick={() => handleZoom(false)}
                      disabled={minZoom >= zoom && true}
                      ><span className='text-11'><i className='fa fa-search-minus' /></span></Button>
                      </div>
                      </div>
                      </div>

                    }
                    { activeTool === 'annotation' && 
                    <AnnotationTools
                        styleSelected={styleSelected}
                        handleStyle={handleAnnotation}
                    />
                }
                { (activeTool === 'line' || activeTool === 'area') &&
                <DrawingTools
                    drawingStyle={drawingStyle}
                    handleStyle={handleDrawingStyle}
                />
            }
                
                       
                        <div className='pb-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        

                        <Button type="submit"  className="btn mr-2 ml-2 color-black" 
                        // disabled={!(examinationDetails.name && Object.keys(errors).length === 0 ? true : false)}
                            onClick={() => handleDone()}
                        >Close</Button>
                      

                    </div>
                    </>
                    }

                    </div>
                </div>

                {/* Enlarged Image Modal */}

            </div>
        </>
    )
}
