import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { env_vars } from '../../Utils/config';



const Topbar = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(({ auth }) => auth);
    const navigate = useNavigate();
    const { searchString } = useSelector(({ data }) => data);
    const [searchStr, setSearchStr] = useState('');


    const addClassSidebar = () => {
        console.log('addClassSidebar')
        // document.body.classList.toggle('mini-sidebar', isOpen);
        if (document.body.classList.contains('mini-sidebar')) {
            document.body.classList.remove('mini-sidebar');
        } else {
            document.body.classList.add('mini-sidebar');
        }
    }

    const addClassSidebar2 = () => {
        console.log('addClassSidebar2');

    }


    useEffect(() => {
        setSearchStr(searchString)
    }, [searchString])

    return (
        <>




            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/dashboard">
                            <img src={require('../../../src/img/dashboard_03.png')} alt="homepage" className="dark-logo img-fluid" />
                        </Link>
                    </div>

                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item"> <a className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" onClick={(e) => addClassSidebar2()}><i className="ti-menu"></i></a> </li>
                            <li className="nav-item"> <a className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" onClick={addClassSidebar}><i className="icon-menu"></i></a> </li>
                            {/* <li className="nav-item">
                                <form className="app-search d-none d-md-block d-lg-block mt-2" onSubmit={handleSearch}>
                                    <input type="text" className="form-control" placeholder="Search & enter" value={searchStr} onChange={(e) => handleChange(e.target.value)} />
                                </form>
                            </li> */}
                        </ul>

                        <div className="navbar-nav" style={{ alignItems: 'center' }}>
                            <div className="nav-item dropdown u-pro">
                                <a className="nav-link dropdown-toggle waves-effect waves-dark profile-pic" onClick={(e) => e.preventDefault()} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={env_vars.api_url + `/static/${user.imgUrl}`} alt="user" className="" />
                                    {<span className="hidden-md-down"> {user.name} &nbsp;
                                        {/* <i className="fa fa-angle-down"></i> */}
                                    </span>}
                                </a>
                                <div className="dropdown-menu dropdown-menu-right animated flipInY">
                                    <a className="dropdown-item"><i className="ti-user"></i> My Profile</a>
                                    <a className="dropdown-item"><i className="ti-wallet"></i> My Balance</a>
                                    <a className="dropdown-item"><i className="ti-email"></i> Inbox</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item"><i className="ti-settings"></i> Account Setting</a>
                                    <div className="dropdown-divider"></div>
                                    <a href="login.html" className="dropdown-item"><i className="fa fa-power-off"></i> Logout</a>

                                </div>
                            </div>
                            <div className="nav-item dropdown u-pro"> <a className="nav-link  waves-effect waves-light center" ><i className="ti-settings"></i></a></div>
                        </div>
                    </div>

                </nav>
            </header>


        </>
    )
}

export default Topbar;