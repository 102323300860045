import React, { useEffect, useState } from 'react';
import '../../css/Licensing.css'; // Import your CSS file
import {
    Button,
    Card,
    CardText,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    FormGroup,
    Label,
    Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { generateCode, generateMotivationalPassword } from '../../Utils/helpers';
import { useDispatch, useSelector } from "react-redux";
import { getLicenseCode, getPasswordToday } from '../../redux/actions/auth.action';
import moment from 'moment';



const YearlyLicense = (props) => {
    // control email in parent so user doesnt lose it in wrong form
    const [activeTab, setActiveTab] = React.useState('compuscope');
    const [email, setEmail] = React.useState('');
    return (
        <>
            <Card>
                <CardHeader tag="h6" className="p-0 border-bottom-0 m-0">
                    <Nav tabs fill>
                        <NavItem
                        >
                            <NavLink active={activeTab == 'compuscope'} onClick={() => setActiveTab('compuscope')}>
                                COMPUSCOPE
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink
                                disabled
                                href="#"
                            // active={activeTab == 'login'} 
                            // onClick={() => setActiveTab('login')}
                            >
                                COMPUSCOPE LITE
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                disabled
                                href="#"
                            // active={activeTab == 'licensedate'}
                            // onClick={() => setActiveTab('licensedate')}
                            >
                                Get Date From License
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="compuscope">
                        <Compuscope />
                    </TabPane>
                    <TabPane tabId="compuscopelite">
                        <LoginContent />
                    </TabPane>
                    <TabPane tabId="licensedate">
                        <GetDateLicense />
                    </TabPane>
                </TabContent>
            </Card>
        </>
    );
};

const MachineLicense = (props) => {
    // control email in parent so user doesnt lose it in wrong form
    const [activeTab, setActiveTab] = React.useState('signup');
    const [email, setEmail] = React.useState('');
    return (
        <>
            <Card>
                <CardHeader tag="h6" className="p-0 border-bottom-0">
                    <Nav tabs fill>
                        <NavItem>
                            <NavLink active={activeTab == 'signup'} onClick={() => setActiveTab('signup')}>
                                Windows Registry Code Only
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                disabled
                                href="#"
                                active={activeTab == 'licensedate'}
                                onClick={() => setActiveTab('licensedate')}
                            >
                                Windows Registry and Time Code
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="signup">
                        <SignupContent email={email} setEmail={setEmail} />
                    </TabPane>
                    <TabPane tabId="login">
                        <LoginContent email={email} setEmail={setEmail} />
                    </TabPane>
                    <TabPane tabId="licensedate">
                        <GetDateLicense email={email} setEmail={setEmail} />
                    </TabPane>
                </TabContent>
            </Card>
        </>
    );
};

const Compuscope = (props) => {
    const dispatch = useDispatch();
    const currentYear = moment().add(1, 'year');
    const [isCopy, setIsCopy] = useState(false);
    const [genCode, setGenCode] = useState(null);
    const [values, setValues] = useState({
        expiryDate: currentYear,
        sitenumber: 1,
        numberofusers: 1,
        products: []
    });

    const handleSelect = (prop) => {

        let ind = values.products.find(a => a === prop);
        if (ind) {
            let newArr = values.products.filter(item => item !== prop);
            setValues({ ...values, products: newArr });

        } else {
            let newArr = values.products;
            newArr.push(prop);
            setValues({ ...values, products: newArr });
        }

    }


    const handleChanges = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }

    const handleGenerate = async () => {
        let code = await dispatch(getLicenseCode())
        setGenCode(code);

    }

    const handleCopy = () => {
        navigator.clipboard.writeText(genCode);
        setIsCopy(true);

        setTimeout(() => {
            setIsCopy(false);
        }, 5000);

    }




    // Generate an array of the next 50 years
    const years = Array.from({ length: 10 }, (_, index) => moment().add(index + 1, 'year'));
    const sites = Array.from({ length: 5 }, (_, index) => index + 1);


    return (
        <>
            <div className="licensing-viewer p-2">
                <div className='bordered m-1' style={{ width: '100%', minHeight: '150px' }}>
                    <h3 className="card-title bordered-title text-12 text-dark ml-1">Generate COMPUSCOPE Application License Code</h3>
                    <div className='pl-2 pr-2'>
                        <div className='d-flex'>
                            <div className="col-md-7 text-left mb-3">
                                <div className="d-flex">
                                    <div className='text-left col-md-9'>
                                        <Label for="exampleEmail" className='text-12 mt-1 font-500'>Maximum number of users:</Label>
                                    </div>
                                    <div className='col-md-3 text-left'>
                                        <Input
                                            type="number"
                                            id="totalusers"
                                            value={values.numberofusers}
                                            size="sm"
                                            onChange={handleChanges('numberofusers')}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className='text-left col-md-5'>
                                        <Label for="exampleEmail" className='mt-1 font-500'>Expiry Date:</Label>
                                    </div>
                                    <div className='col-md-7 text-left'>
                                        <Input size="sm" type="select" value={values.selectedYear} onChange={handleChanges('selectedYear')}>
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {moment(year).format('MM/DD/YYYY')}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className='text-left col-md-8'>
                                        <Label for="exampleEmail" className='mt-1 font-500'>Site Number:</Label>
                                    </div>
                                    <div className='col-md-4 text-left'>
                                        <Input size="sm" type="select" value={values.sitenumber} onChange={handleChanges('sitenumber')}>
                                            {sites.map((site) => (
                                                <option key={site} value={site}>
                                                    {site}
                                                </option>
                                            ))}
                                        </Input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pl-4 text-left">
                                <div className="d-flex pl-2">
                                    <Input type="checkbox"
                                        name="Vulva"
                                        id="Vulva"
                                        checked={values.products.find(a => a === 'Vulva')}
                                        onClick={() => handleSelect('Vulva')}
                                    />
                                    <Label for="Vulva" className='ml-1 mt-1 font-500 text-12'>Vulva</Label>
                                </div>
                                <div className="d-flex pl-2">
                                    <Input type="checkbox"
                                        name="Oncology"
                                        id="Oncology"
                                        checked={values.products.find(a => a === 'Oncology')}
                                        onClick={() => handleSelect('Oncology')}
                                    />
                                    <Label for="Oncology" className='ml-1 mt-1 font-500 text-12'>Oncology</Label>
                                </div>
                                <div className="d-flex pl-2">
                                    <Input type="checkbox"
                                        name="Hysteroscopy"
                                        id="Hysteroscopy"
                                        checked={values.products.find(a => a === 'Hysteroscopy')}
                                        onClick={() => handleSelect('Hysteroscopy')}
                                    />
                                    <Label for="Hysteroscopy" className='ml-1 mt-1 font-500 text-12'>Hysteroscopy</Label>
                                </div>
                                <div className="d-flex pl-2">
                                    <Input type="checkbox"
                                        name="Anal Cancer Screening"
                                        id="Anal Cancer Screening"
                                        checked={values.products.find(a => a === 'Anal Cancer Screening')}
                                        onClick={() => handleSelect('Anal Cancer Screening')}
                                    />
                                    <Label for="Anal Cancer Screening" className='ml-1 mt-1 font-500 text-12'>Anal Cancer Screening</Label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex pl-2 pr-2'>
                            <div className="col-md-3 text-left  mt-2">
                                <Label for="exampleEmail" className='mt-1 font-500'>License Code:</Label>
                            </div>
                            <div className="col-md-6 text-left mt-3 mb-2" style={{ width: '100%', height: '30px' }}>
                                <h3 style={{ fontWeight: '600' }} className='text-dark text-center'>{genCode}</h3>
                            </div>
                            <div className="col-md-3 text-left">
                            </div>
                        </div>

                        <div className='d-flex mb-3' style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Button className="mr-3" style={{ width: '150px' }} onClick={() => handleGenerate()}>Create License</Button>
                            <Button disabled={!genCode} className="mr-3" style={{ width: '150px', opacity: isCopy ? '.3' : '1' }} onClick={() => handleCopy()}>Copy License</Button>

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}


const SignupContent = (props) => {
    const [values, setValues] = useState({});

    const handleChanges = prop => event => {
        setValues({ ...values, [prop]: event.target.value })
    }



    return (
        <>
            <div className="licensing-viewer p-2">
                <div className='bordered m-1' style={{ width: '100%', minHeight: '150px' }}>
                    <h3 className="card-title bordered-title text-11 text-dark ml-1">Generate COMPUSCOPE Application License Code</h3>
                    <div className='pl-2 pr-2'>
                        <div className='d-flex'>
                            <div className="col-md-7">
                                <FormGroup row>
                                    <Col md="8">
                                        <Label for="exampleEmail" className='mt-1 font-500'>Maximum number of users</Label>
                                    </Col>
                                    <Col md="4">
                                        <Input
                                            type="number"
                                            id="totalusers"
                                            value={props.email}
                                            size="sm"
                                            onChange={handleChanges('')}
                                        />
                                    </Col>
                                </FormGroup>
                            </div>
                            <div className="col-md-5">

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}



const LoginContent = (props) => {
    return (
        <>
            <div className="licensing-viewer p-2">
                <Form className="text-left">
                    <FormGroup row>
                        <Col md="4">
                            <Label for="exampleEmail">Email</Label>
                        </Col>
                        <Col md="8">
                            <Input
                                type="email"
                                name="email"
                                id="exampleEmail"
                                placeholder="with a placeholder"
                                value={props.email}
                                onChange={e => props.setEmail(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="4">
                            <Label for="examplePassword">Password</Label>
                        </Col>
                        <Col md="8">
                            <Input type="password" name="password" id="examplePassword" placeholder="password placeholder" />
                        </Col>
                    </FormGroup>
                    <Button color="primary" block>Log In</Button>
                </Form>
            </div>
        </>
    )
}


const GetDateLicense = (props) => {
    return (
        <>
            <div className="licensing-viewer p-2">
                <div className="paper">
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', minHeight: '40px' }}>
                        <h3 style={{ fontSize: '50px', color: "#8f3644" }}>COMPU</h3>
                        <h3 style={{ fontSize: '50px', fontWeight: '600' }} >SCOPE</h3>
                    </div>
                </div>
                <div className='mt-2' style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                    <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '20px', fontWeight: '600' }}>11/19/2023</h6>
                    <h6 className='pr-2' style={{ color: "#8f3644", fontWeight: '600', lineHeight: '20px' }} >Version 11</h6>
                </div>
                <br />
                <div style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <h6 className='pl-2' style={{ color: "#8f3644", lineHeight: '10px', fontWeight: '500' }}>Copyright © 1996-2023 IRISOFT Ltd. All rights reserved.</h6>
                </div>
                <div className='mt-2' style={{ width: '100%', display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
                    <p className='pl-2 text-13' style={{ fontWeight: '500' }} >This product is licensed to:</p>
                    <div className='d-flex'>
                        <Input type="checkbox" name="cervicalstenosis" className="mb-1"
                            id="cervicalstenosis"

                        />
                        <Label htmlFor="cervicalstenosis" className="ml-1 text-12" style={{ lineHeight: '22px' }}>Report Writer</Label>
                    </div>
                    <div className='d-flex'>
                        <Input type="checkbox" name="cervicalstenosis" className=""
                            id="cervicalstenosis"

                        />
                        <Label htmlFor="cervicalstenosis" className="ml-1 text-12" style={{ lineHeight: '22px' }}>System Management</Label>
                    </div>
                </div>
                <div className="paper">
                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', height: 'auto' }}>
                        <div style={{ fontSize: '25px', fontWeight: '500', color: "#8f3644", lineHeight: '30px' }}>Your Hospital Name</div>
                        <div style={{ fontSize: '25px', color: "#8f3644", lineHeight: '30px' }}>(Your site name)</div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Button >Colposcopy</Button>
                    <Button >Hysteroscopy</Button>
                    <Button >Oncology</Button>
                    <Button >Vulvoscopy</Button>
                </div>
            </div>
        </>
    )
}




export default function LicenseGenerator() {
    const dispatch = useDispatch();
    const [passwordToday, setPasswordToday] = useState(null);


    const handleGeneratePassword = async () => {
        let passw = await dispatch(getPasswordToday())
        setPasswordToday(passw)
    }


    const handleCopy = () => {
        navigator.clipboard.writeText(passwordToday);


    }

    useEffect(() => {
        document.title = 'Compuscope License Generator';
        handleGeneratePassword();

    }, [])


    return (
        <div className="licensing-container">
            <div className="licensing-wrapper">
                <div className="bordered">
                    <h3 className='card-title licensing-title'>Yearly Renewal License</h3>
                    <div className='pl-2 pr-2'>
                        <YearlyLicense />
                    </div>
                </div>
                {/*   <div className="bordered mt-3">
                <h3 className='card-title licensing-title'>Machine Specific License</h3>
                <div className='pl-2 pr-2'>
                    <MachineLicense />
                    </div>
                </div> */}
                <div className="bordered mt-3">
                    <h3 className='card-title licensing-title' onClick={() => handleGeneratePassword()}>Today's Temporary Password</h3>
                    <div className='p-2 text-center' style={{ height: '50px' }}>
                        <h3 style={{ fontWeight: '600' }} className='text-dark' onClick={() => handleCopy()}>{passwordToday}</h3>
                    </div>
                </div>
            </div>
        </div >
    )
}
