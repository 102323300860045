import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// ES6 Modules or TypeScript
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { CLEAR_EXAMINATION, CLEAR_EXAMINATION_DETAILS, CLOSE_MODAL, OPEN_MODAL, SET_EDITED, SET_EXAMINATION_DETAILS, SET_MANAGEMENT_DETAILS, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS } from '../../../redux/actions/types';
import { getCervixExaminationDetailsById, getUserDetails } from '../../../redux/actions/data.action';
import CustomModal from '../../../component/Modals/CustomModal';



const One = (props) => {
  const { treatmentDetails, managementDetails, patientDetails, examinationDetails, examination, personalReferralDetails } = useSelector(({ data }) => data);
  const { viewOption, edited, modal } = useSelector(({ ui }) => ui)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clinician, setClinician] = useState([]);
  const [validated, setValidated] = useState(false);
  const [monthSmear, setMonthSmear] = useState(0);


  const handleChanges = prop => event => {
    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};


    let newVal = { ...managementDetails, [prop]: event.target.value === 'true' ? true : event.target.value === 'false' ? false : event.target.value }

    if (prop === 'managementplan' && event.target.value === 'Treatment') {
      newVal = { ...newVal, treatmentstatus: managementDetails.treatmentstatus ? managementDetails.treatmentstatus : "Not done" }
    }



    if (event.target.value === 'Smear') {
      dispatch({ type: OPEN_MODAL, payload: 'smear' })
      setMonthSmear(managementDetails.managementplanmonthstoclinic)
    }



    dispatch({ type: SET_MANAGEMENT_DETAILS, payload: newVal })
    localStorage.setItem('formDetails', JSON.stringify({ ...frm, managementDetails: newVal }))
    dispatch({ type: SET_EDITED, payload: 'examination' })

    if (event.target.value === 'Treatment') {
      navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}/treatment`)
    }
  }

  const handleBack = () => {
    navigate(-1)
  }

  const handleExit = (e) => {
    e.preventDefault();

    if (managementDetails.managementplan === 'Smear' && !managementDetails.managementplanmonthstoclinic) {
      dispatch({ type: OPEN_MODAL, payload: "smear" })
      return
    }



    if (edited.length !== 0) {
      dispatch({ type: OPEN_MODAL, payload: "save_details" })
      // return

    } else {
      dispatch({ type: CLEAR_EXAMINATION })
      dispatch({ type: CLEAR_EXAMINATION_DETAILS })

    }


    navigate(`${viewOption === "enter" ? "/dashboard/patient" : "/dashboard/viewPatient"}`)

  }

  const handleLetter = () => {
    navigate(`/dashboard/letter-reports/generate`)
  }



  const handleChangeClinician = (prop, e) => {

    if (viewOption === 'review') {
      return console.log('REVIEW MODE')
    }

    let selcted = clinician.find(a => a.iuserid == e.target.value)
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (selcted) {
      let { iuserid, name } = selcted;
      dispatch({ type: SET_MANAGEMENT_DETAILS, payload: { ...managementDetails, [prop]: iuserid, treatementuser: name } })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, managementDetails: { ...managementDetails, [prop]: iuserid, treatementuser: name } }))


    } else {

      dispatch({ type: SET_MANAGEMENT_DETAILS, payload: { ...managementDetails, [prop]: null, treatementuser: null } })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, managementDetails: { ...managementDetails, [prop]: null, treatementuser: null } }))

    }
    dispatch({ type: SET_EDITED, payload: 'examination' })

  }


  const handleComments = prop => event => {
    let formDetails = localStorage.getItem('formDetails');
    let frm = formDetails ? JSON.parse(formDetails) : {};

    if (prop === 'consentnotes' || prop === 'mdtdecision') {
      let newVal = { ...examinationDetails, [prop]: event.target.value }

      dispatch({ type: SET_EXAMINATION_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, examinationDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'examination' })
    }

    if (prop === 'additionalinformation') {
      let newVal = { ...patientDetails, [prop]: event.target.value }
      dispatch({ type: SET_PATIENT_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, patientDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'personal' })
    }

    if (prop === 'provisionalplan') {
      let newVal = { ...personalReferralDetails, [prop]: event.target.value }
      dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: newVal })
      localStorage.setItem('formDetails', JSON.stringify({ ...frm, personalReferralDetails: newVal }))
      dispatch({ type: SET_EDITED, payload: 'personal' })
    }
  }


  const handleGetClinics = () => {

    dispatch(getUserDetails({ access: 2 }))
      .then(res => {

        if (res) {
          setClinician(res)
        }
      })

  }


  const handleGetExamination = (id) => {
    dispatch(getCervixExaminationDetailsById(id))
      .then(res => {
        let { tbl_cervixmanagement } = res ? res : {};

        dispatch({ type: SET_EXAMINATION_DETAILS, payload: { ...examinationDetails, ...res } })
        if (!managementDetails.examinationid && tbl_cervixmanagement) {
          dispatch({ type: SET_MANAGEMENT_DETAILS, payload: tbl_cervixmanagement })
        }
      })
  }






  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
      if ((examination && examination.examinationid) && (!examinationDetails.examinationid || !managementDetails.examinationid)) {
        handleGetExamination(examination.examinationid)
      }
    } else {
      navigate("/dashboard/home")
    }




  }, [])

  useEffect(() => {
    handleGetClinics()
  }, [])


  const dischargeDate = (
    <div className='bordered'>
      <h3 className="card-title text-center bordered-title">Discharge Date</h3>
      <div className='form-group pl-2 pr-2'>
        <div className="text-left pl-5 pr-5" >
          <label className="control-label text-center col-md-3" >Date / Time</label>
          <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
          <div className='clr'></div> <br /><br />
        </div>
        <div className='bordered disabled'>
          <h3 className="card-title text-left bordered-title">Attendance Record</h3>
          <div className='row pl-3 pr-3'>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >Scheduled</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >H cancel</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >P Cancel(99)</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >DNA(99)</label>
              <div className='clr'></div>
              <br />
            </div>
          </div>
          <div className="col-md-12 text-left" >
            <label className="control-label text-center col-md-3" >Cancel Reason</label>
            <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
            <div className='clr'></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )

  const nextAppointment = (
    <div className='bordered'>
      <h3 className="card-title text-center bordered-title">Next Appointment Date</h3>
      <div className='form-group pl-2 pr-2'>
        <div className="text-left pl-5 pr-5" >
          <label className="control-label text-center col-md-3" >Date / Time</label>
          <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
          <div className='clr'></div> <br /><br />
        </div>
        <div className='bordered'>
          <h3 className="card-title text-left bordered-title">Attendance Record</h3>
          <div className='row pl-3 pr-3'>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >Scheduled</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >H cancel</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >P Cancel(99)</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >DNA(99)</label>
              <div className='clr'></div>
              <br />
            </div>
          </div>
          <div className="col-md-12 text-left" >
            <label className="control-label text-center col-md-3" >Cancel Reason</label>
            <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
            <div className='clr'></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )


  const TreatmentAppointment = (
    <div className='bordered'>
      <h3 className="card-title text-center bordered-title">Treatment Appointment Date</h3>
      <div className='form-group pl-2 pr-2'>
        <div className="text-left pl-5 pr-5" >
          <label className="control-label text-center col-md-3" >Date / Time</label>
          <input type="date" id="treatmentdate" className="form-control col-md-8 text-center" placeholder=""
            value={managementDetails.treatmentdate}
            onChange={handleChanges('treatmentdate')}
          />
          <div className='clr'></div> <br /><br />
        </div>
        <div className='bordered'>
          <h3 className="card-title text-left bordered-title">Attendance Record</h3>
          <div className='row pl-3 pr-3'>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >Scheduled</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >H cancel</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >P Cancel(99)</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >DNA(99)</label>
              <div className='clr'></div>
              <br />
            </div>
          </div>
          <div className="col-md-12 text-left" >
            <label className="control-label text-center col-md-3" >Cancel Reason</label>
            <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
            <div className='clr'></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )

  const smearAppointment = (
    <div className='bordered'>
      <h3 className="card-title text-center bordered-title">{managementDetails.managementplanmonthstoclinic ? managementDetails.managementplanmonthstoclinic : 0}-month Smear Appointment Date</h3>
      <div className='form-group pl-2 pr-2'>
        <div className="text-left pl-5 pr-5" >
          <label className="control-label text-center col-md-3" >Date / Time</label>
          <input type="date" id="smearappointment" className="form-control col-md-8 text-center" placeholder=""
            value={managementDetails.managementplanclinicdate ? moment(managementDetails.managementplanclinicdate).format('YYYY-MM-DD') : ''}
            onChange={handleChanges('managementplanclinicdate')}
          />
          <div className='clr'></div> <br />
        </div>
        <div className='bordered'>
          <h3 className="card-title text-left bordered-title">Attendance Record</h3>
          <div className='row pl-3 pr-3'>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >Scheduled</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" required="" className="" />
              <label className="control-label text-end" >H cancel</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" className="" />
              <label className="control-label text-end" >P Cancel(99)</label>
              <div className='clr'></div>
            </div>
            <div className='col-md-3'>
              <input type="radio" value="1" name="styled_radio" className="" />
              <label className="control-label text-end" >DNA(99)</label>
              <div className='clr'></div>
            </div>
          </div>
          <div className="col-md-12 text-left" >
            <label className="control-label text-center col-md-3" >Cancel Reason</label>
            <input type="text" id="firstName" className="form-control col-md-8 text-center" placeholder="" required />
            <div className='clr'></div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )




  return (
    <>
      <CustomModal
        size="lg"
        open={modal === 'smear'}
        title="Smear Details (0 in total)"
      /*      onOkay={() => {
               dispatch({ type: CLOSE_MODAL })
           }} */
      >
        <div className="bordered pb-2 " style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
          <h3 className="card-title bordered-title">Current Smear</h3>
          <div className="pl-3 pr-3" style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            {/*   <input type="number" name="managementplanmonthstoclinic" id="managementplanmonthstoclinic" className="form-control col-md-2 mr-2"
              value={monthSmear}
              onChange={(e) => setMonthSmear(e.target.value)}
            /> */}
            <select name="managementplanmonthstoclinic" id="managementplanmonthstoclinic"
              className="form-control col-md-2 mr-2"
              value={monthSmear}
              onChange={(e) => setMonthSmear(e.target.value)}
            >
              <option value="0">0</option>
              {[3, 6, 9, 12, 24].map(a => {
                return (
                  <option value={a}>{a}</option>
                )
              })}
            </select>
            <input name="consentnotes" id="consentnotes" className=" form-control col-md-10" placeholder="" aria-invalid="false"
              value="-Month Smear"
            // onChange={handleComments('consentnotes')}
            />
          </div>
        </div>
        <div className="bordered pb-2 mt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h3 className="card-title bordered-title">Previous Smears</h3>
          <div className="pl-3 pr-3" style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
            <textarea name="additionalcomments" id="additionalcomments" className="form-control col-md-12" required="" placeholder="" aria-invalid="false"
              // value={examinationDetails.additionalcomments}
              // onChange={handleComments('additionalcomments')}
              rows="5"
            />
          </div>
        </div>
        <div className="pr-3 pl-3  mt-3">

          <Button className="float-right ml-2  btnIri" onClick={() => {
            dispatch({ type: CLOSE_MODAL })
            dispatch({ type: SET_MANAGEMENT_DETAILS, payload: { ...managementDetails, managementplanmonthstoclinic: monthSmear } })
            dispatch({ type: SET_EDITED, payload: 'examination' })

          }}>Ok</Button>
          <Button className="float-right btn btn-light text-black" onClick={() => {
            dispatch({ type: CLOSE_MODAL })
          }}>Cancel</Button>
        </div>
      </CustomModal>
      <CustomModal
        size="lg"
        open={modal === 'consentnotes'}
        title="Additional Notes and Comments"
      /*      onOkay={() => {
               dispatch({ type: CLOSE_MODAL })
           }} */
      >
        <div className="bordered pb-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h3 className="card-title bordered-title">Additional Comments</h3>
          <div>
            <div className="d-flex">
              <div className="col-md-6">
                <label className="text-left font-14 disabled" htmlFor="additionalcomments">Additional Comments Examinations</label>
                <textarea name="additionalcomments" disabled id="additionalcomments" className="form-control " required="" placeholder="" aria-invalid="false"
                  value={examinationDetails.additionalcomments}
                  onChange={handleComments('additionalcomments')}
                  rows="3"
                />
              </div>
              <div className="col-md-6">
                <label className="text-left font-14" htmlFor="consentnotes">Consent Details</label>
                <textarea name="consentnotes" id="consentnotes" className="form-control" placeholder="" aria-invalid="false"
                  value={examinationDetails.consentnotes}
                  onChange={handleComments('consentnotes')}
                  rows="3"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-6">
                <label className="text-left font-14 disabled" htmlFor="treatmentreason">Discussion</label>
                <textarea name="treatmentreason" id="treatmentreason" disabled className="form-control" placeholder="" aria-invalid="false"
                  value={treatmentDetails.treatmentreason}
                  onChange={handleComments('treatmentreason')}
                  rows="3"

                />
              </div>
              <div className="col-md-6">
                <label className="text-left font-14" htmlFor="additionalinformation">Any Other Additional Information</label>
                <textarea name="additionalinformation" id="additionalinformation" className="form-control" placeholder="" aria-invalid="false"
                  value={patientDetails.additionalinformation}
                  onChange={handleComments('additionalinformation')}
                  rows="3"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-12">
                <label className="text-left font-14" htmlFor="provisionalplan">Provisional Plan</label>
                <textarea name="provisionalplan" id="provisionalplan" className="form-control w-100" placeholder="" aria-invalid="false"
                  value={personalReferralDetails.provisionalplan}
                  onChange={handleComments('provisionalplan')}
                  rows="2"

                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-md-12">
                <label className="text-left font-14" htmlFor="mdtdecision">MDT Decision</label>
                <textarea name="mdtdecision" id="mdtdecision" className="form-control" placeholder="" aria-invalid="false"
                  value={examinationDetails.mdtdecision}
                  onChange={handleComments('mdtdecision')}
                  rows="2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pr-3 pl-3">
          <Button className="float-right mt-3" onClick={() => {
            dispatch({ type: CLOSE_MODAL })
          }}>Exit</Button>
        </div>
      </CustomModal>
      <Form
        id="formValid"
        noValidate
        validated={validated}
        onSubmit={handleExit}
      >
        <div className="">
          <div className='pl-2 pr-2 text-left'>
            <div className='row bordered'>
              <h3 className='card-title text-center bordered-title'>Management Plan</h3>
              <div className="d-flex pl-2 pr-2">
                <div className='col-md-2 pl-4 mb-2 pl-2 pr-2 mt-3' >
                  <input
                    type="radio"
                    value="Await Decision"
                    id="Await Decision"
                    checked={managementDetails.managementplan === 'Await Decision'}
                    name="managementplan_radio" className="" onChange={handleChanges('managementplan')}
                  />
                  <label className="" htmlFor='Await Decision' >Await Decision</label>
                  <div className='clearfix'></div>
                </div>
                <div className='col-md-4 text-left mb-3 pl-2 pr-2' >
                  <button type="button" className="btn btnIri marRight20 text-left"
                    onClick={() => dispatch({ type: OPEN_MODAL, payload: 'consentnotes' })}

                  >Provisional Plan</button>
                </div>

                <div className='col-md-6 text-right mb-2 pl-2 pr-2' >
                  <label className="col-md-2 text-left" >MDM Date</label>
                  <input
                    type="date" id="mdmdate" name="mdmdate" className="form-control col-md-6" placeholder="MDM Date"
                    value={managementDetails.mdmdate ? moment(managementDetails.mdmdate).format('YYYY-MM-DD') : ''}
                    onChange={handleChanges('mdmdate')}
                  />
                  <div className='clr'></div>
                </div>
              </div>
              <div className="col-md-6">
                <br />
                <div className="bordered pl-2 pr-2">
                  <h3 className="card-title txtLeft bordered-title">Treatment Plan</h3>
                  <br />
                  <div className='form-group d-flex pl-3 pr-3'>
                    <div className="col-md-4 pt-2">
                      <input type="radio"
                        value="Treatment"
                        id="Treatment"
                        checked={managementDetails.managementplan === 'Treatment'}
                        name="managementplan_radio" className="" onChange={handleChanges('managementplan')}
                        onClick={() => {
                          if (viewOption === 'review') {
                            return console.log('REVIEW MODE')
                          }
                          if (managementDetails.managementplan === 'Treatment') {
                            navigate(`/dashboard/patient/treatment`)
                          }
                        }}
                      />
                      <label className="control-label text-end" htmlFor='Treatment' >Treatment</label>
                      <div className='clr'></div>
                    </div>
                    <div className="col-md-8 ">
                      <label className="col-md-4" >Colposcopist</label>
                      <select name="treatmentname" id="treatmentname" className="col-md-8 form-control"
                        value={managementDetails.treatmentname}
                        onChange={(e) => handleChangeClinician('treatmentname', e)}
                      >
                        <option value="0">Select</option>
                        {clinician.length !== 0 && clinician.map(a => {
                          return (
                            <option value={a.iuserid}>{a.name}</option>
                          )
                        })}
                      </select>
                      <div className='clr'></div>
                    </div>
                  </div>

                  <div className='form-group bordered d-flex pl-3 pr-3'>
                    <div className="col-md-4 padTop5">
                      <input type="radio"
                        value="Not done"
                        id="Not done"
                        checked={managementDetails.treatmentstatus === 'Not done'}
                        name="treatmentstatus_radio" className="" onChange={handleChanges('treatmentstatus')}
                      />
                      <label className="control-label text-end" htmlFor='Not done' >Not Done</label>
                      <div className='clr'></div>
                    </div>

                    <div className="col-md-4 padTop5">
                      <input type="radio"
                        value="Awaiting results"
                        id="Awaiting results"
                        checked={managementDetails.treatmentstatus === 'Awaiting results'}
                        name="treatmentstatus_radio" className="" onChange={handleChanges('treatmentstatus')}
                      />
                      <label className="control-label text-end" htmlFor='Awaiting results' >Awaiting Results</label>
                      <div className='clr'></div>
                    </div>

                    <div className="col-md-4 padTop5">
                      <input type="radio"
                        value="Completed"
                        id="Completed"
                        checked={managementDetails.treatmentstatus === 'Completed'}
                        name="treatmentstatus_radio" className="" onChange={handleChanges('treatmentstatus')}
                      />
                      <label className="control-label text-end" htmlFor='Completed'>Completed</label>
                      <div className='clr'></div>
                    </div>
                    <div className='clr'></div>
                  </div>
                </div>
                <br />
                <div className='clearfix'></div>
                <div className="bordered">
                  <h3 className="card-title txtLeft bordered-title">Post Colposcopy Smears</h3>
                  <br />
                  <div className='form-group d-flex pl-3 pr-3'>
                    <div className="col-md-2 padTop5">
                      <input type="radio"
                        value="Smear"
                        id="Smear"
                        checked={managementDetails.managementplan === 'Smear'}
                        name="managementplan_radio" className="" onChange={handleChanges('managementplan')}
                      />
                      <label className="control-label text-end" htmlFor='Smear' >Smear</label>
                      <div className='clr'></div>
                    </div>
                    <div className="col-md-5  text-left padTop5 mb-2" >
                      <label className="control-label col-md-5 text-left" >Months</label>
                      <input type="number" id="smearmonth" style={{ marginTop: -20 }} className="form-control col-md-5" placeholder="" required
                        value={managementDetails.managementplanmonthstoclinic}
                        onClick={() => {
                          if (managementDetails.managementplan !== 'Smear') return;
                          setMonthSmear(managementDetails.managementplanmonthstoclinic)
                          dispatch({ type: OPEN_MODAL, payload: 'smear' })
                        }}
                      />
                      <div className='clr'></div>
                    </div>

                    <div className="col-md-2 padTop5 text-left mb-2">
                      <input type="checkbox" className="marRight10"
                        id="smear_taken" value={!managementDetails.smeartaken} name="smear_taken"
                        checked={managementDetails.smeartaken}
                        onChange={handleChanges('smeartaken')}
                      />
                      <label className="control-label text-end" htmlFor="smear_taken" >Taken</label>
                      <div className='clr'></div>
                    </div>
                    <div className="col-md-3 padTop5 text-left mb-2">
                      <input type="checkbox" className="marRight10"
                        id="hpv_test" value={!managementDetails.hpvtest} name="hpv_test"
                        checked={managementDetails.hpvtest}
                        onChange={handleChanges('hpvtest')}
                      />
                      <label className="control-label text-end" htmlFor="hpv_test">HPV Test</label>
                      <div className='clr'></div>
                    </div>

                  </div>


                  <div className='form-group d-flex pl-3 pr-3'>
                    <div className="col-md-4 padTop5 text-left mb-3">
                      <Button disabled={(managementDetails.managementplan !== 'Smear' || managementDetails.managementplanmonthstoclinic)} className="btn btnIri"
                        onClick={() => {
                          setMonthSmear(managementDetails.managementplanmonthstoclinic)
                          dispatch({ type: OPEN_MODAL, payload: 'smear' })
                        }}
                      >New Smear</Button>
                      <div className='clr'></div>
                    </div>
                    <div className="col-md-8 padTop5 text-left">
                      <label className="control-label col-md-4 text-start" >Clinic</label>
                      <select name="postsmearcolposcopistname" id="postsmearcolposcopistname" className="col-md-8 form-control"
                        value={managementDetails.postsmearcolposcopistname}
                        onChange={(e) => handleChangeClinician('postsmearcolposcopistname', e)}
                      >
                        <option value="0">Select</option>
                        {clinician.length !== 0 && clinician.map(a => {
                          return (
                            <option value={a.iuserid}>{a.name}</option>
                          )
                        })}
                      </select>
                      <div className='clr'></div>
                    </div>

                  </div>
                </div>
                <br />
                <div className="bordered">
                  <h3 className="card-title txtLeft bordered-title">Follow-up</h3>
                  <br />
                  <div className="form-group d-flex pl-3 pr-3">
                    <div className='col-md-4'>
                      <input type="radio" value="1" name="styled_radio" required="" className="" />
                      <label className="control-label text-end" >Gynaecology Clinic</label>
                      <div className='clr'></div>
                      <br />
                      <div className="" >
                        <input type="radio" value="1" name="styled_radio" required="" />
                        <label className="control-label text-end" >Colposcopy Clinic</label>
                        <div className='clr'></div>
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <label className="control-label col-md-8 text-right" >Months</label>
                      <input type="text" id="firstName" style={{ marginTop: -20 }} className="form-control col-md-4 text-right" placeholder="" required />
                      <div className='clr'></div>
                      <br />
                      <div className="text-left" >
                        <label className="control-label text-right col-md-3" >Clinic</label>
                        <input type="text" id="firstName" className="form-control col-md-9 text-left" placeholder="" required />
                        <div className='clr'></div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
              </div>
              <div className="col-md-6">
                <br />
                <div className='row'>
                  <div className='col-md-6 mb-3'>
                    <div className='bordered'>
                      <h3 className="card-title txtLeft bordered-title">Cancer Treatment</h3>
                      <div className='pt-1 pl-5 pr-3 pb-3'>
                        <input type="radio"
                          value="Cancer Treatment"
                          id="Cancer Treatment"
                          checked={managementDetails.managementplan === 'Cancer Treatment'}
                          name="managementplan_radio" className="" onChange={handleChanges('managementplan')}

                        />
                        <label className="control-label text-end" htmlFor="Cancer Treatment" >Cancer Treatment</label>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 mb-3'>
                    <div className='bordered'>
                      <h3 className="card-title txtLeft bordered-title">Other Treatment</h3>
                      <div className="pr-3 pl-3 pb-3">
                        <input type="text"
                          id="othertreatment" className="form-control " placeholder=""
                          value={managementDetails.othertreatment}
                          onChange={handleChanges('othertreatment')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row pr-2 pl-2'>
                  <div className='bordered mb-3 text-left'>
                    <h3 className="card-title bordered-title">Discharge</h3>
                    <div className="col-md-12 pl-3">
                      <input type="radio"
                        value="Discharge"
                        id="Discharge"
                        checked={managementDetails.managementplan === 'Discharge'}
                        name="managementplan_radio" className="" onChange={handleChanges('managementplan')}
                      />
                      <label className="control-label text-end" htmlFor='Discharge'>Discharge</label>
                      <div className='clr'></div>
                    </div>
                    <div className={`bordered pt-1 mb-2 ${managementDetails.managementplan !== 'Discharge' && 'disabled'}`}>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Return to referrer</label>
                        <div className='clr'></div>
                      </div>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Never any evidence of disease</label>
                        <div className='clr'></div>
                      </div>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Disease reloved spontaneously</label>
                        <div className='clr'></div>
                      </div>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Completed procedures</label>
                        <div className='clr'></div>
                      </div>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Failure to attend</label>
                        <div className='clr'></div>
                      </div>
                      <div className="pl-3">
                        <input disabled type="radio" value="1" name="styled_radio" required="" className="" />
                        <label className="control-label text-end" >Follow-up / Treatment elsewhere</label>
                        <div className='clr'></div>
                      </div>
                    </div>
                    <div className='col-md-12 text-right' >
                      <label className="col-md-4 text-center" >Comments</label>
                      <input type="text" id="firstName" className="form-control col-md-8" placeholder="" required />
                      <div className='clr'></div><br />
                    </div>
                    <div className='col-md-12 text-right' >
                      <label className="col-md-4 text-center" >Outcome</label>
                      <input disabled type="text" id="firstName" className="form-control col-md-8" placeholder="" required />
                      <div className='clr'></div>
                    </div>
                    <br />
                  </div>

                  <div className='clearfix'></div>
                  <br />

                  <div className='bordered'>
                    <h3 className="card-title txtLeft bordered-title">History</h3>
                    <div className="col-md-12 pb-4">
                      <textarea row="3" name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false" />
                    </div>
                  </div>
                </div>
                <br />
              </div>



            </div>
          </div>

          <br />
          <div className='pl-2 pr-2'>
            <div className='row text-left'>
              <div className='col-md-6 mb-4'>
                {managementDetails.managementplan === 'Discharge' ? dischargeDate : managementDetails.managementplan === 'Treatment' ? TreatmentAppointment : managementDetails.managementplan === 'Smear' ? smearAppointment : nextAppointment}

              </div>
              <div className='col-md-6'>
                <div className='row' style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <div className='col-md-8 text-left mb-4'>
                    <Button className="btn text-dark btn-secondary mr-2" disabled>Re-instate</Button>
                    <Button className="btn text-dark btn-secondary mr-2" onClick={() => navigate(`/dashboard/generate-report/Data Sheet/${patientDetails.systemid}`)
                    }>Data Sheet</Button>
                    <Button className="btn text-dark btn-secondary mr-2" onClick={() => handleLetter()}>Letter</Button>
                  </div>
                  <div className='col-md-4 mb-4 text-right' style={{ justifyContent: 'space-between' }}>
                    <Button className="btn btnIri mr-2" onClick={() => handleBack()}>Previous</Button>
                    <Button className="btn btnIri" type="submit" >Exit</Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Form >
    </>

  );


}

const Management = () => {
  const navigate = useNavigate();
  const { patientDetails } = useSelector(({ data }) => data)



  useEffect(() => {
    if (patientDetails && (patientDetails.nhsnumber || patientDetails.hospitalnumber)) {
      //   setPatient(patientDetails)
    } else {
      navigate('/dashboard/home')
    }

  }, [patientDetails])




  return (
    <>


      <div className="card-header bg-iri txtLeft">
        <h4 className="text-white">Patient Management Details</h4>
      </div>


      <div className="card-body">

        {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}

        <One />

      </div>
    </>
  );
}

export default Management;
