import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_PATIENT_DETAILS, CLEAR_SORT, CLOSE_MODAL, OPEN_MODAL, SET_DATA, SET_ERRORS, SET_PATIENT_DETAILS, SET_PEROSNAL_REFERRAL_DETAILS, SET_SEARCH_STRING, SET_VIEW_OPTION } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { searchPatientDetails, updateInUseBy } from '../../redux/actions/data.action';



export default function HospitalNumberModal({ }) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { modal } = useSelector(({ ui }) => ui)
    const { viewOption } = useSelector(({ ui }) => ui)
    const { user } = useSelector(({ auth }) => auth)

    const [values, setValues] = useState({
        nhsnumber: '',
        hospitalnumber: ''
    })


    const toggle = () => {
        if (modal === 'hospitalNumber') {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
            dispatch({ type: SET_SEARCH_STRING, payload: '' })
        }
        dispatch({ type: OPEN_MODAL, payload: modal === 'hospitalNumber' ? null : 'hospitalNumber' });
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        dispatch(searchPatientDetails(values))
            .then(res => {
                if (res && res.length !== 0) {
                    setValues({
                        nhsnumber: '',
                        hospitalnumber: ''
                    })
                    if (res.length === 1) {
                        //Check if inuseby
                        if (res[0].inuseby && viewOption !== 'review' && res[0].inuseby !== user.userId) {
                            dispatch({ type: SET_ERRORS, payload: { name: res[0].tbl_userdetail?.name } })
                            dispatch({ type: OPEN_MODAL, payload: 'enteringpatient' })
                            return
                        }

                        dispatch(updateInUseBy(res[0].systemid))
                        dispatch({ type: SET_PATIENT_DETAILS, payload: res[0] })
                        if (res[0].tbl_personalreferraldetails) {
                            dispatch({ type: SET_PEROSNAL_REFERRAL_DETAILS, payload: res[0].tbl_personalreferraldetails[res[0].tbl_personalreferraldetails.length - 1] })
                        }

                        dispatch({ type: SET_SEARCH_STRING, payload: res[0].hospitalnumber ? res[0].hospitalnumber : res[0].nhsnumber ? res[0].nhsnumber : '' })

                        navigate(viewOption === 'enter' ? '/dashboard/patient' : '/dashboard/viewPatient')
                    } else {
                        dispatch({ type: SET_SEARCH_STRING, payload: values.hospitalnumber ? values.hospitalnumber : values.nhsnumber ? values.nhsnumber : '' })
                        navigate('/dashboard/trace')
                    }
                    dispatch({ type: CLOSE_MODAL });
                    // dispatch({type: SET_SEARCH_STRING, payload: searchStr})
                } else {
                    dispatch({ type: SET_VIEW_OPTION, payload: 'enter' })
                    dispatch({ type: SET_PATIENT_DETAILS, payload: values })
                    dispatch({ type: OPEN_MODAL, payload: 'newPatient' });
                }
                dispatch({ type: CLEAR_SORT })
            })

    }

    const handleCancel = (e) => {
        e.preventDefault()
        dispatch({ type: SET_SEARCH_STRING, payload: '' })
        setValues({
            nhsnumber: '',
            hospitalnumber: ''
        })
        dispatch({ type: CLOSE_MODAL });
    }


    return (
        <>
            <Modal
                size="lg"
                isOpen={modal === 'hospitalNumber'}
                toggle={toggle}
            //    className={this.props.className}
            >
                <ModalHeader className='btnIri'
                //   toggle={toggle}

                >Enter Patient's Hospital Number or NHS Number</ModalHeader>
                {/* <ModalBody> */}
                <form className='txtLeft' onSubmit={handleSubmit} onReset={handleCancel}>
                    <div className="form-body d-flex flex-row align-items-center justify-around">
                        <div className="p-t-20 text-left flex-grow-1 pl-2 pr-2" >
                            <div className="form-group center pl-3">
                                <label className="control-label col-md-5 text-left">Hospital Number</label>
                                <input type="text" id="hospitalnumber" className="col-md-7 form-control" placeholder="Enter Hospital Number"
                                    // style={{ width: '300px' }}
                                    value={values.hospitalnumber}
                                    onChange={(e) => setValues({ ...values, hospitalnumber: String(e.target.value).toUpperCase() })
                                    }
                                />
                            </div>
                            <div className="form-group  center pl-3">
                                <label className="control-label col-md-5 text-left" >NHS Number</label>
                                <input type="text" id="nhsnumber" className="col-md-7  form-control" placeholder="Enter NHS Number"
                                    // style={{ width: '300px' }}
                                    value={values.nhsnumber}
                                    onChange={(e) => setValues({ ...values, nhsnumber: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className=" mt-3 ml-3" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="form-group  pr-3 pt-1">
                                <Button className="btn btnIri"
                                    style={{
                                        width: '100px'
                                    }}
                                    disabled={!values.hospitalnumber && !values.nhsnumber}
                                    type='submit'
                                >Ok</Button>
                            </div>
                            <div className="form-group  pr-3 pt-1">
                                <Button className="btn btn-secondary"
                                    style={{
                                        width: '100px'
                                    }}
                                    // disabled={!values.hospitalnumber && !values.nhsnumber}
                                    type='reset'
                                >Cancel</Button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* </ModalBody> */}
                {/* <ModalFooter>

                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter> */}
            </Modal>
        </>
    )
}
